<template>
  <div>
    <template>
      <b-navbar type="is-black" :mobile-burger="false">
        <template #brand>
          <b-navbar-item>
            <img
              @click="go(EVENT_BACK)"
              src="../../assets/arrow-left.png"
              alt="left-arrow"
            />
          </b-navbar-item>
          <b-navbar-item>
            <img
              @click="go(EVENT_CLOSE)"
              src="../../assets/close.png"
              alt="left-arrow"
            />
          </b-navbar-item>
        </template>
      </b-navbar>
    </template>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  methods: {
    go(event) {
      this.postMessage({
        name: event,
      });
    },
  },
};
</script>

<style scoped></style>
