<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  components: {
    // TopBar,
  },
  created() {
    this.postMessage({ name: this.EVENT_SHOW_TOOLBAR });
  },
  watch: {
    $route() {
      this.postMessage({ name: this.EVENT_SHOW_TOOLBAR });
    },
  },
};
</script>
