<template>
  <div class="header-wrapper">
    <div class="top-bar-container">
      <TopBarNew title="Gán đơn cho tài xế"
                 :refresh-icon-path="refreshIconPath"
                 @refreshClick="$emit('refreshSupplier')" />
    </div>
    <div class="page-title">
      <div class="page-title-left"><strong>Danh sách tài xế</strong></div>
      <div class="page-title-right">Đơn hàng {{ order_id }}</div>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopBarNew from "@/pages/SupplierListing/components/ListingNew/TopBarNew";

export default {
  name: "HeaderNew",
  components: { TopBarNew },
  data() {
    return {
      refreshIconPath: "order-listing/refresh-icon.png",
    };
  },
  computed: {
    ...mapState("supplierList", ["order_id"])
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  .page-title {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 16px;

    &-left {
      position: relative;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #4D5358;
    }

    &-right {
      position: relative;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #697077;
    }
  }
}
</style>