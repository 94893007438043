<template>
  <div :style="{ 'background-color': bgColor }" class="board-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BoardWrapper",
  props: {
    bgColor: {
      type: String,
      default: "#101a3c",
    },
  },
};
</script>

<style lang="scss" scoped>
.board-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}
</style>
