<template>
  <div class="modal-card">
    <section class="modal-card-body calendar__screen--wrapper">
      <div class="calendar-screen">
        <CustomTopBar :parentModal="$parent">
          <div class="content">Chọn ngày</div>
        </CustomTopBar>
        <div class="dp__output-group">
          <b-field
            label="Ngày bắt đầu"
            placeholder="Chọn ngày"
            :label-position="labelPosition"
          >
            <b-input
              type="text"
              name="dateStart"
              v-model="date_start"
              :class="dateStartFocus ? 'focus' : ''"
              @focus="focusChange"
              @blur="blurChange"
              READONLY
            >
            </b-input>
          </b-field>
          <b-field
            label="Ngày kết thúc"
            placeholder="Chọn ngày"
            :label-position="labelPosition"
          >
            <b-input
              type="text"
              name="dateEnd"
              v-model="date_end"
              :class="dateEndFocus ? 'focus' : ''"
              @focus="focusChange"
              @blur="blurChange"
              READONLY
            >
            </b-input>
          </b-field>
        </div>

        <b-datepicker
          inline
          range
          v-model="date"
          locale="vi-vn"
          class="datepicker__custom"
          :max-date="maxDate"
          @input="updateData"
          @range-start="onRangeStart"
        >
        </b-datepicker>

        <div class="bottom-wrapper">
          <b-button :disabled="!date_start || !date_end" @click="onChooseDate()"
            >Xác nhận</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { format } from "date-fns";
import ArrowLeftIcon from "@/assets/arrow-left-icon.svg";
import CustomTopBar from "@/common/components/CustomTopBar";

export default {
  name: "CalendarComponent",
  components: { CustomTopBar },
  data() {
    return {
      arrowLeft: ArrowLeftIcon,
      date: new Date(),
      labelPosition: "inside",
      date_start: "",
      date_end: "",
      dateStartFocus: null,
      dateEndFocus: null,
      maxDate: null,
    };
  },
  methods: {
    focusChange(e) {
      const { name } = e.target;
      this[`${name}Focus`] = true;
    },
    blurChange(e) {
      const { value, name } = e.target;
      if (!value) {
        this[`${name}Focus`] = false;
      }
    },
    updateData(dateRange) {
      this.date_start = format(new Date(dateRange[0]), "dd/MM/yyyy");
      this.date_end = format(new Date(dateRange[1]), "dd/MM/yyyy");
    },
    onChooseDate() {
      const labelDateString = `${this.date_start} - ${this.date_end}`;
      this.$emit("date-label", labelDateString);
      this.$emit("date-range", this.date);
      this.$parent.close();
    },
    onRangeStart(day) {
      const today = new Date(day);
      const maxDay = new Date(new Date().setDate(today.getDate() + 35));
      this.maxDate = maxDay;
    },
  },
};
</script>

<style lang="scss">
.dp__output-group {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-left: -20px;
  margin-right: -20px;
  .control {
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      bottom: 0px;
      left: 50%;
      width: 90%;
      transform: translateX(-50%);
      height: 2px;
      background: #f2f3f4;
      transition: width 0.7s;
    }
    &.focus {
      &:before {
        background: #007cff;
        transition: ease-in 0.7s;
      }
    }
  }
  input {
    border: none;
    box-shadow: none;

    &:focus,
    &:active,
    &:focus-visible {
      box-shadow: none;
    }
  }
  label {
    color: #b1b6d3;
  }
}

.calendar__screen--wrapper {
  .calendar-screen {
    background-color: #fff;
  }
  .bottom-wrapper {
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    button {
      width: 100%;
      font-size: 14px;
      height: 48px;
      background-color: #3a86ff;
      color: #fff;
      &:disabled {
        background-color: #c1c7cd;
      }
    }
  }
}

.datepicker__custom {
  &.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    color: #121619;
    background-color: #91d5ff;
    border: 1px solid #1890ff;
    &.is-first-selected,
    &.is-last-selected {
      background-color: #91d5ff;
    }
    &.is-within-selected {
      background-color: #ecf5ff;
    }
  }
  .dropdown-menu {
    width: 100%;
  }
  .dropdown-content {
    box-shadow: none;
  }
  .dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }
  .is-selectable {
    font-weight: bold;
  }

  .datepicker-header {
    .pagination {
      background-color: #f2f3f4;
      margin-left: -18px;
      margin-right: -18px;
      a {
        border-color: transparent;
        & > span {
          color: #21272a !important;
        }
      }
    }
    .pagination-next {
      order: 3;
    }
    .pagination-list {
      justify-content: center;
      order: 2;
      select {
        border: none;
        background-color: transparent;
        font-weight: bold;
      }
    }
    .select:not(.is-multiple):not(.is-loading)::after {
      display: none;
    }
  }
}
</style>
