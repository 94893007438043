<template>
  <div class="cod-form-wrapper">
    <b-field label="Nhập số tiền hàng đã nhận (VND)">
      <b-input
        type="number"
        v-model="cod_amount"
        icon="cash"
        validation-message="Xin nhập số tiền theo đúng định dạng"
        @input="onChange"
      ></b-input>
    </b-field>
    <CustomButton
      className="full-width primary"
      :label="submitText"
      :loading="isLoading"
      :disabled="isLoading || !cod_amount"
      @clicked="onSubmit"
    />

    <div :style="{ marginTop: '8px' }">
      <CustomButton
        v-if="isError"
        className="full-width primary"
        label="Đóng để tiếp tục"
        @clicked="onCloseToCont"
      />
    </div>

    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomButton from "@/common/components/CustomButton";
import CustomToast from "@/common/components/CustomToast";
import { insertCOD } from "./services";

export default {
  name: "CODForm",
  components: { CustomButton, CustomToast },
  computed: {
    ...mapState({
      ahaOrderId: (state) => state.ahaOrderId,
    }),
  },
  data() {
    return {
      cod_amount: 0,
      isLoading: false,
      submitText: "Xác nhận",
      isShowToast: false,
      type: "error",
      message: "",
      position: "bottom",
      isError: false,
    };
  },
  methods: {
    onChange() {
      this.cod_amount = this.cod_amount.replace(/[^a-z0-9]/gi, "");
    },
    onCloseToCont() {
      this.postMessage({
        name: this.EVENT_CLOSE,
      });
    },
    async onSubmit() {
      let orderId = "";
      if (this.ahaOrderId) {
        orderId = this.ahaOrderId;
      } else {
        orderId = this.$route.query.orderId;
      }
      const pathIndex = this.$route.query.pathIndex;

      const body = {
        orderId,
        pathIndex: parseInt(pathIndex),
        cashCollection: parseFloat(this.cod_amount),
      };
      this.isLoading = true;
      this.submitText = "";

      try {
        await insertCOD(body);
        await this.completeOrder();
        this.postMessage({
          name: this.EVENT_CLOSE,
        });
      } catch (err) {
        this.isError = true;
        this.message = `Hiện đã xảy ra lỗi. \nMã: ${err.response.status} - ${err.response.data.message}`;
        this.isShowToast = true;
      } finally {
        this.cod_amount = 0;
        this.isLoading = false;
        this.submitText = "Xác nhận";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cod-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 99vh;
  text-align: left;
}
</style>
