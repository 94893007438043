<template>
  <div class="kamegomi-main">
    <TopBar/>
    <transition name="fade">
      <router-view @open-modify="toggleModifyModal"/>
    </transition>
    <Modal noPadding isCloseOnClickOutside v-show="isModifyModalOpen" @close="close">
      <div class="update-garbage-container">
        <div @click="close" class="close-container">
          <img src="../../assets/close-dark.png" alt="">
        </div>
        <UpdateGarbage v-if="isModifyModalOpen" v-bind="selectedGarbage" @update="close"
                       :requirePhoto="String(this.$route.query.requirePhoto)" @upload_err="showFailed"/>
      </div>
    </Modal>
    <Toast v-show="showToastFailed" type="failed" :message="`Upload error: status ${uploadErrCode}`"/>
  </div>
</template>
<script>
import TopBar from "@/common/components/TopBar";
import Modal from "../../common/components/Modal";
import Toast from "../../common/components/Toast";
import UpdateGarbage from "./components/UpdateGarbage";
import {mapActions, mapState} from "vuex";
import services from './services'

export default {
  components: {
    TopBar,
    Modal,
    Toast,
    UpdateGarbage
  },
  data: () => ({
    isModifyModalOpen: false,
    selectedGarbage: {},
    showToastFailed: false,
    uploadErrCode: null,
  }),
  computed: {
    ...mapState('kamegomi', ['orderId'])
  },
  watch: {
    isModifyModalOpen: function (val) {
      // console.log(`modal open: selectedGarbage=${JSON.stringify(this.selectedGarbage)}`);
      if (!val) {
        this.selectedGarbage = {}
      }
    }
  },
  methods: {
    ...mapActions('kamegomi', ['loadItemListFromApi', 'setOrderId', 'setLoadingItemList']),
    toggleModifyModal(data) {
      this.selectedGarbage = data
      this.isModifyModalOpen = true
    },
    close() {
      this.isModifyModalOpen = false
    },
    showFailed(status) {
      this.uploadErrCode = status;
      this.showToastFailed = true
      console.log(`this.showToastFailed -> ${this.uploadErrCode} - ${this.showToastFailed}`)
      setTimeout(() => this.showToastFailed = false, 5000);
    },
    removeSavedItem() {
      try {
        let orderList = JSON.parse(localStorage.getItem(this.cacheOrderListName))
        orderList = orderList.filter(item => {
          if (item !== this.$route.query.orderId || item !== this.orderId) {
            localStorage.removeItem(item)
          } else {
            return true
          }
        })
        localStorage.setItem(this.cacheOrderListName, JSON.stringify(orderList))
      } catch (e) {
        console.log(e)
      }
    },
    loadWeighFromLocalStorage(apiItemList) {
      let localItemList = JSON.parse(localStorage.getItem(this.orderId))
      if (Array.isArray(localItemList)) {
        for (let i in localItemList) {
          for (let j in apiItemList) {
            if (apiItemList[j]['id'] === localItemList[i]['id']) {
              apiItemList[j]['weight'] = localItemList[i]['weight'];
              apiItemList[j]['order_proof'] = localItemList[i]['order_proof'];
            }
          }
        }
      }
      // console.log(`loaded from local-storage: localItemList=${JSON.stringify(localItemList)}`)
      // console.log(`loaded from local-storage: apiItemList=${JSON.stringify(apiItemList)}`)
      return apiItemList
    }

  },
  async mounted() {
    // console.log(`this.$route.query.requirePhoto -> ${this.$route.query.requirePhoto}`);
    this.setLoadingItemList({status: true})
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR,
    });
    await this.setOrderId({
      orderId: this.$route.query.orderId
    })
    try {
      let itemList = await services.getItemList({
        orderId: this.orderId
      })
      itemList = this.loadWeighFromLocalStorage(itemList)
      // console.log(`mounted collector: itemList=${JSON.stringify(itemList)}`);
      await this.loadItemListFromApi({value: itemList})
      // console.log(`mounted collector: itemList=${JSON.stringify(itemList)}`);
      setTimeout(() => {
        this.setLoadingItemList({status: false})
        this.removeSavedItem()
      }, 200)
    } catch {
      this.setLoadingItemList({status: false})
      this.removeSavedItem()
    }
  },
};
</script>
<style lang="scss" scoped>
.kamegomi-main {
  width: 100%;
  height: 100vh;
}

.update-garbage-container {
  width: 100%;
  padding: 20px 10px;
  background: linear-gradient(183.61deg, rgba(4, 126, 255, 0.1) 30%, rgba(252, 252, 252, 0) 70%);
  position: relative;

  .close-container {
    position: absolute;
    right: 10px;
  }
}
</style>
