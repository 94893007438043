<template>
  <div id="map" :class="scale">
    <MglMap
      :accessToken="accessToken"
      :mapStyle.sync="mapStyle"
      :center="defaultCoordinates"
      :zoom="13"
      :container="'map'"
      @load="onMapLoaded"
    >
      <MglMarker
        v-for="item in markers"
        :key="item.plate"
        :coordinates="item.lngLat"
        @click="onClickMarker(item)"
      >
        <div class="map-marker" slot="marker">
          <MarkerItem :data="item" />
        </div>
      </MglMarker>
    </MglMap>
  </div>
</template>

<script>
import { MglMap, MglMarker } from "vue-mapbox"
import MarkerItem from "./MarkerItem"
import {get} from "lodash"

const HCM_COORS = [106.660172, 10.762622]

export default {
  name: "TrackingMap",
  components: {
    MglMap,
    MglMarker,
    MarkerItem,
  },
  props: ["markers", "defaultCoors", "selectionMarker"],
  data() {
    return {
      accessToken:
          "pk.eyJ1IjoibGVtbjEyIiwiYSI6ImNsMXZpNnMycjF6eWEzZGxta2dpZ2s4YTIifQ.IoMaeDzIVD9Uc6RxstMhJQ", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/outdoors-v11?optimize=true", // your map style,
      defaultCoordinates: HCM_COORS,
      scale: ''
    }
  },
  created() {
    this.map = null
  },
  methods: {
    moveMarker(coors, zoom) {
      this.map.flyTo({
        center: coors ? coors : this.defaultCoordinates,
        zoom: zoom ? zoom : 11
      })
    },
    onMapLoaded(event) {
      const { map } = event
      this.map = map
      this.moveMarker()
      this.map.on('dragend', () => {
        this.map.resize()
      })
    },
    onClickMarker(marker) {
      this.$emit('onClickMarker', marker)
    }
  },
  watch: {
    defaultCoors(value) {
      if (value && value.length > 0) {
        this.defaultCoordinates = value
      }
    },
    selectionMarker: {
      handler(marker) {
        if (marker && Object.keys(marker).length > 0) {
          const [lng, lat] = get(marker, 'lngLat') || []
          if (lng && lat) {
            this.scale = 'scale'
            setTimeout(() => {
              this.map.resize()
              this.moveMarker([lng, lat], 14)
            }, 250)
          }
        } else {
          this.scale = ''
          setTimeout(() => {
            this.map.resize()
            this.moveMarker()
          }, 250)
        }
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
#map {
  position: absolute;
  top: 176px;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 176px);
}

.scale {
  height: calc(100vh - 29vh - 176px)!important;
  transition: ease-in-out 0.7s;
}
</style>
