import { render, staticRenderFns } from "./PaymentTransactions.vue?vue&type=template&id=f84dfb3c&"
import script from "./PaymentTransactions.vue?vue&type=script&lang=js&"
export * from "./PaymentTransactions.vue?vue&type=script&lang=js&"
import style0 from "./PaymentTransactions.vue?vue&type=style&index=0&id=f84dfb3c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports