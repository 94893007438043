<template>
  <div>
    <div v-if="img" class="img-container">
      <img :src="require(`@/assets/kamegomi/${imgData[img] || imgData['OTHER']}.png`)" alt="">
    </div>
    <div class="name">{{ name }}</div>
    <div v-if="price_unit" class="price-unit">{{ price_unit.toLocaleString() }}đ/{{ unit_type }}</div>
    <div class="input-container">
      <div class="input-noted">Hãy cân và ghi số cân đã cân được</div>
      <input @focus="onfocus" @input="onInput" v-model="garbageWeight" class="garbage-input" :placeholder="unit_type"
             inputmode="decimal">
    </div>
    <button class="camera-button" v-if="requirePhoto === 'true'" @click="chooseFile()" @change="uploadFile()"
            @disabled="!isPhotoUploaded">
      <div class="camera-button-container" v-if="!isPhotoUploaded">
        <input ref="file" type="file" v-show="false" accept="image/*"/>
        Chụp hình minh chứng
        <img src="../../../assets/kamegomi/camera.png" alt="camera" class="small-img"/>
      </div>
      <div class="camera-button-upload-success" v-if="isPhotoUploaded">Đã tải lên hình thành công</div>
    </button>
    <div id="submit-button-container" class="confirm-button-container">
      <button @click="clear" class="button-confirm button-confirm-back">Xoá</button>
      <button :disabled="!isUpdated || !isPhotoUploaded" :class="{'button--inactive': !isUpdated || !isPhotoUploaded}"
              @click="updateGarbageWeight" class="button-confirm button-confirm-confirm">
        Xác nhận đơn hàng
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import services from "@/pages/AppKamegomi/services";

export default {
  name: "UpdateGarbage",
  props: {
    img: String,
    price_unit: Number,
    weight: {
      type: [Number, String]
    },
    order_proof: {
      type: [String]
    },
    name: String,
    id: {
      type: [String, Number]
    },
    unit_type: String,
    requirePhoto: [String],
    selectedPhoto: null,
  },
  data: () => ({
    garbageWeight: null,
    garbageOrderProof: null,
    isUpdated: false,
    isPhotoUploaded: false,
  }),
  computed: {
    ...mapState('kamegomi', ['imgData', 'orderId', 'itemList'])
  },
  methods: {
    ...mapActions('kamegomi', ['updateWeight']),
    clear() {
      this.garbageWeight = 0
      this.updateGarbageWeight()
    },
    async chooseFile() {
      let selectPhotoElement = this.$refs.file;
      selectPhotoElement.click();
    },
    async uploadFile() {
      try {
        // this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXAiOiJzdXBwbGllciIsImNpZCI6Ijg0OTc0NDEyNTQyIiwic3RhdHVzIjoiT0ZGTElORSIsImVvYyI6ImVtYWlsQGV4YW1wbGUuY29tIiwibm9jIjoiRm9vIiwiY3R5IjoiU0dOIiwiYXBwIjoiQWhhTW92ZSIsImltZWkiOiIxNTY4ZGRiNTY0ZTc4NTBkIiwidHlwZSI6ImFuZHJvaWQiLCJyb2xlIjoiYWRtaW4iLCJleHAiOjE2MzMzMzY0NjEsImlhdCI6MTYzMzMzNjQwMSwiaXNzIjoiQWhhTW92ZSJ9.RKmQv7QeRR1SsjbP_VPB6PcgZLtiufk09FMJrkIoYw8";
        this.selectedPhoto = this.$refs.file.files[0];
        const response = await services.uploadPhoto(this.selectedPhoto, this.token);
        this.garbageOrderProof = response.data.url;
        console.log({url: response.data.url});
        this.isPhotoUploaded = true;
      } catch (err) {
        console.log({err})
        this.$emit('upload_err', err.response.status)
      }
    },
    async updateGarbageWeight() {
      this.$emit('update')
      await this.updateWeight({
        id: this.id,
        weight: this.garbageWeight,
        order_proof: this.garbageOrderProof,
      })
      try {
        // console.log(`itemList before set to local-storage: ${JSON.stringify(this.itemList)}`);
        localStorage.setItem(this.orderId, JSON.stringify(this.itemList)) // save item list
        let orderList = JSON.parse(localStorage.getItem(this.cacheOrderListName))
        if (!Array.isArray(orderList)) {
          orderList = []
        }
        orderList.push(this.orderId)
        localStorage.setItem(this.cacheOrderListName, JSON.stringify(orderList)) // save order in to cache order list
      } catch (e) {
        console.log(e)
      }
    },
    onInput() {
      // console.log(`this.isUpdated -> ${this.isUpdated}`);
      // console.log(`this.isPhotoUploaded -> ${this.isPhotoUploaded}`);
      let num = this.garbageWeight.replace(",", ".");
      this.garbageWeight = num
      this.isUpdated = !isNaN(num)
    },
    onfocus() {
      setTimeout(() => {
        document.getElementById('submit-button-container').scrollIntoView({
          behavior: "smooth"
        });
      }, 2000)
    }
  },
  mounted() {
    // console.log({id: this.id, name: this.name, weight: this.weight, order_proof: this.order_proof});
    if (this.weight > 0) {
      this.garbageWeight = this.weight
    }
    if (this.order_proof) {
      this.garbageOrderProof = this.order_proof
      this.isPhotoUploaded = true
    }
    if (!this.requirePhoto || this.requirePhoto !== 'true')
      this.isPhotoUploaded = true
  }
}
</script>

<style lang="scss" scoped>
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    width: 25%;
    height: auto;
  }
}

.name {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.price-unit {
  margin-top: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}

.input-container {
  margin-top: 10px;

  .input-noted {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    text-align: left;
  }

  .garbage-input {
    text-align: center;
    border: 1px solid #0F1011;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    padding: 15px 0;
  }
}

.camera-button {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #0F1011;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 0;

  .small-img {
    margin-left: 12px;
    height: 24px;
  }

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }

  &-upload-success {
    color: green;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.confirm-button-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button-confirm {
    padding: 15px 0;
    width: 48%;
    border: none;
    outline: none;

    &:hover {
      outline: none;
    }

    &-back {
      background: #F2F2F2;
      border-radius: 2px;
    }

    &-confirm {
      background: #FF8200;
      border-radius: 2px;
      color: white
    }
  }

  .button--inactive {
    background: #BCBCBC !important;
  }
}

</style>
