<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Phù hiệu phương tiện</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <div class="form-section align-left">
        <form>
          <h6>Phù hiệu xe</h6>
          <div class="form-group-row">
            <CustomFileUploader
              name="badge_img"
              :imageURL="fields.badge_img"
              @input="uploadChange"
            />
          </div>
          <div class="expired-input">
            <label for="">Ngày hết hạn:</label>
            <CustomDatePicker
              name="vehicle_badge_expired_at"
              :minDated="new Date()"
              :selectedDate="fields.vehicle_badge_expired_at"
              @input="dateChange"
            />
          </div>
          <CustomDivider fluid />
          <h6>Phù hiệu hợp tác xã</h6>
          <div class="form-group-row">
            <CustomFileUploader
              name="badge_cooperative_img"
              :imageURL="fields.badge_cooperative_img"
              @input="uploadChange"
            />
          </div>
          <div class="expired-input">
            <label for="">Ngày hết hạn:</label>
            <CustomDatePicker
              name="cooperative_badge_expired_at"
              :minDated="new Date()"
              :selectedDate="fields.cooperative_badge_expired_at"
              @input="dateChange"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomDivider from "@/common/components/CustomDivider";
import CustomFileUploader from "@/common/components/CustomFileUploader";
import CustomDatePicker from "@/common/components/CustomDatePicker";
import CustomButton from "@/common/components/CustomButton";
import { uploadImage } from "@/common/utils";
import { updateVehicleById } from "@/pages/Management/services/vehicle";

export default {
  name: "VehicleBadgeView",
  components: {
    CustomTopBar,
    CustomDivider,
    CustomFileUploader,
    CustomDatePicker,
    CustomButton,
  },
  computed: {
    ...mapGetters({
      vehicleDetail: "managementStore/getVehicleDetail",
    }),
  },
  async mounted() {
    // const { vehicleId } = this.$route.params;
    // const { supplier_token } = this.$route.query;
    // await this.getVehicleDetail({ token: supplier_token, vehicleId });
    this.mapData();
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      submitText: "Cập nhật",
      isLoading: false,
      isDisabled: true,
      fields: {
        badge_img: "",
        vehicle_badge_expired_at: "",
        badge_cooperative_img: "",
        cooperative_badge_expired_at: "",
      },
    };
  },
  methods: {
    ...mapActions({
      getVehicleDetail: "managementStore/fetchVehicleDetail",
    }),
    goBack() {
      this.$router.back();
    },
    async uploadChange(input) {
      const { name, file } = input;
      const body = {
        file,
        file_type: "meta_truck",
      };
      const url = await uploadImage(this.$route.query.supplier_token, body);

      this.fields[name] = url;
      this.isDisabled = this.isValidated();
    },
    dateChange(input) {
      const { name, value } = input;
      this.fields[name] = value.toISOString();
      this.isDisabled = this.isValidated();
    },
    async onConfirm() {
      const { vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      const {
        badge_img,
        vehicle_badge_expired_at,
        badge_cooperative_img,
        cooperative_badge_expired_at,
      } = this.fields;
      this.isLoading = true;

      const payload = {
        cooperative_badge_urls: [badge_cooperative_img],
        cooperative_badge_expired_at,
        vehicle_badge_expired_at,
        vehicle_badge_urls: [badge_img],
      };

      try {
        await updateVehicleById(supplier_token, vehicleId, payload);
        this.goBack();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    mapData() {
      const { vehicleId } = this.$route.params;
      if (this.vehicleDetail[vehicleId]) {
        const vehicle = this.vehicleDetail[vehicleId];
        this.fields.badge_img = vehicle.cooperative_badge_urls[0];
        this.fields.vehicle_badge_expired_at = vehicle.vehicle_badge_expired_at;
        this.fields.badge_cooperative_img = vehicle.vehicle_badge_urls[0];
        this.fields.cooperative_badge_expired_at =
          vehicle.cooperative_badge_expired_at;
      }
    },
    isValidated() {
      return Object.values(this.fields).some((el) => !el);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.expired-input {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  label {
    margin-right: 8px;
  }
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
