<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="closeOnClickOutside">
        <div class="modal-container" :class="{'mobile--over-lap': overlapOnMobile, 'no-padding': noPadding}">
          <div v-if="closeButton" @click="close" class="button-close-container">
            <img src="../../assets/close-dark.png" alt="">
          </div>
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isCloseOnClickOutside: {
      type: Boolean,
      default: false
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    overlapOnMobile: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    preventCloseClassName: 'prevent-scroll'
  }),
  watch: {
    isShow: function (isShow) {
      if (isShow) {
        document.body.classList.add(this.preventCloseClassName);
      } else {
        document.body.classList.remove(this.preventCloseClassName);
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeOnClickOutside() {
      if (this.isCloseOnClickOutside) {
        this.$emit('close')
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: scroll;
  width: 90%;
  max-height: 80vh;
  height: auto;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-content {
  .email-container {
    display: flex;
  }
}

.button-close-container {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.mobile-over-lap {
  height: 100% !important;
  width: 100% !important;
}

.no-padding {
  padding: 0 !important;
}
</style>

<style lang="scss">
.prevent-scroll {
  overflow: hidden;
  position: fixed;
}
</style>
