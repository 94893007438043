<template>
  <div class="vehicle-card">
    <template v-if="edit">
      <div class="inner-overlay" @click="$emit('get-detail', detail)" />
    </template>
    <template v-if="checkbox">
      <b-checkbox
        class="custom-checkbox float-checkbox"
        v-model="detail.checked"
        @input="onChange"
      >
      </b-checkbox>
    </template>
    <div class="card-inner">
      <div class="wrapper">
        <img :src="vehicleIcon" alt="vehicle_icon" class="image" />
        <div class="info">
          <div class="plate">
            {{ convertPlateNumber(detail._id, detail.type) || "-" }}
          </div>
          <div class="service">{{ detail.type_name_vi_vn || "-" }}</div>
          <div class="alert" v-if="isExpired(detail)">
            {{ getQuantityExpired(detail) }} giấy tờ cần kiểm tra
          </div>
        </div>
      </div>
      <template v-if="notice">
        <div class="noti-icon">
          <img
            :src="detail.status === 200 ? successIcon : errorIcon"
            alt="status_icon"
          />
        </div>
      </template>
    </div>

    <template v-if="notice && detail.status !== 200">
      <div class="status" :class="{ error: detail.status !== 200 }">
        {{ detail.error && convertCode(detail.error.code) }}
      </div>
    </template>

    <button
      v-if="isExpired(detail)"
      class="btn-navigate"
      @click="$emit('update', detail)"
    >
      Bổ sung giấy tờ
      <span class="icon ic-chevron"> <inline-svg :src="chevronIcon" /></span>
    </button>
  </div>
</template>

<script>
import _ from "lodash";
import { convertPlateNumber } from "@/common/utils";
import {
  ChevronIcon,
  SuccessIcon,
  ErrorIcon,
  VehicleImg,
} from "@/common/icons";

export default {
  name: "VehicleItem",
  props: ["detail", "edit", "checkbox", "notice"],
  data() {
    return {
      vehicleIcon: VehicleImg,
      chevronIcon: ChevronIcon,
      successIcon: SuccessIcon,
      errorIcon: ErrorIcon,
    };
  },
  methods: {
    convertPlateNumber,
    onChange() {
      this.$emit("input", { ...this.data });
    },
    convertCode(code) {
      switch (code) {
        case "VEHICLE_EXISTED":
          return "Xe đã có trong đội";
        default:
          break;
      }
    },
    isExpired(data) {
      const pickVal = _.pick(data, [
        "vehicle_badge_expired_at",
        "register_expired_at",
        "insurance_expired_at",
        "inspection_expired_at",
        "cooperative_badge_expired_at",
      ]);

      const current = new Date();
      let isExpired = false;

      for (const item in pickVal) {
        const expired = new Date(pickVal[item]);
        if (expired.getTime() < current.getTime()) {
          isExpired = true;
          break;
        }
      }

      return isExpired;
    },
    getQuantityExpired(data) {
      const pickVal = _.pick(data, [
        "vehicle_badge_expired_at",
        "register_expired_at",
        "insurance_expired_at",
        "inspection_expired_at",
        "cooperative_badge_expired_at",
      ]);

      const current = new Date();
      let quantity = 0;

      for (const item in pickVal) {
        const expired = new Date(pickVal[item]);
        if (expired.getTime() < current.getTime()) {
          quantity += 1;
        }
      }

      return quantity;
    },
  },
};
</script>

<style lang="scss">
.float-checkbox {
  input[type="checkbox"],
  .check {
    position: absolute;
    left: auto;
    right: 16px;
  }
}
</style>

<style lang="scss" scoped>
.vehicle-card {
  text-align: left;
  margin-bottom: 16px;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  .inner-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .card-inner {
    position: relative;
  }

  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    .image {
      margin-right: 8px;
      max-width: 32px;
    }

    .info {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      width: 100%;
      .service {
        color: #a2a9b0;
      }
      .plate {
        font-size: 16px;
        font-weight: 700;
      }
      .alert {
        color: #f5222d;
        padding-bottom: 8px;
      }
    }
  }
}

.btn-navigate {
  position: relative;
  z-index: 2;
  cursor: pointer;
  padding-top: 8px;
  border: none;
  background: transparent;
  outline: none;
  color: #3a86ff;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  border-top: 1px solid #e5e7e8;
}

.float-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.noti-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.status {
  text-align: right;
  &.error {
    color: #f5222d;
  }
}
</style>
