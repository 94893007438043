<template>
  <div id="app">
    <div v-if="isLoading" style="
      width: 100dvw;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ">
        <div style="border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          animation: spin 2s linear infinite;"></div>
    </div>
    <router-view v-if="!isLoading" />
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  setup() {
    return {
      isLoading: ref(true),
    };
  },
  async created() {
    await this.setUpProfile();
    await this.getAhaOrderInfo();
    this.isLoading = false;
    console.log("done");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #007cff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
</style>
