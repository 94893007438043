<template>
  <div class="tab-wrapper">
    <VuePullRefresh :on-refresh="onRefresh" :config="config">
      <div class="inner">
        <DriverListItem
          v-for="(item, index) in list"
          :key="index"
          :data="item"
          :showLinkAction="showLinkAction"
          @open-detail="openDetail(item)"
          @action-connect="toggleConnect"
        />
      </div>
    </VuePullRefresh>

    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="error"
        title="Hủy liên kết xe"
        message="Tài xế sẽ không còn liên kết với xe này nữa. Chắc chắn hủy liên kết? "
        cancelText="Tiếp tục liên kết"
        :confirmText="confirmText"
        :loading="dialogLoading"
        @confirm="handleUnlinkVehicle"
        @cancel="closeAlert"
      />
    </b-modal>

    <b-modal
      class="link-modal"
      v-model="linkModal"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <LinkModal
        @back="linkModal = false"
        @action-linked-truck="handleLinkVehicle"
      />
    </b-modal>

    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VuePullRefresh from "vue-pull-refresh";
import { REFRESH_CONFIG } from "@/common/constants";
import CustomToast from "@/common/components/CustomToast";
import AlertDialog from "@/common/components/AlertDialog";
import DriverListItem from "@/pages/Management/components/DriverListItem";
import LinkModal from "@/pages/Management/components/DriverTab/LinkModal";
import { unlinkVehicle, linkVehicle } from "@/pages/Management/services/driver";

export default {
  name: "NoAssignedTab",
  components: {
    DriverListItem,
    LinkModal,
    CustomToast,
    AlertDialog,
    VuePullRefresh,
  },
  computed: {
    ...mapGetters({
      list: "managementStore/getNoAssignedDrivers",
    }),
  },
  mounted() {
    //TODO: init action api - get data from init state in store -- pass params format object
    const token = this.$route.query.supplier_token;
    this.getNoAssignedList({ token });
  },
  data() {
    return {
      config: REFRESH_CONFIG,
      showLinkAction: true,
      dialog: false,
      dialogLoading: false,
      confirmText: "Huỷ liên kết",
      linkModal: false,
      selectedId: "",
      isShowToast: false,
      type: "success",
      message: "",
      position: "bottom",
      unlinkProps: "",
    };
  },
  methods: {
    ...mapActions({
      getNoAssignedList: "managementStore/fetchNoAssignedDrivers",
    }),
    onRefresh() {
      const token = this.$route.query.supplier_token;

      return new Promise((resolve) => {
        this.getNoAssignedList({ token }).then(() => resolve());
      });
    },
    openAlert() {
      this.dialog = true;
    },
    closeAlert() {
      this.dialog = false;
      this.dialogLoading = false;
    },
    toggleConnect($value) {
      if ($value.vehicle_id && $value.vehicle_id !== "") {
        this.dialog = true;
        this.unlinkProps = {
          supplier_id: $value._id,
          plate: $value.vehicle_id,
        };
      } else {
        this.selectedId = $value._id;
        this.linkModal = true;
      }
    },
    async handleUnlinkVehicle() {
      this.confirmText = "";
      this.dialogLoading = true;
      const { supplier_id, plate } = this.unlinkProps;
      const token = this.$route.query.supplier_token;

      try {
        await unlinkVehicle(token, { supplier_id }, plate);
        await this.getNoAssignedList({ token });
        this.type = "success";
        this.message = `Hủy liên kết xe thành công`;
        this.isShowToast = true;
      } catch (error) {
        const { response } = error;
        this.type = "error";
        this.message = `Lỗi ${response.status} ! ${response.data.title}`;
        this.isShowToast = true;
      } finally {
        this.confirmText = "Huỷ liên kết";
        this.closeAlert();
      }

      this.autoCloseToast();
    },
    async handleLinkVehicle(plate_number) {
      const token = this.$route.query.supplier_token;
      const supplier_id = this.selectedId;

      try {
        await linkVehicle(token, { supplier_id }, plate_number);
        await this.getNoAssignedList({ token });
        this.type = "success";
        this.message = `Liên kết xe thành công`;
        this.isShowToast = true;
      } catch (error) {
        const { response } = error;
        this.type = "error";
        this.message = `Lỗi ${response.status} ! ${response.data.title}`;
        this.isShowToast = true;
      } finally {
        this.linkModal = false;
      }

      this.autoCloseToast();
    },
    autoCloseToast() {
      setTimeout(() => {
        this.message = ``;
        this.isShowToast = false;
      }, 2000);
    },
    openDetail(data) {
      //TODO path is driverId
      // this.setDriverDetail(data);
      const { id } = this.$route.params;
      const supplierToken = this.$route.query.supplier_token;
      this.$router.push({
        path: `/management/${id}/driver/detail/${data._id}`,
        query: { supplier_token: supplierToken },
      });
    },
  },
};
</script>
