<template>
  <div style="width: 100%; height: 100%; justify-content: flex-start; align-items: center; display: inline-flex">
    <div class="link-icon-wrapper">
      <div class="link-icon-align">
        <div class="link-icon">
          <img :src="linkIcon" alt="linkIcon" />
        </div>
      </div>
    </div>
    <div class="menu-item-text-wrapper">
      <div class="menu-item-text-main-align">
        <div class="menu-item-text-main">{{ mainText }}</div>
      </div>
      <div class="menu-item-text-sub-align">
        <div class="menu-item-text-sub">{{ subText }}</div>
        <div class="menu-item-text-timeout" v-show="timeoutSeconds > 0">
          Liên kết lại sau <span class="menu-item-text-timeout-color">{{ timeoutFormatted }}</span>
        </div>
      </div>
      <div class="menu-item-text-unlink" v-show="buttonType" @click="handleUnlinkClick">Hủy liên kết</div>
    </div>
    <div class="btn-link-wrapper">
      <div class="btn-link-align">
        <div
          :class="{ 'btn-link-box--linked': buttonType, 'btn-link-box--disabled': buttonDisabled }"
          class="btn-link-box"
        >
          <div
            :class="{ 'btn-link-text--linked': buttonType, 'btn-link-text--disabled': buttonDisabled }"
            class="btn-link-text"
            @click="handleButtonClick"
          >
            {{ buttonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkIcon } from "@/common/icons";

export default {
  name: "LinkSection",
  props: {
    mainText: String,
    subText: String,
    buttonType: Boolean,
    buttonText: String,
    initTimeout: Boolean,
  },
  computed: {
    timeoutFormatted() {
      const minutes = Math.floor(this.timeoutSeconds / 60)
        .toString()
        .padStart(2, "0");
      const seconds = (this.timeoutSeconds % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
  },
  data() {
    return {
      linkIcon: LinkIcon,
      timeoutSeconds: 0,
      buttonDisabled: false,
      timeoutMinute: 5,
    };
  },
  methods: {
    handleButtonClick() {
      if (this.buttonDisabled > 0) return;
      if (!this.buttonType) this.$emit("confirm-click");
    },
    handleUnlinkClick() {
      this.$emit("unlink-click");
    },
    startTimeoutCountdown() {
      this.timeoutCountdownInterval = setInterval(() => {
        if (this.timeoutSeconds > 0) {
          this.timeoutSeconds--;
          localStorage.setItem("otpTimeout", this.timeoutSeconds);
        } else {
          clearInterval(this.timeoutCountdownInterval);
          this.timeoutCountdownInterval = null;
          localStorage.removeItem("otpTimeout");
        }
      }, 1000);
    },
    handleTimeout() {
      const storedTimeout = localStorage.getItem("otpTimeout");
      this.timeoutSeconds = storedTimeout ? parseInt(storedTimeout) : 0;

      if (this.timeoutSeconds > 0) {
        this.startTimeoutCountdown();
      }
    },
  },
  beforeMount() {
    this.handleTimeout();
  },
  watch: {
    timeoutSeconds(newTimeout) {
      this.buttonDisabled = newTimeout > 0;
    },
    initTimeout(value) {
      if (value) {
        localStorage.setItem("otpTimeout", (this.timeoutMinute * 60).toString());
        this.handleTimeout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link-icon-wrapper,
.link-icon-align,
.link-icon {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.link-icon-align {
  align-self: center;
  padding: 16px;
}

.link-icon {
  width: 20px;
  height: 20px;
  position: relative;
  flex-direction: column;
}

.menu-item-text-wrapper {
  flex: 1 1 0;
  padding: 16px 0;
  flex-direction: column;
  gap: 4px;
  display: inline-flex;
}

.menu-item-text-main,
.menu-item-text-sub {
  text-align: left;
  font-size: 14px;
  font-family: "SVN-Gilroy";
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}

.menu-item-text-timeout {
  padding-top: 6px;
  text-align: left;
  color: #343a3f;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;

  &-color {
    color: #096dd9;
    font-weight: 600;
  }
}

.menu-item-text-main {
  color: #121619;
}

.menu-item-text-sub {
  color: #697077;
  font-size: 12px;
  font-weight: 500;
}

.menu-item-text-unlink {
  text-align: left;
  color: #cf1322;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}

.btn-link-wrapper {
  width: 115px;
  align-self: stretch;
  padding: 16px;
  gap: 10px;
  display: flex;
}

.btn-link-box {
  align-self: stretch;
  height: 36px;
  padding: 14px 16px;
  background: #e6f7ff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.btn-link-text {
  text-align: center;
  color: #1890ff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}

.btn-link-box--linked {
  background: #ebf4ec;
}
.btn-link-text--linked {
  color: #2f855a;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  word-wrap: break-word;
}

.btn-link-box--disabled {
  background: #e6e9ec;
}

.btn-link-text--disabled {
  color: #a2a9b0;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
}
</style>
