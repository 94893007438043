<template>
  <button
    :class="[`btn ${className}`, { disabled }]"
    :type="type || 'button'"
    :disabled="disabled"
    @click="clicked"
  >
    <div v-if="loading" class="loading-icon"></div>
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "CustomButton",
  props: ["label", "className", "loading", "disabled", "type"],
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: #f2f2f2;
  color: #142246;
  border-radius: 4px;
  padding: 14px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &.full-width {
    width: 100%;
  }

  &.primary {
    background-color: #3a86ff;
    color: #fff;
    &.link {
      color: #3a86ff;
    }
  }

  &.error {
    background-color: #f5222d;
    color: #fff;
  }

  &.disabled {
    background: #c1c7cd;
  }

  &.link {
    background-color: transparent;
    padding: 0;
  }
}

.loading-icon {
  &::after {
    content: "";
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    display: block;
    width: 24px;
    height: 24px;
    border-width: 0.25em;
  }
}
</style>
