<template>
  <div class="scrap-container" @click="openModify" :class="{'border-bottom--none':noBorderBottom}">
    <div class="img-container">
      <img :src="require(`@/assets/kamegomi/${imgData[img] || imgData['OTHER']}.png`)" alt="">
    </div>
    <div class="item-name">
      <h1>{{ name }}</h1></div>
    <div class="item-price">{{ price_unit.toLocaleString() }}đ/{{ unitType }}</div>
    <div v-if="weight > 0" class="weight-picker">
      <div class="modify-icon"><img src="../../../assets/kamegomi/modify.png" alt=""></div>
      <div>
        <div class="modify-price-unit m-bottom-modify-text"><span class="modify-name">{{ name }}</span>
          ({{ price_unit.toLocaleString() }}đ/{{ unitType }})
        </div>
        <div class="modify-weight m-bottom-modify-text">Số cân: {{ weight }}{{ unitType }}</div>
        <div class="modify-cash m-bottom-modify-text">Thành tiền: {{ (weight * price_unit).toLocaleString() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ScrapItem",
  props: {
    name: String,
    price_unit: {
      type: [String, Number]
    },
    img: String,
    weight: {
      type: [Number, String]
    },
    itemId: String,
    noBorderBottom: {
      type: Boolean,
      default: false
    },
    unitType: String,
    order_proof: String,
  },
  computed: {
    ...mapState('kamegomi', ['imgData'])
  },
  methods: {
    openModify() {
      this.$emit("update-scrap", {
        id: this.itemId,
        img: this.img,
        weight: this.weight,
        price_unit: this.price_unit,
        name: this.name,
        unit_type: this.unitType,
        order_proof: this.order_proof,
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.scrap-container {
  border: solid #E8E8E8;
  border-width: 0 0.25px 1px 0.25px;
  padding: 15px 0;
  position: relative;
  text-align: center;

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    img {
      width: 30%;
      height: auto;
    }
  }

  .item-name {
    font-weight: 600;
    font-size: 18px;
  }

  .item-price {
    font-size: 12px;
  }

  .weight-picker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: white;

    .modify-icon {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .modify-price-unit {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    .modify-weight {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }

    .modify-cash {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px
    }

    .modify-name {
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.border-bottom--none {
  border-bottom: none;
}

.m-bottom-modify-text {
  margin-bottom: 10px
}
</style>
