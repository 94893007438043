<template>
  <div class="vehicle-management page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBackMenu">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Quản lý xe</p>
      </CustomTopBar>
    </div>

    <div class="content-box">
      <b-tabs
        expanded
        class="custom-tab"
        size="is-large"
        v-model="tabIndex"
        @input="onChange"
      >
        <b-tab-item label="Đã liên kết">
          <LinkedTab :tabIndex="tabIndex" />
        </b-tab-item>
        <b-tab-item label="Chưa liên kết">
          <NotLinkedTab :tabIndex="tabIndex" />
        </b-tab-item>
        <!-- TODO: Scale to Verifying Tab -->
        <!-- <b-tab-item label="Đang xác minh">
          <ProcessTruckTab />
        </b-tab-item> -->
      </b-tabs>
    </div>

    <span class="icon ic-float btn-float" @click="addTruck">
      <inline-svg :src="floatIcon"></inline-svg>
    </span>

    <b-modal
      class="add-modal"
      v-model="addModal"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <AddTruckModal @back="addModal = false" @confirm="onConfirmAddModal" />
    </b-modal>
    <b-modal
      class="gps-house-modal"
      v-model="gpsHouseModal"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <GPSHouseModal
        @back="gpsHouseModal = false"
        @confirm="onConfirmAddModal"
      />
    </b-modal>

    <BottomModal :open="open" @close="open = false">
      <div class="bottom-menu">
        <button type="button" @click="addManualTruck">Thêm xe thủ công</button>
        <button type="button" @click="addGPSHouse">
          Thêm từ hệ thống nhà GPS
        </button>
        <button type="button" @click="open = false">Huỷ</button>
      </div>
    </BottomModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ChevronIcon, AddIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import LinkedTab from "@/pages/Management/components/TruckTab/LinkedTab";
import NotLinkedTab from "@/pages/Management/components/TruckTab/NotLinkedTab";
import BottomModal from "@/common/components/BottomModal";
import AddTruckModal from "@/pages/Management/components/TruckTab/AddModal";
import GPSHouseModal from "@/pages/Management/components/GPSHouse/GPSHouseModal";

export default {
  name: "VehicleManagement",
  components: {
    CustomTopBar,
    LinkedTab,
    NotLinkedTab,
    AddTruckModal,
    GPSHouseModal,
    BottomModal,
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      floatIcon: AddIcon,
      tabIndex: 0,
      open: false,
      addModal: false,
      gpsHouseModal: false,
    };
  },
  methods: {
    ...mapActions({
      getLinkedList: "managementStore/fetchLinkedVehicles",
      getNotLinkedList: "managementStore/fetchUnlinkedVehicles",
    }),
    goBackMenu() {
      this.$router.back();
    },
    onChange(index) {
      const token = this.$route.query.supplier_token;
      if (index === 0) {
        this.getLinkedList({ token });
      }

      if (index === 1) {
        this.getNotLinkedList({ token });
      }
    },
    addTruck() {
      this.open = true;
    },
    addManualTruck() {
      this.addModal = true;
      this.open = false;
    },
    addGPSHouse() {
      this.gpsHouseModal = true;
      this.open = false;
    },
    onConfirmAddModal() {
      const token = this.$route.query.supplier_token;
      this.addModal = false;
      this.gpsHouseModal = false;
      this.tabIndex = 1;
      this.getNotLinkedList({ token });
    },
  },
};
</script>

<style lang="scss">
.vehicle-management {
  .b-tabs .tab-content {
    background-color: #f2f2f2;
    min-height: calc(100vh - 105px);
  }

  .bottom-menu {
    display: flex;
    flex-direction: column;
    button {
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #121619;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      padding: 16px 0;
      &:first-child {
        border-bottom: 1px solid #e5e7e8;
      }
      &:last-child {
        margin-top: 8px;
        padding: 16px;
        text-align: center;
        background-color: #f2f2f2;
        color: #142246;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.pull-down-header {
  background-color: transparent;
}
</style>
