<template>
  <div class="toast-bottom" :class="`toast-bottom_${type}`">
    <img v-if="type === 'success'" class="toast-bottom__image" src="@/assets/supplier-listing/success-icon.png" alt=""/>
    <img v-else class="toast-bottom__image" src="@/assets/supplier-listing/failed-icon.png" alt=""/>
    <div class="toast-bottom__text">{{ type === 'success' ? `Đơn hàng đã được gắn thành công cho tài xế ${supplier_name}` : message }}</div>
    <!--    <div v-if="type === 'success'">-->
    <!--      <img class="toast-bottom__image" src="@/assets/supplier-listing/success-icon.png" />-->
    <!--      <div class="toast-bottom__text">Đơn hàng đã được gắn thành công cho tài xế {{ this.supplier_name }}</div>-->
    <!--    </div>-->
    <!--    <div v-else>-->
    <!--      <img class="toast-bottom__image" src="@/assets/supplier-listing/failed-icon.png" />-->
    <!--      <div class="toast-bottom__text">Đơn hàng đã được gắn thành công cho tài xế {{ this.supplier_name }}</div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ToastNew",
  props: ["type", "message"],
  data() {
    return {
      img: {
        success: '@/assets/supplier-listing/success-icon.png',
        failed: '@/assets/supplier-listing/failed-icon.png'
      }
    };
  },
  computed: { ...mapState("supplierList", ["supplier_name"]) }
};
</script>

<style lang="scss">
.toast-bottom {
  position: relative;
  bottom: 8px;

  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &_success {
    background: #E8F9EF;
  }

  &_failed {
    background: #FFEBEA;
  }

  &__image {
    width: 24px;
    height: auto;
    margin: 12px 0 12px 12px;
  }

  &__text {
    margin: 12px 12px 12px 16px;

    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
}
</style>