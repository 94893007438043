<template>
  <div class="top-bar">
    <div class="left-children">
      <img class="back-icon" @click="clickBack()" :src="back_icon" alt="left-arrow" />
    </div>
    <div class="title">{{ title }}</div>
    <img
        v-if="refreshIconPath"
        class="refresh-children"
        @click="$emit('refreshClick')"
        :src="require(`@/assets/${refreshIconPath}`)"
        alt=""
    />
  </div>
</template>

<script>
import back_icon from "@/assets/supplier-listing/back-icon.png";
import router from "@/router";
import { mapState } from "vuex";

export default {
  name: "TopBarNew",
  props: ["title", "refreshIconPath"],
  data() {
    return {
      back_icon,
    };
  },
  computed: {
    ...mapState("supplierList", ["supplier_token", "open_in_app"]),
  },
  methods: {
    go(event) {
      this.postMessage({
        name: event,
      });
    },
    navigateOrderListing() {
      this.$router.push({
        path: `/order-listing?supplier_token=${this.supplier_token}`,
      });
    },
    clickBack() {
      router.go(-1);
      /*if (this.open_in_app) this.go("EVENT_BACK");
      else this.navigateOrderListing();*/
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  background: #142246;
  position: relative;
  padding: 16px;

  .left-children {
    position: absolute;
    left: 16px;

    .back-icon {
      width: 8px;
      height: 12px;
    }
  }

  .title {
    display: contents;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #ffffff;
  }

  .refresh-children {
    position: absolute;
    right: 16px;
    width: 20px;
    height: 20px;
  }
}
</style>
