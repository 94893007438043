<template>
  <div class="top-bar">
    <img
      v-if="leftIconPath"
      class="left-children"
      @click="$emit('leftChildClick')"
      :src="require(`@/assets/${leftIconPath}`)"
      alt=""
    />
    <div class="title">{{ title }}</div>
    <img
      v-if="refreshIconPath"
      class="refresh-children"
      @click="$emit('refreshChildClick')"
      :src="require(`@/assets/${refreshIconPath}`)"
      alt=""
    />
    <img
      v-if="rightIconPath"
      class="right-children"
      @click="$emit('rightChildClick')"
      :src="require(`@/assets/${rightIconPath}`)"
      alt=""
    />
  </div>
</template>

<script>
// import back_icon from "@/assets/order-listing/left-arrow.png";
// import filter_icon from "@/assets/order-listing/filter-icon.png";

export default {
  name: "TopBar",
  props: ["title", "leftIconPath", "rightIconPath", "refreshIconPath"],
  data() {
    return {
      // back_icon,
      // filter_icon
    };
  },
  methods: {
    go(event) {
      this.postMessage({
        name: event,
      });
    },
    chooseFilter() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  background: #142246;
  position: relative;
  padding: 16px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .left-children {
    position: absolute;
    left: 16px;
    width: 16px;
    height: 16px;
  }

  .refresh-children {
    position: absolute;
    right: 48px;
    width: 20px;
    height: 20px;
  }

  .right-children {
    position: absolute;
    right: 16px;
    width: 24px;
    height: 24px;
  }

  .title {
    display: contents;
    font-family: "SVN-Gilroy";
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #ffffff;
  }
}
</style>
