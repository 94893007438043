<template>
  <div>
    <Toast v-show="isToastShow" :type="toastStatus" :message="toastText"/>
  </div>
</template>

<script>
import client from "@/services/client";
import Toast from "@/common/components/Toast";

export default {
  name: "EContract",
  components: {Toast},
  data: () => ({
    isToastShow: false,
    toastStatus: null,
    toastText: null,

  }),
  async mounted() {
    let contract_id = null;
    if (this.$route.params.contract_id && this.$route.params.contract_id !== '') {
      contract_id = this.$route.params.contract_id;
      console.log({contract_id});
    } else return;

    let contract_status = 0, contract_url = '';
    try {
      const res = await client.appApiEContract({
            method: 'get',
            url: `/check_status/${contract_id}`,
          }
      );
      if (!res || !res.data || !res.data.Status) {
        this.isToastShow = true;
        this.toastStatus = 'failed';
        this.toastText = ' Bad request'
        return setTimeout(() => {
          this.postMessage({
            name: this.EVENT_CLOSE
          });
        }, 5000);
      }

      if (res.data.Status && res.data.Status !== 1) {
        this.isToastShow = true;
        this.toastStatus = 'failed';
        this.toastText = ' Hợp đồng chưa tạo thành công vui lòng chờ giây lát.'
        return setTimeout(() => {
          this.postMessage({
            name: this.EVENT_CLOSE
          });
        }, 5000);
      }

      if (res.data.Status === 1) {
        contract_status = res.data.Status;
        contract_url = res.data.SignUrl;
      }
    } catch (e) {
      console.log({e})
      this.isToastShow = true;
      this.toastStatus = 'failed';
      this.toastText = ' Không tìm thấy contract_id'
      return setTimeout(() => {
        this.postMessage({
          name: this.EVENT_CLOSE
        });
      }, 5000);
    }

    console.log({contract_status, contract_url})
    if (contract_status === 1)
      window.location.href = contract_url
  }
}
</script>

<style scoped>

</style>