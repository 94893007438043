<template>
  <div class="date-range__wrapper">
    <div class="date-range__display" @click="calendarState = true">
      <div class="date-range__display--group">
        <div class="date-range__display--label">
          Ngảy bắt đầu - Ngày kết thúc
        </div>
        <div class="date-range__display--content">{{ dateLabel }}</div>
      </div>
      <div class="date-range__display--img">
        <img :src="calendarIcon" alt="calendar_icon" />
      </div>
    </div>

    <b-modal
      v-model="calendarState"
      has-modal-card
      full-screen
      scroll="keep"
      :can-cancel="false"
    >
      <CalendarComponent
        @date-label="dateLabel = $event"
        @date-range="$emit('date-range', $event)"
      />
    </b-modal>
  </div>
</template>

<script>
import { format, endOfDay } from "date-fns";
import CalendarIcon from "@/assets/reporting/calendar-icon.svg";
import CalendarComponent from "./CalendarComponent";

export default {
  name: "CalendarView",
  components: { CalendarComponent },
  data() {
    return {
      calendarState: false,
      calendarIcon: CalendarIcon,
      dateLabel: "",
    };
  },
  mounted() {
    this.getDefaultLabel();
  },
  methods: {
    getDefaultLabel() {
      const today = new Date();
      const endDay = format(endOfDay(today), "dd/MM/yyyy");
      const startDate = new Date(new Date().setDate(today.getDate() - 7));
      const startDay = format(startDate, "dd/MM/yyyy");
      this.dateLabel = `${startDay} - ${endDay}`;
    },
  },
};
</script>

<style lang="scss">
.date-range {
  &__display {
    text-align: left;
    padding: 8px 16px;
    border: solid 1px #dde1e6;
    border-radius: 3px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--label {
      font-size: 12px;
      line-height: 20px;
      color: #b1b6d3;
    }
    &--content {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>
