<template>
  <div style="height: auto; overflow: auto">
    <b-table
      :data="tableData"
      :sticky-header="true"
      :mobile-cards="false"
      striped
      height="85vh"
      narrowed
      :loading="isLoading"
    >
      <b-table-column
        v-for="col in columns"
        v-bind="col"
        :key="col.field"
        v-slot="props"
      >
        <div @click="showConfirmModal(props.row)" class="supplier-table-row">
          <div
            :class="
              col.field === '_id'
                ? `supplier_status supplier_status_${props.row['status']}`
                : ''
            "
          >
            {{ props.row[col.field] }}
          </div>
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">
          {{ isLoading ? "" : "Không tìm thấy tài xế nào!" }}
        </div>
      </template>
    </b-table>
    <Modal
      noPadding
      isCloseOnClickOutside
      v-show="isShowConfirmModal"
      @close="toggleConfirmModal(false)"
    >
      <div class="confirm-modal-wrapper">
        <ModalConfirmAssign
          :supplier_id="supplier_id"
          :supplier_name="supplier_name"
          @assign="showAssignStatus"
          @close="toggleConfirmModal(false)"
        />
      </div>
    </Modal>
    <Modal
      noPadding
      :is-close-on-click-outside="messageType === 'error'"
      v-show="isShowSuccessModel"
      @close="toggleNotiModal(false)"
    >
      <PopupModal :message="success_message" :type="messageType" />
    </Modal>
  </div>
</template>

<script>
import ModalConfirmAssign from "@/pages/SupplierListing/components/Listing/ModalConfirmAssign";
import { mapState } from "vuex";
import PopupModal from "@/pages/SupplierListing/components/Listing/PopupModal";
import Modal from "../../../../common/components/Modal";
import { adminPushNotification } from "@/pages/SupplierListing/services/adminService";

export default {
  name: "Table",
  components: { PopupModal, ModalConfirmAssign, Modal },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    supplier_id: "",
    supplier_name: "",
    isShowConfirmModal: false,
    isShowSuccessModel: false,
    success_message: "",
    messageType: "",
  }),
  computed: {
    ...mapState("supplierList", [
      "master_supplier_id",
      "supplier_token",
      "order_id",
      "order_service",
      "user_id",
      "admin_token",
    ]),
    columns() {
      return [
        {
          field: "_id",
          label: "Số điện thoại",
          searchable: true,
          centered: true,
          sticky: true,
          headerClass: "is-sticky-column-one",
          cellClass: "is-sticky-column-one",
        },
        {
          field: "name",
          label: "Tên",
          searchable: true,
          centered: true,
          width: "40",
        },
        {
          field: "vehicle",
          label: "Phương tiện",
        },
        {
          field: "distance",
          label: "Khoảng cách",
          sortable: true,
        },
        {
          field: "last_activity",
          label: "Thời gian",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    showConfirmModal(item) {
      this.supplier_id = item._id;
      this.supplier_name = item.name;
      this.isShowConfirmModal = true;
    },
    showAssignStatus(e) {
      this.isShowConfirmModal = false;
      if (e === "success") {
        // console.log(`env: ${JSON.stringify(process.env)}`);
        // pushAssignedNotification(this.admin_token, this.master_supplier_id, this.order_id, this.order_service, this.user_id, this.supplier_id, this.supplier_name);
        adminPushNotification(
          this.master_supplier_id,
          this.order_id,
          this.order_service,
          this.user_id,
          this.supplier_id,
          this.supplier_name
        );
        this.success_message = `Đơn hàng đã được gán thành công cho ${this.supplier_name}.`;
        this.messageType = "success";
        this.isShowSuccessModel = true;
        setTimeout(() => {
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      } else {
        this.success_message = `Đã xảy ra lỗi, xin vui lòng thử lại`;
        this.messageType = "error";
        this.isShowSuccessModel = true;
      }
    },
    toggleNotiModal(status) {
      this.isShowSuccessModel = status;
    },
    toggleConfirmModal(status) {
      this.isShowConfirmModal = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier_status {
  border-radius: 5px;
  padding: 5px 5px;
  color: white;
  font-weight: 500;

  &_ONLINE {
    background-color: #79cf53;
  }

  &_BUSY {
    background-color: #ff1025;
  }

  &_OFFLINE {
    background-color: #757575;
  }

  &_COMPLETING {
    background-color: #fad733;
  }
}

.supplier-table-row {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-wrapper {
  padding: 15px;
}
</style>

<style lang="scss">
td {
  height: 80px;
  width: 160px;
  text-align: center;
  vertical-align: middle;
}
</style>