import BigCMain from "@/houses/bigc/BigCMain";
import HousesMain from "@/houses/HousesMain";
import CODForm from "@/pages/AppAnCuong/CODForm";
import QRCode from "@/pages/AppAnCuong/QRCode";
import EmbeddedActions from "@/pages/AppEmbedded/EmbeddedActions";
import EmbeddedMain from "@/pages/AppEmbedded/views/EmbeddedMain";
import Iqos from "@/pages/AppIqos/Iqos";
import Collector from "@/pages/AppKamegomi/Collector";
import CollectMain from "@/pages/AppKamegomi/views/CollectMain";
import ConfirmPage from "@/pages/AppKamegomi/views/ConfirmPage";
import Utilities from "@/pages/AppUtilities/Utilities";
import IframeView from "@/pages/AppUtilities/views/IframeView";
import UtilitiesHome from "@/pages/AppUtilities/views/UtilitiesHome";
import Caller from "@/pages/Caller/Caller";
import EContract from "@/pages/EContract/EContract";
import LiveMap from "@/pages/LiveMap/LiveMap";
import Management from "@/pages/Management/Management";
import DocumentUpdate from "@/pages/Management/views/document/DocumentUpdate";
import InspectionView from "@/pages/Management/views/document/InspectionView";
import VehicleBadgeView from "@/pages/Management/views/document/VehicleBadgeView";
import VehicleInfoView from "@/pages/Management/views/document/VehicleInfoView";
import DriverDetail from "@/pages/Management/views/driver/DriverDetail";
import DriverInfo from "@/pages/Management/views/driver/DriverInfo";
import DriverList from "@/pages/Management/views/driver/DriverList";
import DriverManagement from "@/pages/Management/views/driver/DriverManagement";
import DriverReporting from "@/pages/Management/views/driver/DriverReport";
import DriverService from "@/pages/Management/views/driver/DriverService";
import Notification from "@/pages/Management/views/Notification";
import VehicleDetail from "@/pages/Management/views/vehicle/VehicleDetail";
import VehicleList from "@/pages/Management/views/vehicle/VehicleList";
import VehicleManagement from "@/pages/Management/views/vehicle/VehicleManagement";
import OrderListing from "@/pages/OrderListing/OrderListing";
import Profile from "@/pages/Profile/Profile";
import Reporting from "@/pages/Reporting/Reporting";
import Stock from "@/pages/Stock/Stock";
import SupplierListing from "@/pages/SupplierListing/SupplierListing";
import SupplierListingNew from "@/pages/SupplierListing/SupplierListingNew";
import PaymentHistory from "@/pages/PaymentHistory/PaymentHistory";

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: EmbeddedActions,
    children: [{ path: "", name: "EmbeddedActions", component: EmbeddedMain }],
  },
  { path: "/e_contract/:contract_id", name: "EContract", component: EContract },
  {
    path: "/utilities",
    component: Utilities,
    children: [
      { path: "", name: "Utilities", component: UtilitiesHome },
      { path: "iframe", name: "IframeView", component: IframeView },
    ],
  },
  { path: "/iqos-demo/:phone", name: "iqos-page", component: Iqos },
  {
    path: "/kame-gomi",
    component: Collector,
    children: [
      { path: "", name: "KameGomi", component: CollectMain },
      { path: "confirm", name: "Confirm", component: ConfirmPage },
    ],
  },
  { path: "/order-listing", name: "OrderListing", component: OrderListing },
  { path: "/supplier-listing", name: "SupplierListing", component: SupplierListing },
  { path: "/supplier-listing-new", name: "SupplierListingNew", component: SupplierListingNew },
  { path: "/reporting", name: "Reporting", component: Reporting },
  { path: "/profile/:id", name: "Profile", component: Profile },
  {
    path: "/management/:id",
    component: Management,
    children: [
      { path: "vehicle", name: "VehicleManagement", component: VehicleManagement },
      { path: "vehicle/list", name: "VehicleList", component: VehicleList },
      { path: "vehicle/detail/:vehicleId", name: "VehicleDetail", component: VehicleDetail },
      { path: "driver", name: "DriverManagement", component: DriverManagement },
      { path: "driver/list", name: "DriverList", component: DriverList },
      { path: "driver/detail/:driverId", name: "DriverDetail", component: DriverDetail },
      { path: "driver/services/:driverId", name: "DriverService", component: DriverService },
      { path: "driver/info/:driverId", name: "DriverInfo", component: DriverInfo },
      { path: "driver/report/:driverId", name: "DriverReporting", component: DriverReporting },
      { path: "notification", name: "Notification", component: Notification },
      { path: "documents/:vehicleId", name: "Documents", component: DocumentUpdate },
      { path: "documents/:vehicleId/inspection", name: "InspectionView", component: InspectionView },
      { path: "documents/:vehicleId/info", name: "VehicleInfoView", component: VehicleInfoView },
      { path: "documents/:vehicleId/badge", name: "VehicleBadgeView", component: VehicleBadgeView },
    ],
  },
  {
    path: "/houses",
    name: "Houses",
    component: HousesMain,
    children: [{ path: "bigc/reporting/:id", name: "BigCMain", component: BigCMain }],
  },
  { path: "/an-cuong/qrcode", name: "an-cuong-qrcode", component: QRCode },
  { path: "/an-cuong/cod", name: "an-cuong-cod", component: CODForm },
  { path: "/caller/:id", name: "Caller", component: Caller },
  { path: "/stock", name: "Stock", component: Stock },
  { path: "/live-map", name: "LiveMap", component: LiveMap },
  { path: "/payment-history", name: "payment-history", component: PaymentHistory },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
