<template>
  <div class="container houses">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "HouseMain",
};
</script>

<style lang="scss" scoped>
.houses {
  font-family: SVN-Gilroy;
}
</style>
