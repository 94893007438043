<template>
  <div>
    <div v-if="orders.length > 0">
      <div class="columns table-column">
        <div v-for="item in orders" :key="item._id"
             class="column is-half-tablet is-one-third-fullhd">
          <OrderCard :data="item" />
        </div>
      </div>
    </div>
    <div v-else-if="orders.length === 0 && !loading"
         class="no-records">
      <img class="no-records--icon" :src="searchEmptyIcon"
           alt="search_empty_icon" />
      Không có kết quả tìm kiếm. Vui lòng thử lại.
    </div>
  </div>
</template>

<script>
import SearchEmptyIcon from "@/assets/supplier-listing/search-empty.png";
import OrderCard from "@/pages/OrderListing/components/OrderCard";

export default {
  name: "OrderTable",
  components: { OrderCard },
  props: ["orders", "loading"],
  data() {
    return {
      searchEmptyIcon: SearchEmptyIcon,
      data: []
    };
  }
};
</script>

<style lang="scss" scoped>

.columns {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-records {
  flex-direction: column;
  font-family: SVN-Gilroy;
  font-size: 14px;
  font-style: normal;
  margin-top: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: calc(50% - 84px);
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303A46;
  opacity: 0.5;

  &--icon {
    width: 126px;
  }
}
</style>