<template>
  <div class="modal-box">
    <div class="modal-header">
      <!--<img class="modal-image" :src="require('@/assets/supplier-listing/call-icon.png')" />-->
      <div class="modal-title">{{ title }}</div>
    </div>
    <div class="modal-body">
      <div class="">
        Bạn muốn gọi tới số <strong>{{ this.supplier_id }}</strong> - <strong>{{ this.supplier_name }}</strong>?
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn-cancel" v-on:click="cancel">Hủy</button>
      <button class="btn-confirm" v-on:click="confirmCall">
        <a class="btn-confirm__text" :href="`tel:+${this.supplier_id}`">Đồng ý</a>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CallerModal",
  data() {
    return {
      title: "Gọi tài xế"
    };
  },
  computed: {
    ...mapState("supplierList", ["supplier_id", "supplier_name"])
  },
  methods: {
    ...mapActions("supplierList", ["actionSetShowCallerModal"]),
    cancel() {
      return this.actionSetShowCallerModal({ value: false });
    },
    confirmCall() {
      return this.actionSetShowCallerModal({ value: false });
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-box {
  font-family: 'SVN-Gilroy';

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  height: auto;
  padding: 16px;
  z-index: 20;

  background: #FFFFFF;
  border-radius: 4px;

  .modal-header {
    .modal-image {
      height: 40px;
      width: 40px;
    }

    .modal-title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 8px 0;

      color: #343A3F;
    }
  }

  .modal-body {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    margin: 8px 0;

    color: #697077;
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;

    .btn {
      &-confirm {
        border: 0;
        border-radius: 2px;

        height: 48px;
        width: 140px;

        background: #3A86FF;

        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;

          color: #FFFFFF;
        }
      }

      &-cancel {
        border: 0;
        border-radius: 2px;

        height: 48px;
        width: 140px;

        background: #F2F2F2;

        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;

          color: #142246;
        }
      }
    }
  }
}
</style>