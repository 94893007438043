<template>
  <div class="qrcode-wrapper">
    <div class="heading">Mã QR</div>
    <div class="qrcode-content" v-if="value">
      <qrcode :value="value"></qrcode>
    </div>
    <div class="loader" v-else></div>
  </div>
</template>

<script>
import { getQRString } from "@/pages/AppAnCuong/services";
import { mapState } from "vuex";

export default {
  name: "ACApp",
  computed: {
    ...mapState({
      ahaOrderId: (state) => state.ahaOrderId,
    }),
  },
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    const orderId = this.$route.query.orderId || this.ahaOrderId;
    const params = { orderId };
    if (this.$route.query.pathIndex) params.pathIndex = parseInt(this.$route.query.pathIndex);
    this.init(params);
  },
  methods: {
    async init(params) {
      const response = await getQRString(params);
      this.value = response.qrcode;
    },
  },
  // watch: {
  //   async ahaOrderId(newValue) {
  //     let params = { orderId: newValue };
  //     if (this.$route.query.pathIndex) params.pathIndex = parseInt(this.$route.query.pathIndex);
  //     this.init(params);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.qrcode-wrapper {
  padding: 16px;
  font-family: SVN-Gilroy;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}

.qrcode-content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

canvas {
  max-width: 250px !important;
  max-height: 250px !important;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #007cff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
