<template>
  <div class="container dashboard">
    <Header />
    <div class="analytic__wrapper">
      <Calendar @date-range="getDateRange($event)" />
      <Board
        :income="managerInfo.total_fee"
        :vehicle="managerInfo.total_driver"
        :orders="managerInfo.total_order"
      />
    </div>
    <Divider />
    <Table :drivers="drivers" :loading="loading" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import Divider from "@/common/components/CustomDivider";
import Header from "@/pages/Reporting/components/Header";
import Calendar from "@/pages/Reporting/components/Calendar/Calendar";
import Board from "@/pages/Reporting/components/Board";
import Table from "@/pages/Reporting/components/Table/Table";
import {
  fetchAsyncDrivers,
  fetchAsyncManagerInfo,
} from "@/pages/Reporting/services/reportService";

export default {
  name: "Reporting",
  components: { Header, Calendar, Board, Divider, Table },
  computed: {
    ...mapState(["token"]),
  },
  data() {
    return {
      dateRange: "",
      managerInfo: "",
      drivers: "",
      supplierToken: "",
      loading: false,
    };
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  mounted() {
    // this.supplierToken = this.token;
    this.supplierToken = this.$route.query.supplier_token;
    this.actionSetSupplierToken({ value: this.supplierToken });
    this.getManagerInfo(this.supplierToken);
    this.getDrivers(this.supplierToken);
  },
  methods: {
    ...mapActions("supplierList", ["actionSetSupplierToken"]),
    getDateRange($event) {
      this.dateRange = $event;
      const start_date = format(new Date($event[0]), "yyyy-MM-dd");
      const end_date = format(new Date($event[1]), "yyyy-MM-dd");
      this.getManagerInfo(this.supplierToken, start_date, end_date);
      this.getDrivers(this.supplierToken, start_date, end_date);
    },
    async getManagerInfo(token, start_date, end_date) {
      const data = await fetchAsyncManagerInfo(token, start_date, end_date);
      const managerInfo = _.filter(data, (el) => el.supplier_id === "Chủ xe");
      this.managerInfo = managerInfo[0];
    },
    async getDrivers(token, start_date, end_date) {
      try {
        this.drivers = await fetchAsyncDrivers(token, start_date, end_date);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.analytic__wrapper {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

.dashboard {
  font-family: SVN-Gilroy;
}
</style>
