<template>
  <div class="otp">
    <div class="otp-header">
      <div class="otp-header-nav">
        <inline-svg :src="BackArrowIcon" alt="back_arrow" @click="$emit('close-otp')" />
      </div>
      <div class="otp-header-text">Nhập mã OTP</div>
      <div class="otp-header-subtext">
        {{ `Vui lòng nhập mã xác thực vừa được gửi đến số điện thoại ${supplier_id} của Tài xế ${supplier_name}` }}
      </div>
    </div>
    <div class="otp-body">
      <div style="display: flex">
        <VueOtp2
          :length="otpLength"
          join-character=""
          inputmode="numeric"
          pattern="[0-9]*"
          @onChange="handleOnChange"
          @onComplete="handleOnComplete"
        />
      </div>
      <div class="otp-body-error" v-show="otpError">Mã không đúng. Vui lòng thử lại!</div>
      <div class="otp-body-resend" v-show="countdownSeconds">
        <div class="otp-body-resend-text">Chưa nhận được mã?</div>
        <div class="otp-body-resend-button" :class="{ disabled: resendDisabled }" @click="resendOtp">
          {{ resendButtonText }}
        </div>
      </div>
    </div>
    <ModalConfirm
      v-if="showTimeoutModal"
      :header-text="timeoutModalHeaderText"
      :body-text="timeoutModalBodyText"
      confirm-text=""
      cancel-type="dismiss"
      @cancel-click="closeTimeoutModal"
    />
  </div>
</template>

<script>
import { BackArrowIcon } from "@/common/icons";
import ModalConfirm from "@/pages/Management/components/ModalConfirm";
import { activeChildAccount } from "@/pages/Management/services/driver";
import VueOtp2 from "vue-otp-2";

export default {
  name: "OTP",
  props: {
    supplier_name: String,
    supplier_id: String,
    token: String,
  },
  components: {
    ModalConfirm,
    VueOtp2,
  },
  data() {
    return {
      BackArrowIcon,
      otpDigits: [],
      otpLength: 4,
      otpError: false,
      initCountdownSeconds: 60,
      countdownSeconds: null,
      countdownInterval: null,
      incorrectAttempts: 0,
      maximumIncorrectAttempts: 5,
      showTimeoutModal: false,
      timeoutModalHeaderText: "Liên kết thất bại",
      timeoutModalBodyText: `Bạn đã nhập sai OTP 5 lần liên tiếp. Vui lòng thử liên kết lại sau.`,
      timeoutModalConfirmText: "OK",
    };
  },
  computed: {
    resendDisabled() {
      return this.countdownSeconds > 0;
    },
    resendButtonText() {
      if (this.countdownSeconds > 0) {
        return `Gửi lại OTP sau ${this.countdownSeconds} giây`;
      } else {
        return "Gửi lại OTP";
      }
    },
  },
  methods: {
    async handleOnComplete(value) {
      console.log("OTP completed: ", value);
      this.otpDigits = value;
      try {
        this.startCountdown();
        this.countdownSeconds = this.initCountdownSeconds;
        const res = await activeChildAccount(this.supplier_id, this.otpDigits.join(""), this.token);
        this.resetIncorrectAttempts(); // Reset incorrectAttempts on successful OTP entry
        this.$emit("show-toast", {
          type: "success",
          message: "Thêm tài khoản con thành công!",
        });
        this.$emit("otp-success");
      } catch (error) {
        this.incorrectAttempts++;
        if (this.incorrectAttempts === this.maximumIncorrectAttempts) {
          this.clearCountdown();
          this.showTimeoutModal = true;
          this.disableOtpInputForTimeout();
          setTimeout(this.resetIncorrectAttempts, this.timeoutMinute * 60 * 1000);
          // localStorage.setItem("otpTimeout", (this.timeoutMinute * 60).toString());
          this.$emit("otp-timeout");
        } else {
          this.otpError = true;
          const { code, http_code, description } = error.response.data;
          this.$emit("show-toast", {
            type: "error",
            message: `${code} - ${http_code} - ${description}`,
          });
        }
      }
    },
    handleOnChange(value) {
      this.otpDigits = "";
      this.otpError = false;
      console.log("OTP changed: ", value);
    },
    startCountdown() {
      if (this.countdownInterval !== null) {
        return;
      }
      this.countdownInterval = setInterval(() => {
        if (this.countdownSeconds > 0) {
          this.countdownSeconds--;
          localStorage.setItem("otpCountdown", this.countdownSeconds);
        } else {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          localStorage.removeItem("otpCountdown");
        }
      }, 1000);
    },
    clearCountdown() {
      if (this.countdownInterval === null) {
        return;
      }
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      localStorage.removeItem("otpCountdown");
    },
    resendOtp() {
      if (this.countdownSeconds > 0) {
        return;
      }
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.handleOnComplete(this.otpDigits);
    },
    resetIncorrectAttempts() {
      this.incorrectAttempts = 0;
    },
    disableOtpInputForTimeout() {
      return;
    },
    closeTimeoutModal() {
      this.showTimeoutModal = false;
      this.$emit("otp-timeout");
      this.$emit("close-otp");
    },
  },
  beforeMount() {
    const storedCountdown = localStorage.getItem("otpCountdown");
    if (storedCountdown !== null) {
      this.countdownSeconds = parseInt(storedCountdown);
      if (this.countdownSeconds > 0) {
        this.startCountdown();
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval);
  },
};
</script>

<style lang="scss" scoped>
.otp {
  font-family: "SVN-Gilroy";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: #ffffff;
  padding: 0 16px;

  &-header {
    height: fit-content;
    padding-bottom: 16px;

    &-nav {
      display: flex;
      padding: 16px 0 10px 0;
    }

    &-text {
      color: #252a31;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      word-wrap: break-word;
      text-align: left;
    }

    &-subtext {
      color: #697077;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      word-wrap: break-word;
      text-align: left;
      padding-top: 12px;
    }
  }

  &-body {
    height: fit-content;
    padding-bottom: 16px;
    text-align: left;

    &-input {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      display: flex;

      &-box {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin: 0 10px;
        font-size: 20px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
      }

      &-box::-webkit-inner-spin-button,
      &-box::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &-error {
      color: #cf1322;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      word-wrap: break-word;
      padding-top: 8px;
    }

    &-resend {
      padding-top: 52px;

      &-text {
        color: #343a3f;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        word-wrap: break-word;
      }

      &-button {
        color: #3a86ff;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        word-wrap: break-word;
      }
    }
  }
}
</style>

<style lang="scss">
.vue-otp-2 {
  display: flex;
  gap: 6px;

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      padding: 11.5px 8px;
      font-size: 20px;
      border-radius: 3px;
      border: 0 solid #e6e9ec !important;
      text-align: center;
      background: #e6e9ec;
      max-width: 44px !important;
      height: 44px;
    }

    span {
      display: block;
      flex: 1;
      text-align: center;
    }
  }
}
</style>
