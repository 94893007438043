<template>
  <div class="navbar-wrapper">
    <template v-if="!detailBar">
      <div class="top-bar" :class="{ 'no-shadow': noShadow }">
        <img v-if="parentModal" :src="arrowLeft" alt="arrow_left_icon" @click="parentModal.close()" />
        <slot />
      </div>
    </template>
    <template v-if="detailBar">
      <div class="detail-bar">
        <img :src="cardBg" alt="card_background" class="bg-image" />
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
import ArrowLeftIcon from "@/assets/arrow-left-icon.svg";
import CardBackground from "@/assets/reporting/bg.png";

export default {
  name: "CustomTopBar",
  props: ["parentModal", "noShadow", "detailBar"],
  data() {
    return {
      arrowLeft: ArrowLeftIcon,
      cardBg: CardBackground,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: relative;
  margin: -20px -20px 16px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 3px 0px rgba(220, 220, 220, 0.5);
  -webkit-box-shadow: 0px 5px 3px 0px rgba(220, 220, 220, 0.5);
  -moz-box-shadow: 0px 5px 3px 0px rgba(220, 220, 220, 0.5);
  text-align: center;

  &.no-shadow {
    box-shadow: none;
  }

  .content {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  img,
  .icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);

    &.spin-180 {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .sticky & {
    margin: auto;
  }
}

.detail-bar {
  position: relative;

  .bg-image {
    width: 100%;
    min-height: 150px;
  }

  .icon {
    position: absolute;
    left: 16px;
    top: 16px;
  }
}
</style>
