<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Thông tin phương tiện</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <div class="form-section align-left">
        <form>
          <h6>Biển số xe</h6>
          <div class="form-group-row">
            <CustomFileUploader
              name="plate_number_img"
              :imageURL="fields.plate_number_img"
              @input="uploadChange"
            />
          </div>
          <CustomDivider fluid />
          <h6>Chứng nhận đăng ký xe</h6>
          <div class="form-group-row">
            <CustomFileUploader
              name="registration_img"
              :imageURL="fields.registration_img"
              @input="uploadChange"
            />
          </div>
          <div class="expired-input">
            <label for="">Ngày hết hạn:</label>
            <CustomDatePicker
              name="register_expired_at"
              :minDated="new Date()"
              :selectedDate="fields.register_expired_at"
              @input="dateChange"
            />
          </div>
          <CustomDivider fluid />
          <h6>Ảnh chụp phương tiện</h6>
          <div class="form-group-row">
            <CustomFileUploader
              name="front_img"
              label="Chụp chính diện sau xe"
              className="scale"
              :imageMask="bTruckImg"
              :imageURL="fields.front_img"
              @input="uploadChange"
            />
            <CustomFileUploader
              name="front_left_img"
              label="Ảnh 45 độ trái từ phía trước"
              :imageMask="lfTruckImg"
              :imageURL="fields.front_left_img"
              @input="uploadChange"
            />
            <CustomFileUploader
              name="front_right_img"
              label="Ảnh 45 độ phải từ phía trước"
              :imageMask="rfTruckImg"
              :imageURL="fields.front_right_img"
              @input="uploadChange"
            />
          </div>
        </form>
        <p class="text-footer">
          Đối tác chụp ảnh/tải ảnh phương tiện nghiêng 45 độ từ phía sau, thấy
          rõ decal, biển số và hình dạng xe
        </p>
      </div>
    </div>
    <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  RFTruckImage,
  LFTruckImage,
  BackTruckImage,
  ChevronIcon,
} from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomFileUploader from "@/common/components/CustomFileUploader";
import CustomButton from "@/common/components/CustomButton";
import CustomDivider from "@/common/components/CustomDivider";
import CustomDatePicker from "@/common/components/CustomDatePicker";
import { uploadImage } from "@/common/utils";
import { updateVehicleById } from "@/pages/Management/services/vehicle";

export default {
  name: "VehicleInfoView",
  components: {
    CustomTopBar,
    CustomFileUploader,
    CustomButton,
    CustomDivider,
    CustomDatePicker,
  },
  computed: {
    ...mapGetters({
      vehicleDetail: "managementStore/getVehicleDetail",
    }),
  },
  async mounted() {
    // const { vehicleId } = this.$route.params;
    // const { supplier_token } = this.$route.query;
    // await this.getVehicleDetail({ token: supplier_token, vehicleId });
    this.mapData();
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      bTruckImg: BackTruckImage,
      lfTruckImg: LFTruckImage,
      rfTruckImg: RFTruckImage,
      submitText: "Cập nhật",
      isLoading: false,
      isDisabled: true,
      fields: {
        plate_number_img: "",
        registration_img: "",
        register_expired_at: "",
        front_img: "",
        front_left_img: "",
        front_right_img: "",
      },
    };
  },
  methods: {
    ...mapActions({
      getVehicleDetail: "managementStore/fetchVehicleDetail",
    }),
    goBack() {
      this.$router.back();
    },
    async uploadChange(input) {
      const { name, file } = input;
      const body = {
        file,
        file_type: "meta_truck",
      };
      const url = await uploadImage(this.$route.query.supplier_token, body);

      this.fields[name] = url;
      this.isDisabled = this.isValidated();
    },
    async onConfirm() {
      const { vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      const {
        plate_number_img,
        registration_img,
        register_expired_at,
        front_img,
        front_left_img,
        front_right_img,
      } = this.fields;
      this.isLoading = true;

      const payload = {
        plate_number_urls: [plate_number_img],
        register_urls: [registration_img],
        register_expired_at: register_expired_at,
        vehicle_urls: [front_img, front_left_img, front_right_img],
      };

      try {
        await updateVehicleById(supplier_token, vehicleId, payload);
        this.goBack();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    dateChange(input) {
      const { name, value } = input;
      this.fields[name] = value.toISOString();
      this.isDisabled = this.isValidated();
    },
    mapData() {
      const { vehicleId } = this.$route.params;
      if (this.vehicleDetail[vehicleId]) {
        const vehicle = this.vehicleDetail[vehicleId];
        this.fields.plate_number_img = vehicle.plate_number_urls[0];
        this.fields.registration_img = vehicle.register_urls[0];
        this.fields.register_expired_at = vehicle.register_expired_at;
        this.fields.front_img = vehicle.vehicle_urls[0];
        this.fields.front_left_img = vehicle.vehicle_urls[1];
        this.fields.front_right_img = vehicle.vehicle_urls[2];
      }
    },
    isValidated() {
      return Object.values(this.fields).some((el) => !el);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.text-footer {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.expired-input {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  label {
    margin-right: 8px;
  }
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
