import client from "@/services/client";


export async function getUti(token, isWeb) {
  let utilities = [];
  let params = { token };
  if (isWeb) params['is_web'] = true;
  let { data } = await client.appApiUtility.get("list", { params });

  data.forEach(item => {
    if (item.url && item.url !== "")
      utilities.push(item);
  });

  return utilities;
}
