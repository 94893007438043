<template>
  <div class="uploader">
    <p v-if="!!label">{{ label }}</p>
    <div :class="['input-uploader', { active: imageSrc }]">
      <img
        :src="imageSrc || placeholder"
        alt="file_mask"
        :class="[`image-mask ${className || ''}`]"
      />
      <div v-if="loading" class="loading-icon"></div>
      <div v-if="!imageSrc" class="add-text">
        <span class="icon ic-add"> <inline-svg :src="addIcon" /></span>
        <span>Thêm ảnh</span>
      </div>
      <input type="file" :name="name" accept="image/*" @change="updateVal" />
    </div>
  </div>
</template>

<script>
import { AddIcon, DocumentImgIcon } from "@/common/icons";

export default {
  name: "CustomFileUploader",
  props: ["name", "label", "imageURL", "imageMask", "className"],
  data() {
    return {
      placeholder: this.imageMask || DocumentImgIcon,
      imageSrc: this.imageURL,
      addIcon: AddIcon,
      loading: false,
    };
  },
  methods: {
    updateVal(event) {
      const { files, name } = event.target;
      if (files && files[0]) {
        //TODO: apply blob image in local
        // const imageUrl = URL.createObjectURL(files[0]);
        // this.imageSrc = imageUrl;
        this.loading = true;
        this.$emit("input", {
          name,
          file: files[0],
        });
      }
    },
  },
  watch: {
    imageURL: function (newVal) {
      this.loading = false;
      this.imageSrc = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #3a86ff;

.input-uploader {
  position: relative;
  min-width: 150px;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  border: 1px dashed $primary-color;
  border-radius: 8px;
  background: rgba(22, 102, 221, 0.02);
  &.active {
    padding: 4px;
  }
  img {
    border-radius: 8px;
  }
  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.add-text {
  margin-top: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  span {
    font-weight: 500;
    font-size: 14px;
    color: $primary-color;
    display: block;
  }

  .ic-add {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.scale {
  height: 65px;
  margin: 0 auto;
  .active & {
    height: auto;
    margin: auto;
  }
}

p {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.loading-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    display: block;
    width: 24px;
    height: 24px;
    border-width: 0.25em;
  }
}
</style>
