<template>
  <div class="divider" :class="{ fluid }"></div>
</template>

<script>
export default {
  name: "Divider",
  props: {
    fluid: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  height: 8px;
  background-color: #eef2f5;
  &.fluid {
    margin-left: -20px;
    margin-right: -20px;
  }
}
</style>
