<template>
  <div class="modal-card add-modal">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="emitBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Thêm tài xế</p>
      </CustomTopBar>
    </div>
    <section class="modal-card-body">
      <div class="content">
        <Transition name="slide-fade">
          <div id="step-0" class="step-form">
            <StepFormCheck
              title="Nhập số điện thoại tài xế"
              description="Thông tin về số điện thoại của tài xế sẽ giúp chúng tôi xác định được tài xế này đã có tài khoản trên hệ thống hay chưa"
              label="Số điện thoại tài xế"
              type="number"
              @input="handleCheckForm"
            />
          </div>
        </Transition>
      </div>
    </section>
    <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="confirmStep"
      />
    </div>
    <MessageOverlay
      v-show="showMessage"
      className="phone-clock"
      btnLabel="Quay lại quản lý tài xế"
      :src="imageIcon"
      :title="messageTitle"
      @clicked="moveBack"
    >
      <template>
        {{ messageText }}
      </template>
    </MessageOverlay>

    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="primary"
        :title="alertTitle"
        :message="alertMessage"
        :loading="alertLoading"
        :confirmText="confirmText"
        :cancelText="cancelText"
        @confirm="handleInvite"
        @cancel="handleClose"
      />
    </b-modal>
  </div>
</template>

<script>
import parsePhoneNumber from "libphonenumber-js";
import { ChevronIcon, PhoneClockIcon } from "@/common/icons";
import { STEP_FORM_TEXT, MESSAGE_TEXT, BUTTON_TEXT } from "@/common/constants";
import AlertDialog from "@/common/components/AlertDialog";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import MessageOverlay from "@/common/components/MessageOverlay";
import StepFormCheck from "../Form/StepFormCheck";
import {
  checkPhoneNumber,
  inviteDriver,
} from "@/pages/Management/services/driver";

export default {
  name: "AddModal",
  components: {
    CustomTopBar,
    CustomButton,
    StepFormCheck,
    MessageOverlay,
    AlertDialog,
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      imageIcon: PhoneClockIcon,
      isLoading: false,
      isDisabled: true,
      showMessage: false,
      submitText: STEP_FORM_TEXT.CONTINUE,
      messageTitle: MESSAGE_TEXT.NOTICE_TITLE,
      messageText: MESSAGE_TEXT.NOTICE_TEXT,
      dialog: false,
      alertTitle: "",
      alertMessage: "",
      alertLoading: false,
      confirmText: "",
      cancelText: "",
    };
  },
  methods: {
    emitBack() {
      this.$emit("back");
    },
    inputChange(input) {
      const { name, value } = input;
      this.fields[name] = value;
      this.isDisabled = this.validateForm(this.fields);
    },
    handleCheckForm(value) {
      this.isDisabled = !value;
      if (!value || value.length < 3) {
        return;
      }

      const phoneNumber = parsePhoneNumber(value, "VN");
      const formatPhoneNumber = phoneNumber.format("INTERNATIONAL");
      this.smsPhone = formatPhoneNumber;
    },
    async handleInvite() {
      if (this.alertTitle === MESSAGE_TEXT.MESSAGE_FOUND_TITLE) {
        this.alertLoading = true;
        this.confirmText = "";
        const supplier_id = this.smsPhone.replace(/[^a-zA-Z0-9]/g, "");
        try {
          await inviteDriver(this.$route.query.supplier_token, { supplier_id });
          this.showMessage = true;
        } catch (error) {
          console.log(error);
        } finally {
          this.alertLoading = false;
          this.handleClose();
        }
      }
      this.handleClose();
    },
    handleClose() {
      this.dialog = false;
      this.alertTitle = "";
      this.alertMessage = "";
      this.confirmText = "";
      this.cancelText = "";
    },
    async confirmStep() {
      const token = this.$route.query.supplier_token;
      this.isLoading = true;
      this.submitText = "";
      try {
        const phone = this.smsPhone.replace(/[^a-zA-Z0-9]/g, "");
        const { data } = await checkPhoneNumber(token, phone);
        if (data.owner !== "") {
          // Driver in another team
          const item = {
            confirm: BUTTON_TEXT.CONFIRM,
            cancel: "",
            title: MESSAGE_TEXT.DRIVER_BUSY,
            message: `${MESSAGE_TEXT.MESSAGE_DRIVER_BUSY} của chủ xe ${data.owner_name}`,
          };
          this.showToast(item);
        } else {
          // Driver is free
          const item = {
            confirm: BUTTON_TEXT.INVITE_DRIVER,
            cancel: BUTTON_TEXT.CANCEL,
            title: MESSAGE_TEXT.MESSAGE_FOUND_TITLE,
            message: MESSAGE_TEXT.MESSAGE_FOUND,
          };
          this.showToast(item);
        }
      } catch (error) {
        const item = {
          confirm: BUTTON_TEXT.CONFIRM,
          cancel: "",
          title: MESSAGE_TEXT.MESSAGE_NOT_FOUND_TITLE,
          message: MESSAGE_TEXT.MESSAGE_NOT_FOUND,
        };
        this.showToast(item);
      } finally {
        this.isLoading = false;
      }
    },
    showToast(data) {
      this.confirmText = data.confirm;
      this.cancelText = data.cancel;
      this.alertTitle = data.title;
      this.alertMessage = data.message;
      this.dialog = true;
      this.submitText = STEP_FORM_TEXT.CONTINUE;
    },
    moveBack() {
      this.$emit("move-to-tab");
    },
    validateForm(fields) {
      return Object.values(fields).some((el) => !el);
    },
  },
};
</script>
<style lang="scss">
.phone-clock {
  .icon-image {
    max-width: 85px;
  }
}
</style>

<style lang="scss" scoped>
.content {
  text-align: left;
  position: relative;
  height: 100%;
  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #a2a9b0;
    margin-bottom: 16px;
  }
}

.message-template {
  strong {
    font-weight: 700;
  }
}

.step-form {
  position: absolute;
  width: 100%;
}

#step-0 {
  height: 100%;
}

//Animation Transition
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
