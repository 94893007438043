<template>
  <div>
    <p class="text-center">
      <b>Bạn chưa thanh toán</b>, vui lòng thanh toán bằng cách mở App Ngân hàng bất kỳ để <b>quét mã</b> hoặc<b> chuyển khoản</b> chính xác nội dung bên dưới
    </p>
    <div class="text-center">
      <b-image center :src="qrUrl" alt="qr code" class="qrcode-width"></b-image>

      <div class="columns mb-0">
        <div class="columns is-mobile is-justify-content-center is-align-items-center">
          <div class="column is-4 pr-0">
            <figure class="image is-32x32 is-rounded is-pulled-right">
              <img src="https://pub-ed31e6fa6ee24662b9097ee8aae93eea.r2.dev/rounded/mbbank.png" alt="MB Bank" />
            </figure>
          </div>
          <div class="column is-8">
            <div class="has-text-left">
              <p class="has-text-grey">Ngân hàng</p>
              <p class="has-text-weight-bold has-text-black">Ngân hàng TMCP Quân đội</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mb-0">
        <div class="columns is-mobile is-justify-content-center is-align-items-center">
          <div class="column is-3 pr-0"></div>
          <div class="column is-9">
            <div class="has-text-left">
              <p class="has-text-grey">Chủ tài khoản:</p>
              <p class="has-text-weight-bold has-text-black">{{paymentInfo.accountName}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mb-0">
        <div class="columns is-mobile is-justify-content-center is-align-items-center">
          <div class="column is-3 pr-0"></div>
          <div class="column is-9">
            <div class="has-text-left">
              <p class="has-text-grey">Số tài khoản:</p>
              <p class="has-text-weight-bold has-text-black">{{paymentInfo.accountNumber}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mb-0">
        <div class="columns is-mobile is-justify-content-center is-align-items-center">
          <div class="column is-3 pr-0"></div>
          <div class="column is-9">
            <div class="has-text-left">
              <p class="has-text-grey">Số tiền:</p>
              <p class="has-text-weight-bold has-text-black">{{new Intl.NumberFormat('en-US').format(paymentInfo.amount || 0)}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mb-0">
        <div class="columns is-mobile is-justify-content-center is-align-items-center">
          <div class="column is-3 pr-0"></div>
          <div class="column is-9">
            <div class="has-text-left">
              <p class="has-text-grey">Nội dung:</p>
              <p class="has-text-weight-bold has-text-black">{{paymentInfo.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {makePaymentRequestSync} from "@/pages/PaymentHistory/services/payment";

export default {
  name: "PaymentModal",
  props: ["needPayment", "paymentInfo"],
  data() {
    return {
      qrUrl: ''
    }
  },
  mounted() {
    if (this.paymentInfo && Object.keys(this.paymentInfo).length > 0) {
      const { accountNumber, amount, description, accountName} = this.paymentInfo
      this.qrUrl = `https://img.vietqr.io/image/MB-${accountNumber}-compact.png?amount=${amount}&addInfo=${description}&accountName=${accountName}`
    }

    setInterval(() => {
      if (this.needPayment) {
        makePaymentRequestSync(this.token || this.$route.query.supplier_token)
          .then((data) => {
            if (data?.data && data.data?.paid && data.data.paid === true) window.location.reload();
          })
          .catch((e) => console.error(e));
      }
    }, 5000)
  },
}
</script>

<style lang="scss" scoped>
.qrcode-width {
  width: 60vw;
  margin: 0 auto;
}
</style>