<template>
  <div class="driver-management page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBackMenu">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Quản lý tài xế</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <b-tabs
        expanded
        class="custom-tab"
        size="is-large"
        v-model="tabIndex"
        @input="onChange"
      >
        <b-tab-item label="Đang nhận đơn">
          <assigned-tab @move-to-tab="onChange" />
        </b-tab-item>
        <b-tab-item label="Chưa nhận đơn">
          <no-assigned-tab />
        </b-tab-item>
        <b-tab-item label="Đang xác minh">
          <process-tab />
        </b-tab-item>
      </b-tabs>
    </div>

    <span class="icon ic-float btn-float" @click="addDriver">
      <inline-svg :src="floatIcon"></inline-svg>
    </span>

    <b-modal
      class="add-modal"
      v-model="addModal"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <AddModal @back="addModal = false" @move-to-tab="onMoveTab" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { AddIcon, ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import AssignedTab from "@/pages/Management/components/DriverTab/AssignedTab";
import NoAssignedTab from "@/pages/Management/components/DriverTab/NoAssignedTab";
import ProcessingTab from "@/pages/Management/components/DriverTab/ProcessTab";
import AddModal from "@/pages/Management/components/DriverTab/AddModal";

export default {
  name: "DriverManagement",
  components: {
    CustomTopBar,
    AddModal,
    "assigned-tab": AssignedTab,
    "no-assigned-tab": NoAssignedTab,
    "process-tab": ProcessingTab,
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      floatIcon: AddIcon,
      addModal: false,
      tabIndex: 0,
    };
  },
  methods: {
    ...mapActions({
      getAssignedDrivers: "managementStore/fetchAssignedDrivers",
      getNoAssignedDrivers: "managementStore/fetchNoAssignedDrivers",
      getVerifyingDrivers: "managementStore/fetchVerifyingDrivers",
    }),
    goBackMenu() {
      this.$router.back();
    },
    onChange(index) {
      this.tabIndex = index;
      const token = this.$route.query.supplier_token;
      const owner_id = this.$route.params.id;
      if (index === 0) {
        //TODO: call action in store for reload api Assigned Tab;
        this.getAssignedDrivers({ token });
      }

      if (index === 1) {
        //TODO: call action in store for reload api Online Tab;
        this.getNoAssignedDrivers({ token });
      }

      if (index === 2) {
        //TODO: call action in store for reload api Process Tab;
        this.getVerifyingDrivers({ token, owner_id });
      }
    },
    onMoveTab() {
      const token = this.$route.query.supplier_token;
      const owner_id = this.$route.params.id;
      this.addModal = false;
      this.tabIndex = 2;
      this.getVerifyingDrivers({ token, owner_id });
    },
    addDriver() {
      this.addModal = true;
    },
  },
};
</script>
