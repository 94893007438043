<template>
  <div class="page-wrapper">
    <CustomTopBar detailBar="true">
      <span class="icon ic-white" @click="goBackMenu"> <inline-svg :src="arrowIcon" /></span>
    </CustomTopBar>
    <ProfileInfo :data="driverInfo" vertical rating />
    <CustomDivider style="padding-bottom: 8px" />
    <div class="content-box">
      <div class="menu-wrapper" v-if="driverInfo">
        <h6 class="menu-title">Liên hệ</h6>
        <ul class="menu contact--menu">
          <MenuItem :icon="phoneIcon">
            <a :href="'tel:+' + driverInfo._id" class="link">{{ `+${driverInfo._id}` }}</a>
          </MenuItem>
          <MenuItem :icon="mailIcon">
            <span>{{ driverInfo.email || "-" }}</span>
          </MenuItem>
        </ul>
      </div>
      <CustomDivider />
      <div class="menu-wrapper" v-if="driverInfo">
        <h6 class="menu-title">Thông tin khác</h6>
        <LinkSection
          main-text="Liên kết với chủ xe"
          sub-text="Doanh thu của tài xế sẽ chuyển vào tài khoản của bạn"
          :button-type="isSupplierLinked"
          :button-text="isSupplierLinked ? 'Đã liên kết' : 'Liên kết'"
          @confirm-click="handleConfirmClick"
          @unlink-click="handleUnlinkClick"
          :init-timeout="initTimeout"
        />
        <ul class="menu contact--menu">
          <MenuItem
            v-for="element in menu"
            :key="element.id"
            :icon="element.icon"
            @menu-click="onNavigate(element.path)"
          >
            <span class="menu-text">
              {{ element.name }}
              <span class="icon"><inline-svg :src="chevronIcon" /></span
            ></span>
          </MenuItem>
        </ul>
      </div>
    </div>
    <button class="btn btn-remove" type="button" @click="removeDriver">
      <inline-svg :src="removeIcon" />
      <span>Mời tài xế ra khỏi đội xe</span>
    </button>
    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
    <div v-if="isShowModal" class="modal-overlay"></div>
    <ModalConfirm
      v-if="isShowModal"
      :header-text="modalHeader"
      :body-text="modalBody"
      :confirm-text="modalButtonText"
      :confirm-type="modalConfirmType"
      :cancel-type="modalCancelType"
      @cancel-click="closeModal"
      @confirm-click="onConfirmLink"
    />
    <OTP
      class="otp-overlay"
      v-if="isOtpRequired"
      @close-otp="isOtpRequired = false"
      @otp-success="handleOtpSuccess"
      :supplier_name="driverInfo.name"
      :supplier_id="driverInfo._id"
      :token="$route.query.supplier_token"
      @show-toast="handleShowToast"
      @otp-timeout="handleOtpTimeout"
    />
  </div>
</template>

<script>
import CustomDivider from "@/common/components/CustomDivider";
import CustomToast from "@/common/components/CustomToast";
import CustomTopBar from "@/common/components/CustomTopBar";
import MenuItem from "@/common/components/MenuItem";
import {
  ArrowIcon,
  ChevronIcon,
  DefaultAvatar,
  DocumentIcon,
  ExitIcon,
  LinkIcon,
  MailIcon,
  PersonIcon,
  PhoneIcon,
  VehicleServiceIcon,
} from "@/common/icons";
import LinkSection from "@/pages/Management/components/LinkSection";
import ModalConfirm from "@/pages/Management/components/ModalConfirm";
import OTP from "@/pages/Management/components/OTP.vue";
import { addChildAccount, deleteChildAccount, removeDriverFromFleet } from "@/pages/Management/services/driver";
import ProfileInfo from "@/pages/Profile/components/MainProfile/ProfileInfo";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DriverDetail",
  components: {
    OTP,
    CustomDivider,
    CustomToast,
    CustomTopBar,
    LinkSection,
    MenuItem,
    ModalConfirm,
    ProfileInfo,
  },
  computed: {
    ...mapGetters({
      detail: "managementStore/getDriverDetail",
    }),
  },
  async mounted() {
    const { driverId, id: ownerId } = this.$route.params;
    const { supplier_token } = this.$route.query;
    try {
      await this.getDriverDetail({
        token: supplier_token,
        supplier_id: driverId,
      });

      this.driverInfo = this.mapData(this.detail[driverId]);
      if (this.driverInfo && this.driverInfo.parent_id && this.driverInfo.parent_id === ownerId) {
        this.isSupplierLinked = true;
      }
      await this.setDriverServices(this.driverInfo);
    } catch (error) {
      console.log("error", error);
      this.type = "error";
      this.message = `Không thể lấy thông tin tài xế`;
      this.isShowToast = true;

      setTimeout(() => {
        this.message = ``;
        this.isShowToast = false;
      }, 2000);
    }
  },
  data() {
    return {
      arrowIcon: ArrowIcon,
      chevronIcon: ChevronIcon,
      phoneIcon: PhoneIcon,
      mailIcon: MailIcon,
      removeIcon: ExitIcon,
      linkIcon: LinkIcon,
      menu: [
        { id: 0, name: "Hồ sơ tài xế", icon: PersonIcon, path: "info" },
        {
          id: 1,
          name: "Dịch vụ đã đăng ký",
          icon: VehicleServiceIcon,
          path: "services",
        },
        {
          id: 2,
          name: "Báo cáo hoạt động",
          icon: DocumentIcon,
          path: "report",
        },
      ],
      driverInfo: "",
      isShowToast: false,
      type: "success",
      message: "",
      position: "bottom",
      modalHeader: "",
      modalBody: "",
      modalButtonText: "",
      modalConfirmType: null,
      modalCancelType: null,
      isSupplierLinked: false,
      isShowModal: false,
      isOtpRequired: false,
      otpTimeout: null,
      initTimeout: false,
      timeoutMinute: 5,
    };
  },
  methods: {
    ...mapActions({
      getDriverDetail: "managementStore/fetchDriverDetail",
      setDriverServices: "managementStore/" + "setDriverServices",
    }),
    goBackMenu() {
      this.$router.back();
    },
    mapData(data) {
      const { files } = data;
      const imageURL =
        files?.avatar && Object.keys(files.avatar).length > 0
          ? files.avatar[Object.keys(files.avatar)[0]]
          : DefaultAvatar;

      return {
        ...data,
        driver_name: data.name,
        imageURL: imageURL,
        driver_plate_number: data.plate_number,
      };
    },
    async removeDriver() {
      if (this.isSupplierLinked) {
        this.isShowModal = true;
        this.modalHeader = "Không thể mời tài xế ra khỏi đội";
        this.modalBody =
          "Hiện tài khoản của bạn đang liên kết với tài xế này. Vui lòng hủy liên kết trước khi mời tài xế ra khỏi đội xe.";
        this.modalCancelType = "dismiss";
      } else {
        const { id, driverId: supplier_id } = this.$route.params;
        const { supplier_token } = this.$route.query;
        try {
          await removeDriverFromFleet(supplier_token, { supplier_id }, id);
          this.type = "success";
          this.message = `Tài xế đã được xoá khỏi danh sách đội`;
          this.isShowToast = true;
        } catch (error) {
          const { response } = error;
          this.type = "error";
          this.message = `Lỗi ${response.status} ! ${response.data.title}`;
          this.isShowToast = true;
        }

        setTimeout(() => {
          this.message = ``;
          this.isShowToast = false;
        }, 2000);
      }
    },
    onNavigate(path) {
      const { id, driverId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      this.$router.push({
        path: `/management/${id}/driver/${path}/${driverId}`,
        query: { supplier_token },
      });
    },
    handleConfirmClick() {
      this.isShowModal = true;
      this.modalHeader = "Liên kết Tài khoản chính";
      this.modalBody =
        "Sau khi liên kết Tài khoản chính, hệ thống sẽ gửi 1 tin nhắn có chứa mã xác thực đến Số điện thoại mà bạn đang thực hiện liên kết để Đối tác của bạn cùng xác nhận.";
      this.modalButtonText = "Liên kết";
    },
    closeModal() {
      this.isShowModal = false;
      this.modalHeader = "";
      this.modalBody = "";
      this.modalButtonText = "";
      this.modalConfirmType = null;
      this.modalCancelType = null;
    },
    async onConfirmLink() {
      if (this.isSupplierLinked) {
        try {
          const res = await deleteChildAccount(this.driverInfo._id, this.$route.query.supplier_token);
          this.closeModal();
          console.log("deleteChildAccount", res);
          this.isSupplierLinked = false;
          this.handleShowToast({ type: "success", message: "Remove child account successfully!" });
        } catch (error) {
          const { code, http_code, description } = error.response.data;
          this.type = "error";
          this.message = `${code} - ${http_code} - ${description}` || `Không thể liên kết tài xế`;
          this.isShowToast = true;

          setTimeout(() => {
            this.message = ``;
            this.isShowToast = false;
          }, 2000);
        }
      } else
        try {
          const res = await addChildAccount(this.driverInfo._id, this.$route.query.supplier_token);
          this.isOtpRequired = true;
          this.closeModal();
          console.log("addChildAccount", res);
        } catch (error) {
          const { code, http_code, description } = error.response.data;
          this.type = "error";
          this.message = `${code} - ${http_code} - ${description}` || `Không thể liên kết tài xế`;
          this.isShowToast = true;

          setTimeout(() => {
            this.message = ``;
            this.isShowToast = false;
          }, 2000);
        }
    },
    handleShowToast({ type, message }) {
      this.type = type;
      this.message = message;
      this.isShowToast = true;

      setTimeout(() => {
        this.message = "";
        this.isShowToast = false;
      }, 2000);
    },
    handleUnlinkClick() {
      this.isShowModal = true;
      this.modalHeader = "Hủy liên kết Tài khoản chính";
      this.modalBody = `Sau khi hủy liên kết, tài khoản của bạn sẽ không bị trừ phí hoặc nhận thêm doannh thu từ các giao dịch liên quan đến đơn hàng được thực hiện bởi tài xế ${this.driverInfo.name}. Bạn chắc chắn muốn hủy liên kết?`;
      this.modalButtonText = "Hủy liên kết";
      this.modalConfirmType = "danger";
    },
    handleOtpSuccess() {
      this.isOtpRequired = false;
      this.isSupplierLinked = true;
    },
    handleOtpTimeout() {
      this.initTimeout = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$scope-blue: #3a86ff;
$scope-white: #ffffff;
$scope-red: #cf1322;

.menu-wrapper {
  height: fit-content;
  padding-top: 16px;
  padding-bottom: 16px;
}

.menu-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #a2a9b0;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
}

.menu-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  color: $scope-blue;
  cursor: pointer;
}

.btn-remove {
  outline: none;
  border: none;
  padding: 24px;
  background-color: $scope-white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $scope-red;
  gap: 8px;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
}

.modal-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #1a1a1a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.otp-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
</style>
