<template>
  <div>
    <div class="subtitle">Bước 2/2: Cung cấp giấy tờ xe</div>
    <div class="notice-text">
      Đối tác vui lòng thêm vào ảnh chụp của các loại chứng từ sau đây:
    </div>
    <Divider fluid />
    <form class="vehicle-form">
      <template v-for="(field, index) in fieldNames">
        <div class="form-group" :key="field.name">
          <h6>{{ field.label }}</h6>
          <CustomFileUploader
            :name="`${field.name}_img`"
            :imageMask="field.placeholder"
            :imageURL="fields[`${field.name}_img`]"
            @input="uploadChange"
          />
          <div v-if="field.date" class="expired-input">
            <label for="">Ngày hết hạn:</label>
            <CustomDatePicker
              :name="`${field.name}_date`"
              @input="dateChange"
            />
          </div>
        </div>
        <Divider fluid :key="index" />
      </template>
      <div class="form-group">
        <h6>Giấy đăng kiểm</h6>
        <div class="form-group--row">
          <CustomFileUploader
            name="inspection_front_img"
            label="Mặt trước"
            :imageURL="fields.inspection_front_img"
            @input="uploadChange"
          />
          <CustomFileUploader
            name="inspection_back_img"
            label="Mặt sau"
            :imageMask="bcImage"
            :imageURL="fields.inspection_back_img"
            @input="uploadChange"
          />
        </div>
        <div class="expired-input">
          <label for="">Ngày hết hạn:</label>
          <CustomDatePicker name="inspection_date" @input="dateChange" />
        </div>
        <p class="text-footer">
          Đối tác vui lòng chụp ảnh/tải ảnh đăng kiểm mở 2 mặt gồm thông tin
          phương tiện, thông số kĩ thuật và thông tin đơn vị kiểm định
        </p>
      </div>
      <Divider fluid />
      <div class="form-group">
        <h6>Ảnh chụp phương tiện</h6>
        <div class="form-group--row">
          <CustomFileUploader
            name="front_img"
            label="Chụp chính diện sau xe"
            className="scale"
            :imageMask="bTruckImg"
            :imageURL="fields.front_img"
            @input="uploadChange"
          />
          <CustomFileUploader
            name="front_left_img"
            label="Ảnh 45 độ trái từ phía trước"
            :imageMask="lfTruckImg"
            :imageURL="fields.front_left_img"
            @input="uploadChange"
          />
          <CustomFileUploader
            name="front_right_img"
            label="Ảnh 45 độ phải từ phía trước"
            :imageMask="rfTruckImg"
            :imageURL="fields.front_right_img"
            @input="uploadChange"
          />
        </div>
        <p class="text-footer">
          Vui lòng đảm bảo ảnh chụp của xe thấy rõ decal, biển số xe và hình
          dạng xe
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import {
  PlateImage,
  TextCertImage,
  BackCertImage,
  RFTruckImage,
  LFTruckImage,
  BackTruckImage,
} from "@/common/icons";
import Divider from "@/common/components/CustomDivider";
import CustomFileUploader from "@/common/components/CustomFileUploader";
import CustomDatePicker from "@/common/components/CustomDatePicker";
import { uploadImage } from "@/common/utils";

export default {
  name: "SecondStepForm",
  components: {
    CustomFileUploader,
    CustomDatePicker,
    Divider,
  },
  data() {
    return {
      bcImage: BackCertImage,
      bTruckImg: BackTruckImage,
      lfTruckImg: LFTruckImage,
      rfTruckImg: RFTruckImage,
      fields: {
        plate_number_img: "",
        insurance_img: "",
        insurance_date: "",
        badge_img: "",
        badge_date: "",
        badge_cooperative_img: "",
        badge_cooperative_date: "",
        registration_img: "",
        registration_date: "",
        inspection_front_img: "",
        inspection_back_img: "",
        inspection_date: "",
        front_img: "",
        front_left_img: "",
        front_right_img: "",
      },
      fieldNames: [
        {
          label: "Biển số xe",
          name: "plate_number",
          placeholder: PlateImage,
        },
        {
          label: "Bảo hiểm xe",
          name: "insurance",
          date: true,
        },
        {
          label: "Phù hiệu xe",
          name: "badge",
          date: true,
        },
        {
          label: "Phù hiệu hợp tác xã",
          name: "badge_cooperative",
          date: true,
        },
        {
          label: "Chứng nhận đăng ký xe ô tô",
          name: "registration",
          placeholder: TextCertImage,
          date: true,
        },
      ],
    };
  },
  methods: {
    async uploadChange(input) {
      const { name, file } = input;
      const body = {
        file,
        file_type: "meta_truck",
      };
      const url = await uploadImage(this.$route.query.supplier_token, body);

      this.fields[name] = url;
      this.$emit("input", this.fields);
    },
    dateChange(input) {
      const { name, value } = input;
      this.fields[name] = value.toISOString();
      this.$emit("input", this.fields);
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #a2a9b0;
  margin-bottom: 16px;
}

.notice-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 16px 0;
  color: #121619;
}

.text-footer {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.form-group {
  margin: 16px 0;
  &--row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .uploader {
      margin-bottom: 16px;
      max-width: 48%;
    }
  }
}

.expired-input {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  label {
    margin-right: 8px;
  }
}
</style>
