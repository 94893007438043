<template>
  <div class="background-item">
    <ScoreItem label="Mức đánh giá" :value="scoreData.rating" star />
    <ScoreItem
      label="Tỷ lệ chấp nhận"
      key-name="acceptance_rate"
      :value="parseFloat(scoreData.acceptance_rate)"
      :tooltipData="getARMessage(scoreData.acceptance_rate)"
    />
    <ScoreItem
      label="Tỷ lệ hủy đơn"
      key-name="cancellation_rate"
      :value="parseFloat(scoreData.cancellation_rate)"
      :tooltipData="getCRMessage(scoreData.cancellation_rate)"
    />
  </div>
</template>

<script>
import ScoreItem from "./ScoreItem";
export default {
  name: "Board",
  components: { ScoreItem },
  props: ["scoreData"],
  methods: {
    getARMessage(score) {
      if (score < 0.9) {
        return "Cần cải thiện trên 90%";
      }

      return "Tốt";
    },
    getCRMessage(score) {
      if (score > 0.3) {
        return "Cần cải thiện dưới 30%";
      }

      return "Tốt";
    },
  },
};
</script>

<style lang="scss" scoped>
.background-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #101a3c;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}
</style>
