<template>
  <div class="table__wrapper">
    <div class="table__header">
      <div class="title">Danh sách tài xế</div>
    </div>
    <div class="table__search-box">
      <b-field>
        <b-input
          placeholder="Tìm kiếm theo tên biển số xe"
          type="search"
          icon-pack="fas"
          icon="search"
          name="search_value"
          @input="onSearch"
        >
        </b-input>
      </b-field>
    </div>
    <div class="table__display table__show-all">
      <TableComponent
        :data="data"
        :loading="loading"
        :isPaginated="true"
        :size="10"
      />
    </div>
  </div>
</template>

<script>
import { filterClient } from "@/common/utils";
import TableComponent from "@/pages/Reporting/components/Table/TableComponent";

export default {
  name: "Table",
  components: { TableComponent },
  props: ["drivers", "loading"],
  data() {
    return {
      data: [],
      loadedData: [],
    };
  },
  mounted() {
    if (this.drivers) {
      this.mapData(this.drivers.data);
    }
  },
  methods: {
    mapData(driverArray) {
      this.data = driverArray;
      this.loadedData = this.data;
    },
    onSearch(query) {
      if (query) {
        const filterArray = filterClient(query, this.loadedData, "driver");
        this.data = filterArray;
      } else {
        this.data = this.loadedData;
      }
    },
  },
  watch: {
    drivers: function (newVal) {
      this.mapData(newVal.data);
    },
  },
};
</script>

<style lang="scss">
.table {
  &__wrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }

    .btn-show-all {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #007cff;

      img {
        margin-left: 8px;
        margin-top: 4px;
      }
    }
  }
  &__search-box {
    margin-top: 12px;
  }
  &__show-all {
    margin-top: 24px;
    .pagination-next,
    .pagination-previous {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .pagination-list li {
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    .pagination-link {
      border-color: transparent;
    }
    .pagination-link.is-current {
      background-color: #ffffff;
      color: #007cff;
      border-color: #007cff;
    }
  }
}
</style>
