<template>
  <div class="bottom-modal" :class="{ active: open }" v-show="open">
    <div class="inner">
      <div class="block mb-0">
        <span>Thông tin phương tiện - {{vehicleData.id}}</span>
        <div class="is-pulled-right">
          <img :src="closeIcon" alt="close_icon" @click="onClose" />
        </div>
      </div>
      <div class="is-clearfix"></div>
    </div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Chung">
        <div class="content">
          <div class="status">
            <div class="row">
              <div class="item">
                <span v-if="vehicleData.lastUpdated"><strong>Cập nhật lần cuối:</strong> {{vehicleData.lastUpdated}}</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span><strong>Địa chỉ hiện tại:</strong> {{vehicleData.address}}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Thống kê">
        <div class="content">
          <div class="status">
            <div class="row">
              <div class="item">
                <span><strong>Tổng thời gian dừng đỗ:</strong> {{vehicleData.totalParking}}</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span><strong>Tổng thời gian lái xe trong ngày:</strong> {{vehicleData.totalDrive}}</span>
              </div>
            </div>
            <div class="row">
              <div class="item">
                <span><strong>Tổng quảng đường trong ngày:</strong> {{vehicleData.totalDistance}}</span>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CloseIcon from "@/assets/close-dark.png";

export default {
  name: "VehicleInfoModal",
  components: {},
  props: ["open", "vehicleData"],
  data() {
    return {
      closeIcon: CloseIcon,
      activeTab: 0
    }
  },
  methods: {
    onClose() {
      this.activeTab = 0
      this.$emit('close')
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.bottom-modal {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 0;
  height: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px;
  z-index: 2;
  opacity: 0;
  transition: ease-in-out 0.7s;
  &.active {
    opacity: 1;
    height: calc(30vh - 5px);
    transition: ease-in-out 0.7s;
  }

  .top-bar {
    border-bottom: 1px solid #f2f2f2;
    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .content {
    text-align: left;
    .active & {
      display: block;
    }
    h5 {
      font-weight: 700;
      color: #c1c7cd;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  span {
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    .notice & {
      margin-left: 16px;
    }
  }
}
</style>
