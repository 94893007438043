<template>
  <div class="bar-column">
    <div
      class="chart-column"
      :style="{ height: `${heightPx * 2}px`, transition: 'ease 2' }"
    >
      <div class="label-item">{{ topLabel > 0 ? topLabel : "" }}</div>
      <div class="column-item"></div>
      <div class="down-label-item">{{ downLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnItem",
  props: {
    topLabel: Number,
    downLabel: String,
    heightPx: Number,
  },
};
</script>
<style lang="scss" scoped>
.bar-column {
  position: relative;
  width: 27px;
}
.chart-column {
  position: absolute;
  left: auto;
  height: 0;
  bottom: 0;
}
.label-item {
  display: inline-block;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 22px;
  color: #1a1a1a;
}
.down-label-item {
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 22px;
  color: #bcbcbc;
  white-space: nowrap;
}
.column-item {
  height: 100%;
  width: 27px;
  background-color: #568bef;
}
</style>
