<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Chi tiết doanh thu</p>
      </CustomTopBar>
    </div>

    <div class="content-box"><ReportComponent /></div>
  </div>
</template>

<script>
import { ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import ReportComponent from "@/pages/Management/components/ReportComponent";

export default {
  name: "DriverReport",
  components: {
    CustomTopBar,
    ReportComponent,
  },

  data() {
    return {
      arrowIcon: ChevronIcon,
      driver: "",
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-menu {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: hidden;
  &--head {
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
    .icon {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--body {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }
}
</style>
