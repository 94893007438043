<template>
  <div class="chart-display-item">
    <div class="chart-header">
      <div class="title">{{ data.periodString }}</div>
      <div class="income">{{ parseCurrency(data.totalFee) }}</div>
    </div>
    <div class="chart-board">
      <div class="board-item">
        <div class="title">Tổng đơn</div>
        <div class="value">{{ data.totalOrder }}</div>
      </div>
      <div class="board-item">
        <div class="title">Thời gian trực tuyến</div>
        <div class="value">{{ data.active_time || "0" }}</div>
      </div>
    </div>
    <div class="chart-body">
      <BarColumnItem
        v-for="(item, key, index) in chartObject"
        :key="index"
        :topLabel="item.total_order"
        :downLabel="item.labelString"
        :heightPx="item.heightPx"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { parseCurrency } from "@/common/utils";
import BarColumnItem from "./BarColumnItem";

export default {
  name: "ChartDisplayItem",
  components: { BarColumnItem },
  props: ["data"],
  data() {
    return {
      chartObject: {
        MO: {
          heightPx: 0,
          labelString: "TH 2",
          total_order: 0,
        },
        TU: {
          heightPx: 0,
          labelString: "TH 3",
          total_order: 0,
        },
        WE: {
          heightPx: 0,
          labelString: "TH 4",
          total_order: 0,
        },
        TH: {
          heightPx: 0,
          labelString: "TH 5",
          total_order: 0,
        },
        FR: {
          heightPx: 0,
          labelString: "TH 6",
          total_order: 0,
        },
        SA: {
          heightPx: 0,
          labelString: "TH 7",
          total_order: 0,
        },
        SU: {
          heightPx: 0,
          labelString: "CN",
          total_order: 0,
        },
      },
    };
  },
  methods: {
    parseCurrency,
    convertKeyDate(index) {
      switch (index) {
        case 0:
          return "CN";
        case 1:
          return "TH 2";
        case 2:
          return "TH 3";
        case 3:
          return "TH 4";
        case 4:
          return "TH 5";
        case 5:
          return "TH 6";
        case 6:
          return "TH 7";
        default:
          return "";
      }
    },
    getMaxValue(chartArray) {
      if (chartArray) {
        const mxArray = _.maxBy(chartArray, (o) => o.total_order);
        const mxValue = mxArray.total_order;
        return chartArray.map((item) => {
          const curValue = item.total_order;
          return {
            ...item,
            labelString: this.convertKeyDate(item.dateIndex),
            heightPx: (curValue / mxValue) * 100,
          };
        });
      }
    },
  },
  mounted() {
    if (this.data.chartData) {
      const chartArray = this.getMaxValue(this.data.chartData);
      const chartObject = _.keyBy(chartArray, "label");
      this.chartObject = {
        ...this.chartObject,
        ...chartObject,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #4d5358;
  margin-bottom: 8px;
}
.income {
  font-size: 30px;
  line-height: 38px;
  color: #ff8200;
}
.chart-board {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .title {
    color: #7a8189;
  }
  .value {
    font-size: 24px;
    line-height: 32px;
    color: #121619;
  }
}
.board-item {
  padding: 8px 16px;
  min-width: 150px;
  border: 1px solid #bcbcbc;
  border-radius: 10px;
}
.chart-body {
  height: 250px;
  display: flex;
  justify-content: space-between;
  padding: 24px 8px;
}
.chart-display-item {
  margin-top: 16px;
}
</style>
