<template>
  <div>
    <TopBar/>
    <div class="page-title">Danh sách tài xế gán đơn <strong>{{ order_id }}</strong> </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import TopBar from "../../../../common/components/TopBar";

export default {
  name: "Header",
  components: { TopBar },
  data() {
    let buttonName = "BACK";
    return { buttonName };
  },
  computed: {
    ...mapState('supplierList', ['order_id'])
  },
  methods: {
    exitWebView() {
      this.postMessage({
        name: 'BACK',
      });
    },
  }
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
