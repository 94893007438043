import client from "@/services/client";

export const fetchAsyncDriverInfo = async (token, supplier_id) => {
  const params = {
    token,
    supplier_id,
  };
  const res = await client.appApiOnwheelUtility.get(
    `/metatruck/get_child_driver_info`,
    { params }
  );
  const photos = await client.appApiMetatruck.get(
    `/supplier/children/${supplier_id}/documents`,
    { params }
  ).catch(() => ({ data: [] }));
  return {
    ...res.data,
    photo: photos.data,
  };
};

export const fetchAsyncDriverStats = async (token, supplier_id) => {
  const params = {
    token,
    supplier_id,
  };
  const res = await client.appApiOnwheelUtility.get(
    `/metatruck/get_child_driver_stats`,
    { params }
  );
  return res.data;
};
