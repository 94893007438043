<template>
  <div id="vehicle-detail" class="detail-wrapper slot-page">
    <CustomTopBar detailBar="true">
      <span class="icon ic-white" @click="goBackMenu">
        <inline-svg :src="arrowIcon"
      /></span>
    </CustomTopBar>
    <ProfileCard v-if="info" :data="info" vertical :isVehicle="true" />
    <CustomDivider />
    <div class="slot-content">
      <div v-if="!isEdit" class="item info">
        <div class="text-service">
          <span>Số hiệu:</span> {{ info.serial || "Chưa cập nhật" }}
        </div>
        <div class="text-service">
          <span>Loại phương tiện:</span>
          {{ info.brand || "Chưa cập nhật" }}
        </div>
        <div class="text-service">
          <span>Kiểu phương tiện:</span> {{ info.model || "Chưa cập nhật" }}
        </div>
        <div class="text-service">
          <span>Màu phương tiện:</span> {{ info.color || "Chưa cập nhật" }}
        </div>
        <CustomButton
          className="primary shadow btn-update"
          label="Cập nhật thông tin xe"
          @clicked="toggleEdit"
        />
      </div>
      <div v-if="isEdit" class="item info">
        <div id="edit-vehicle-form">
          <StepFormFirst
            :isEdit="isEdit"
            :detailData="info"
            @input="onChangeForm"
          />
        </div>
        <div class="actions">
          <CustomButton
            className="primary shadow btn-update"
            :label="submitText"
            :loading="isLoading"
            :disabled="isLoading"
            @clicked="updatedVehicle"
          />
          <CustomButton
            className="error shadow btn-update"
            label="Huỷ"
            @clicked="toggleEdit"
          />
        </div>
      </div>
      <CustomDivider fluid />
      <div
        class="photo-wrapper"
        v-for="(photoElement, imgIndex) in imageData"
        :key="photoElement.key"
      >
        <div class="item display">
          <h6>{{ photoElement.name }}</h6>
          <div class="image-row">
            <div
              class="image-wrapper"
              :key="photoIndex"
              v-for="(photo, photoIndex) in photoElement[
                `${photoElement.key}_urls`
              ]"
            >
              <div
                v-if="photoElement[`${photoElement.key}_urls`].length > 1"
                class="text"
              >
                {{ getLabelText(photoIndex, photoElement.key) }}
              </div>
              <div
                class="image-mask"
                :class="{
                  active: photoElement[`${photoElement.key}_urls`].length > 0,
                  ['is-labeled']:
                    photoElement[`${photoElement.key}_urls`].length > 1,
                }"
              >
                <img :src="photo || imgPlaceholder" alt="photo" />
              </div>
            </div>
          </div>
          <template v-if="photoElement[`${photoElement.key}_expired_at`]">
            <p class="date-expired">
              Ngày hết hạn:
              {{ convertISO(photoElement[`${photoElement.key}_expired_at`]) }}
            </p>
            <div
              class="alert-container"
              v-if="isExpired(photoElement[`${photoElement.key}_expired_at`])"
            >
              <div class="alert-icon">
                <img :src="alertIcon" alt="alert_icon" />
              </div>
              <div class="alert-content">
                <div class="text-title">{{ photoElement.name }} đã hết hạn</div>
                <div class="text-subtitle">
                  Vui lòng cập nhật {{ photoElement.name.toLowerCase() }} mới
                </div>
                <CustomButton
                  className="link primary"
                  label="Cập nhật"
                  @clicked="onNavigateUpdate"
                />
              </div>
            </div>
          </template>
        </div>
        <CustomDivider fluid v-if="imgIndex !== imageData.length - 1" />
      </div>
    </div>
    <button class="btn btn-remove shadow" type="button" @click="handleRemove">
      <inline-svg :src="removeIcon" />
      <span>Mời xe ra khỏi đội</span>
    </button>
    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";
import { ArrowIcon, ExitIcon, ErrorIcon } from "@/common/icons";
import CustomButton from "@/common/components/CustomButton";
import CustomToast from "@/common/components/CustomToast";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomDivider from "@/common/components/CustomDivider";
import ProfileCard from "@/common/components/ProfileCard";
import StepFormFirst from "../../components/Form/StepFormFirst.vue";
import {
  removeVehicle,
  updateVehicleById,
} from "@/pages/Management/services/vehicle";

export default {
  name: "VehicleDetail",
  components: {
    CustomTopBar,
    ProfileCard,
    CustomDivider,
    CustomToast,
    CustomButton,
    StepFormFirst,
  },
  computed: {
    ...mapGetters({
      detail: "managementStore/getVehicleDetail",
    }),
  },
  async mounted() {
    this.onFetch();
  },
  data() {
    return {
      arrowIcon: ArrowIcon,
      alertIcon: ErrorIcon,
      removeIcon: ExitIcon,
      info: {},
      imageData: "",
      isShowToast: false,
      message: "",
      type: "success",
      position: "bottom",
      isEdit: false,
      formChangeValue: "",
      isLoading: false,
      submitText: "Xác nhận",
    };
  },
  methods: {
    ...mapActions({
      getVehicleDetail: "managementStore/fetchVehicleDetail",
    }),
    async onFetch() {
      const { vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      await this.getVehicleDetail({
        token: supplier_token,
        vehicleId,
      });
      this.info = this.mapData(this.detail[vehicleId]);
      this.mapImageData(this.detail[vehicleId]);
    },
    mapData(data) {
      return {
        ...data,
        title: data.plate_number,
      };
    },
    goBackMenu() {
      this.$router.back();
    },
    onNavigateUpdate() {
      const { id, vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      this.$router.push({
        path: `/management/${id}/documents/${vehicleId}`,
        query: { supplier_token },
      });
    },
    isExpired(ISOString) {
      const expired = new Date(ISOString);
      const current = new Date();

      return expired.getTime() < current.getTime();
    },
    convertISO(ISOString) {
      return format(new Date(ISOString), "dd/MM/yyyy");
    },
    async handleRemove() {
      const token = this.$route.query.supplier_token;
      const { vehicleId } = this.$route.params;

      try {
        await removeVehicle(token, vehicleId);

        this.type = "success";
        this.message = `Xe đã được xoá khỏi danh sách đội`;
        this.isShowToast = true;
      } catch (error) {
        const { response } = error;
        if (response) {
          this.type = "error";
          this.message = `Lỗi ${response.data.http_code} ! ${response.data.title}`;
          this.isShowToast = true;
        }
      }

      setTimeout(() => {
        this.message = ``;
        this.isShowToast = false;
      }, 2000);
    },
    getLabelText(index, key) {
      //NOTE: Display text for inspection photo
      if (key === "inspection") {
        switch (index) {
          case 0:
            return "Mặt trước";
          case 1:
            return "Mặt sau";
          default:
            break;
        }
      }

      //NOTE: Display text for vehicle photo
      if (key === "vehicle") {
        switch (index) {
          case 0:
            return "Chụp chính diện sau xe";
          case 1:
            return "Ảnh 45 độ trái từ phía trước";
          case 2:
            return "Ảnh 45 độ phải từ phía trước";
          default:
            break;
        }
      }
    },
    mapImageData(data) {
      const {
        cooperative_badge_expired_at,
        cooperative_badge_urls,
        inspection_urls,
        inspection_expired_at,
        insurance_expired_at,
        insurance_urls,
        plate_number_urls,
        register_expired_at,
        register_urls,
        vehicle_urls,
        vehicle_badge_expired_at,
        vehicle_badge_urls,
      } = data;

      const imageData = [
        {
          name: "Biển số xe",
          key: "plate_number",
          plate_number_urls,
        },
        {
          name: "Bảo hiểm xe",
          key: "insurance",
          insurance_urls,
          insurance_expired_at,
        },
        {
          name: "Phù hiệu xe",
          key: "vehicle_badge",
          vehicle_badge_urls,
          vehicle_badge_expired_at,
        },
        {
          name: "Phù hiệu hợp tác xã",
          key: "cooperative_badge",
          cooperative_badge_urls,
          cooperative_badge_expired_at,
        },
        {
          name: "Chứng nhận đăng ký xe ô tô",
          key: "register",
          register_urls,
          register_expired_at,
        },
        {
          name: "Giấy đăng kiểm",
          key: "inspection",
          inspection_urls,
          inspection_expired_at,
        },
        {
          name: "Ảnh chụp phương tiện",
          key: "vehicle",
          vehicle_urls,
        },
      ];

      this.imageData = imageData;
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    async updatedVehicle() {
      const { vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      const { brand, weight, length, width, height, manufactured_year } =
        this.formChangeValue;

      console.log(this.formChangeValue);

      const payload = {
        ...this.formChangeValue,
        manufactured_year: manufactured_year.toString(),
        weight: parseFloat(weight),
        length: parseInt(length),
        width: parseInt(width),
        height: parseInt(height),
        type_name_vi_vn: brand,
        type_name_en_us: brand,
      };
      this.isLoading = true;
      this.submitText = "";

      try {
        await updateVehicleById(supplier_token, vehicleId, payload);
        this.onFetch();
      } catch (error) {
        console.log(error);
      } finally {
        this.submitText = "Xác nhận";
        this.isLoading = false;
        this.isEdit = false;
      }
    },
    onChangeForm(value) {
      this.formChangeValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
$scope-white: #ffffff;
$scope-red: #cf1322;

.slot-page {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .slot-content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
    overflow-x: hidden;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0;
}

#edit-vehicle-form {
  text-align: left;
}

.btn-update {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
}
.btn-remove {
  outline: none;
  border: none;
  padding: 16px;
  background-color: $scope-white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $scope-red;
  gap: 8px;
  &.shadow {
    box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
  }
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
}

.item {
  padding-top: 16px;
  padding-bottom: 16px;

  .text-service {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    span {
      font-weight: bold;
      margin-right: 8px;
    }
  }

  h6 {
    font-weight: 600;
  }

  &.display {
    text-align: left;
  }

  .date-expired {
    margin-top: 16px;
  }
}

.image-row {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.image-wrapper {
  text-align: center;
  .text {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.image-mask {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
  border: 1px dashed #ff8200;
  border-radius: 8px;
  background: rgba(255, 130, 0, 0.02);
  height: 100%;
  &.active {
    padding: 8px;
  }
  &.is-labeled {
    height: calc(100% - 28px);
  }
}

.alert-container {
  display: flex;
  padding: 12px;
  background-color: #fff1f0;
  border: 0.5px solid #f4d2d2;
  border-radius: 4px;
  margin-top: 16px;
  .text-title {
    font-weight: 700;
    color: #121619;
    font-size: 14px;
    line-height: 22px;
  }

  .text-subtitle {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #4d5358;
    margin-bottom: 10px;
  }

  .alert-icon {
    margin-right: 8px;
  }
}
</style>
