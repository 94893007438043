<template>
  <div>
    <div class="modal-header">
      <div class="confirm-title">{{ tittle }}</div>
    </div>
    <div class="modal-body">
      <div class="">Gán tài xế <strong>{{ this.supplier_id }} - {{ this.supplier_name }}</strong> vào đơn hàng mã
        <strong>{{ this.order_id }}</strong>!
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn-caller">
        <img :src="require('@/assets/supplier-listing/phone-symbol.png')" style="width: 15px; margin-right: 5px"/>
        <a :href="'tel:+'+supplier_id">Gọi tài xế: {{ supplier_id }}</a>
      </button>
    </div>
    <div class="modal-footer">
      <button class="btn-confirm" v-on:click="confirmAssign">Xác nhận gán</button>
      <button class="btn-cancel" v-on:click="cancel">Hủy gán</button>
    </div>
  </div>
</template>

<script>
import { assignOrder } from "@/pages/SupplierListing/services/orderService";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalConfirmAssign",
  props: ["supplier_id", "supplier_name"],
  data() {
    return { tittle: "Xác nhận" };
  },
  computed: {
    ...mapState("supplierList", ["supplier_token", "order_id", "admin_token"])
  },
  methods: {
    ...mapActions("supplierList", ['actionSetOrderAssigned']),
    confirmAssign() {
      assignOrder(this.admin_token, this.order_id, this.supplier_id)
          .then(({status}) => {
            if (status === 200) {
              this.$emit("assign", "success");
            } else {
              this.$emit("assign", 'error');
            }
          })
          .catch(() => {
            this.$emit("assign", 'error');
          });
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  .confirm-title {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.modal-body {
}

.modal-footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.btn-confirm {
  width: 47%;
  padding: 10px;
  color: whitesmoke;
  background: #42b983;
  border: 0;
  border-radius: 5px;
}

.btn-cancel {
  width: 47%;
  padding: 10px;
  color: whitesmoke;
  background: orangered;
  border: 0;
  border-radius: 5px;
}

.btn-caller {
  width: 96%;
  padding: 10px;
  //color: #42b983;
  background: lightcyan;
  border: 0;
  border-radius: 5px;
}

.assign-error {
  color: #ff1025;
}
</style>
