<template>
  <div class="card">
    <div class="card-content">
      <div class="title">{{ title }}</div>
      <div class="content">
        {{ message }}
      </div>
      <div class="actions">
        <CustomButton
          v-if="cancelText && cancelText !== ''"
          :label="cancelText"
          @clicked="$emit('cancel')"
        />
        <CustomButton
          :className="btnClass || ''"
          :label="confirmText"
          :loading="loading || false"
          :disabled="loading || false"
          @clicked="$emit('confirm')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/common/components/CustomButton";

export default {
  name: "AlertDialog",
  components: { CustomButton },
  props: [
    "btnClass",
    "confirmText",
    "cancelText",
    "loading",
    "message",
    "title",
  ],
};
</script>

<style lang="scss">
.alert-dialog {
  padding-left: 16px;
  padding-right: 16px;
  .modal-close {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.title {
  // font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}

.content {
  text-align: left;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  gap: 2%;
  .btn {
    width: 100%;
  }
}
</style>
