import client from "@/services/client";

export default {
    garbageItemInterface(item, reverse = false) {
        if (reverse) {
            return {
                "bin_id": item['bin_id'],
                "garbage_id": item['id'],
                "garbage_name": item['name'],
                "unit_price": item['price_unit'],
                "unit_type": item['unit_type'],
                "unit_value": parseFloat(item['weight']),
                "price": item['weight'] * item['price_unit'],
                "order_proof": item["order_proof"],
            }
        }
        return {
            bin_id: item['bin_id'],
            id: item['garbage_id'],
            img: item['garbage_id'],
            price_unit: item['unit_price'],
            weight: 0,
            name: item['garbage_name'],
            unit_type: item['unit_type'],
            order_proof: item['order_proof'],
        }
    },
    async getItemList(params) {
        let {data} = await client.appApiKamegomi('order-detail', {
            params
        })
        return data['bins'].map(item => this.garbageItemInterface(item))
    },
    async submitGarbage({orderId, data}) {
        data['bins'] = data['bins'].map(item => this.garbageItemInterface(item, true))
        return await client.appApiKamegomi.post('order-update', {
            orderId,
            data: {
                ...data
            }
        })
    },
    async uploadPhoto($file, token) {
        if (!token || token !== "")
            token = `${process.env["NODE_ENV"] === "production" ? "5d5a1a898a5da502e5951709" : "5d5a1a7ceb44d73940a34228"}`;
        const formData = new FormData();
        formData.append('file', $file);
        formData.append('file_type', 'order_proof');
        const res = client.appApiAhaMovePrivate({
            method: 'post',
            url: `/assets/order-images/upload`,
            data: formData,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        });
        return (await res);
    }
}
