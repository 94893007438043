<template>
  <li :class="['menu-item', { 'bottom-line': line }]" @click="$emit('menu-click')">
    <img v-if="icon" class="menu-icon" :src="icon" alt="menu_icon" />
    <slot />
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    icon: String,
    link: Boolean,
    line: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$scope-grey: #eef2f5;
.menu-item {
  display: flex;
  align-items: center;
  padding: 16px;

  &.bottom-line {
    border-bottom: 2px solid $scope-grey;
  }

  &:last-child {
    border-bottom: none;
  }

  img {
    margin-right: 16px;
    max-width: 20px;
    max-height: 20px;
  }
}
</style>
