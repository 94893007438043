<template>
  <div class="service-card">
    <img :src="expressIcon" alt="icon_avatar" class="icon-avatar" />
    <template v-if="checkbox">
      <b-checkbox
        class="custom-checkbox float-checkbox"
        v-model="detail.checked"
        @input="onChange"
      >
      </b-checkbox>
    </template>
    <div class="card-inner">
      <div class="title">{{ convertDriverService(detail) }}</div>
      <p class="desc">{{ "-" }}</p>
    </div>
  </div>
</template>

<script>
import { ExpressServiceImg } from "@/common/icons";
import { convertDriverService } from "@/common/utils";
export default {
  name: "ServiceListItem",
  props: ["detail", "checkbox"],
  data() {
    return {
      expressIcon: ExpressServiceImg,
    };
  },
  methods: {
    convertDriverService,
    onChange() {
      this.$emit("input", { ...this.detail });
    },
  },
};
</script>

<style lang="scss">
$color-main: #1890ff;

.float-checkbox {
  input[type="checkbox"],
  .check {
    position: absolute;
    left: auto;
    right: 24px;
  }
}

.custom-checkbox {
  cursor: auto;
  &.b-checkbox.checkbox {
    input,
    .check {
      cursor: pointer;
    }
    &:hover {
      input[type="checkbox"]:not(:disabled) + .check {
        border-color: $color-main;
        box-shadow: none;
      }
    }
    input[type="checkbox"]:checked + .check {
      border-color: $color-main;
      background-color: $color-main;
      box-shadow: none;
    }
    input[type="checkbox"]:indeterminate + .check {
      background: none;
      position: relative;
      box-shadow: none;
      border-color: $color-main;
      &::before {
        content: "";
        background-color: $color-main;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 7px);
        height: calc(100% - 7px);
        transition: ease-out 0.2s;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.service-card {
  text-align: left;
  position: relative;
  padding: 16px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.float-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.icon-avatar {
  max-width: 40px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  color: #121619;
  line-height: 24px;
  margin-bottom: 0;
}

.desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #343a3f;
}
</style>
