import client from "./client";

export async function getProfile(token) {
  const { data } = await client.appApiAhamove("supplier/profile", {
    params: {
      token,
    },
  });
  return data;
}
