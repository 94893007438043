<template>
  <div class="wrapper">
    <div class="wrapper-inner" @click="$emit('open-detail')">
      <img :src="data.avatar || placeholder" alt="avatar" class="avatar" />
      <div class="info">
        <div class="name">{{ data.name || "-" }}</div>
        <div v-if="data.vehicle_id" class="plate">
          {{ convertPlateNumber(data.vehicle_id, data.fleet_vehicle_type) }}
          {{ data.fleet_vehicle_type && `| ${data.fleet_vehicle_type}` }}
        </div>
        <div class="phone">{{ `+${data._id}` || "-" }}</div>
        <template v-if="data.invitation_status">
          <div class="badge" :class="onGetStatusClass(data.invitation_status)">
            {{ getStatusText(data.invitation_status) }}
          </div>
        </template>
      </div>
    </div>
    <template v-if="showLinkAction">
      <div class="actions" @click="$emit('action-connect', data)">
        <inline-svg :src="data.vehicle_id ? unlinkIcon : linkIcon" />
      </div>
    </template>
    <template v-if="showDeleteAction">
      <div class="actions" @click="$emit('action-delete', data)">
        <inline-svg :src="removeIcon" />
      </div>
    </template>
  </div>
</template>

<script>
import ProfileImage from "@/assets/reporting/profile.png";
import { RemoveUserIcon, LinkTruckIcon, UnlinkTruckIcon } from "@/common/icons";
import { convertPlateNumber } from "@/common/utils";

export default {
  name: "DriverItem",
  props: ["data", "showLinkAction", "showDeleteAction"],
  data() {
    return {
      removeIcon: RemoveUserIcon,
      placeholder: ProfileImage,
      linkIcon: LinkTruckIcon,
      unlinkIcon: UnlinkTruckIcon,
    };
  },
  methods: {
    convertPlateNumber,
    onGetStatusClass(status) {
      switch (status) {
        case 1:
          return "warning";
        case 3:
          return "alert";
        default:
          break;
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return "Chờ phản hồi";
        case 3:
          return "Bị từ chối";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$scope-grey: #a2a9b0;

.wrapper {
  position: relative;
  &-inner {
    text-align: left;
    margin-bottom: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
}

.info {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  .name {
    font-size: 16px;
  }
  .plate {
    font-weight: 700;
  }
  .phone {
    color: $scope-grey;
  }
}
.actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}

.badge {
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-block;
  &.warning {
    background-color: #feffe6;
    color: #ffa940;
  }
  &.alert {
    color: #f5222d;
    background-color: #fff1f0;
  }
  &.secondary {
    background-color: #f2f4f8;
    color: $scope-grey;
  }
}
</style>
