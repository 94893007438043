<template>
  <div id="vehicle" class="management-wrapper">
    <CustomTopBar detailBar="true">
      <span class="icon ic-white" @click="goBackMenu"> <inline-svg :src="arrowIcon" /></span>
    </CustomTopBar>
    <div class="wrapper">
      <ProfileInfo :data="driverInfo" vertical />
      <Divider />
      <ul v-if="driverInfo" class="menu management--menu">
        <MenuItem line :icon="truckIcon" @menu-click="openItem()">
          <span>Quản lý xe</span>
        </MenuItem>
      </ul>
    </div>
    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import { ArrowIcon, PersonIcon, TruckIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import Divider from "@/common/components/CustomDivider";
import MenuItem from "@/common/components/MenuItem";
import CustomToast from "@/common/components/CustomToast";
import ProfileInfo from "@/pages/Profile/components/MainProfile/ProfileInfo";
import { getOwnerProfile } from "@/pages/Management/services/driver";
import router from "@/router";

export default {
  name: "VehicleManagement",
  components: {
    ProfileInfo,
    Divider,
    MenuItem,
    CustomToast,
    CustomTopBar,
  },
  data() {
    return {
      arrowIcon: ArrowIcon,
      truckIcon: TruckIcon,
      driverIcon: PersonIcon,
      currentId: "",
      driverInfo: "",
      isShowToast: false,
      type: "success",
      message: "",
      position: "bottom",
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    this.supplierToken = this.$route.query.supplier_token;
    this.currentId = id;

    try {
      const data = await getOwnerProfile(this.supplierToken);
      this.driverInfo = {
        ...data,
        imageURL: data.supplier.avatar.origin_url || "",
        driver_name: data.supplier.name,
        driver_plate_number: data.supplier.plate_number,
      };
    } catch (error) {
      this.type = "error";
      this.message = `Đã xảy ra lỗi, xin vui lòng thử lại!\n${error.response.status}: ${error.response.data.message}`;
      this.isShowToast = true;
    }
  },
  methods: {
    goBackMenu() {
      // TODO Back to Utilities
      router.go(-1);
      // this.postMessage({ name: this.EVENT_BACK });
    },
    openItem() {
      this.$router.push({
        path: `/management/${this.currentId}/vehicle/list`,
        query: { supplier_token: this.supplierToken },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 32px;
}
</style>
