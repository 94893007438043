<template>
  <div class="display-item">
    <div v-if="label" class="label">
      {{ label }} <span>{{ content || "" }}</span>
    </div>

    <template v-if="imageURL && !checkImageURL(imageURL)">
      <img class="img-item" :src="imageURL" alt="photo_display" @click="zoomImage(imageURL)" />
    </template>

    <template v-if="imageURL && checkImageURL(imageURL)">
      <img
        v-for="(item, index) in imageURL"
        :key="index"
        :src="item"
        class="img-item"
        @click="zoomImage(item)"
        alt="photo_display"
      />
    </template>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "DisplayItem",
  props: {
    label: String,
    content: String,
    imageURL: [Array, String],
  },
  methods: {
    checkImageURL(imageURL) {
      return _.isArray(imageURL);
    },
    zoomImage(imageURL) {
      const img = this.$createElement("img", {
        attrs: {
          src: imageURL,
          class: "img-item-zoom",
        },
      });

      this.$buefy.modal.open({
        content: [ img ],
        customClass: "img-item-zoom-holder",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  line-height: 22px;
  color: #121619;
  span {
    font-weight: normal;
  }
}
.display-item {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 2px solid #eef2f5;
  &:first-child {
    .profile-display__container & {
      border: none;
    }
  }
}
.img-item {
  max-width: 104px;
  margin-right: 16px;
  border-radius: 8px;
}

.img-item-zoom {
  height: 100%;
}
</style>
