<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Đăng kiểm - Bảo hiểm</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <div class="form-section align-left">
        <h6>Bảo hiểm xe</h6>
        <div class="form-group-row">
          <CustomFileUploader
            name="insurance_img"
            :imageURL="fields.insurance_img"
            @input="uploadChange"
          />
        </div>
        <div class="expired-input">
          <label for="">Ngày hết hạn:</label>
          <CustomDatePicker
            name="insurance_expired_at"
            :minDated="new Date()"
            :selectedDate="fields.insurance_expired_at"
            @input="dateChange"
          />
        </div>
        <CustomDivider fluid />
        <h6>Ảnh giấy đăng kiểm</h6>
        <div class="form-group-row">
          <CustomFileUploader
            name="inspection_front_img"
            label="Mặt trước"
            :imageURL="fields.inspection_front_img"
            @input="uploadChange"
          />
          <CustomFileUploader
            name="inspection_back_img"
            label="Mặt sau"
            :imageMask="bcImage"
            :imageURL="fields.inspection_back_img"
            @input="uploadChange"
          />
        </div>
        <div class="expired-input">
          <label for="">Ngày hết hạn:</label>
          <CustomDatePicker
            name="inspection_expired_at"
            :minDated="new Date()"
            :selectedDate="fields.inspection_expired_at"
            @input="dateChange"
          />
        </div>
      </div>
    </div>
    <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BackCertImage, ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import CustomFileUploader from "@/common/components/CustomFileUploader";
import CustomDatePicker from "@/common/components/CustomDatePicker";
import CustomDivider from "@/common/components/CustomDivider";
import { uploadImage } from "@/common/utils";
import { updateVehicleById } from "@/pages/Management/services/vehicle";

export default {
  name: "InspectionView",
  components: {
    CustomTopBar,
    CustomButton,
    CustomFileUploader,
    CustomDatePicker,
    CustomDivider,
  },
  computed: {
    ...mapGetters({
      vehicleDetail: "managementStore/getVehicleDetail",
    }),
  },
  async mounted() {
    // const { vehicleId } = this.$route.params;
    // const { supplier_token } = this.$route.query;
    // await this.getVehicleDetail({ token: supplier_token, vehicleId });
    this.mapData();
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      bcImage: BackCertImage,
      submitText: "Cập nhật",
      isLoading: false,
      isDisabled: true,
      fields: {
        insurance_img: "",
        insurance_expired_at: "",
        inspection_front_img: "",
        inspection_back_img: "",
        inspection_expired_at: "",
      },
    };
  },
  methods: {
    ...mapActions({
      getVehicleDetail: "managementStore/fetchVehicleDetail",
    }),
    goBack() {
      this.$router.back();
    },
    async uploadChange(input) {
      const { name, file } = input;
      const body = {
        file,
        file_type: "meta_truck",
      };
      const url = await uploadImage(this.$route.query.supplier_token, body);

      this.fields[name] = url;
      this.isDisabled = this.isValidated();
    },
    async onConfirm() {
      const { vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      const {
        insurance_img,
        insurance_expired_at,
        inspection_front_img,
        inspection_back_img,
        inspection_expired_at,
      } = this.fields;
      this.isLoading = true;

      const payload = {
        insurance_urls: [insurance_img],
        insurance_expired_at,
        inspection_urls: [inspection_front_img, inspection_back_img],
        inspection_expired_at,
      };

      try {
        await updateVehicleById(supplier_token, vehicleId, payload);
        this.goBack();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    dateChange(input) {
      const { name, value } = input;
      this.fields[name] = value.toISOString();
      this.isDisabled = this.isValidated();
    },
    mapData() {
      const { vehicleId } = this.$route.params;
      if (this.vehicleDetail[vehicleId]) {
        const vehicle = this.vehicleDetail[vehicleId];
        this.fields.insurance_img = vehicle.insurance_urls[0];
        this.fields.insurance_expired_at = vehicle.insurance_expired_at;
        this.fields.inspection_front_img = vehicle.inspection_urls[0];
        this.fields.inspection_back_img = vehicle.inspection_urls[1];
        this.fields.inspection_expired_at = vehicle.inspection_expired_at;
      }
    },
    isValidated() {
      return Object.values(this.fields).some((el) => !el);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group-row {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.expired-input {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  label {
    margin-right: 8px;
  }
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -16px !important;
  margin-right: -16px !important;
}
</style>
