<template>
  <div class="modal-card add-modal">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="emitBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Thêm xe</p>
      </CustomTopBar>
    </div>
    <section class="modal-card-body">
      <div class="content">
        <Transition name="slide-fade">
          <div v-show="formStep === 0" id="step-0" class="step-form">
            <StepFormCheck
              title="Nhập biển số xe"
              description="Thông tin về biển số xe sẽ giúp chúng tôi xác định được xe này đã có trên
      hệ thống hay chưa"
              label="Biển số xe"
              @input="handleCheckForm"
            />
          </div>
        </Transition>
        <Transition name="slide-fade">
          <div v-show="formStep === 1" id="step-1" class="step-form">
            <FirstStepForm @input="handleFirstForm" />
          </div>
        </Transition>

        <Transition name="slide-fade">
          <div v-show="formStep === 2" id="step-2" class="step-form">
            <SecondStepForm @input="handleSecondForm" />
          </div>
        </Transition>
      </div>
    </section>
    <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="confirmStep"
      />
    </div>

    <!-- Full Message -->
    <MessageOverlay
      v-show="showMessage"
      :src="successIcon"
      className="center success-screen"
      title="Gửi hồ sơ thành công"
      btnLabel="Trang chủ"
      @clicked="moveBack"
    >
      Cám ơn bạn đã gửi hồ sơ cho chúng tôi. Bạn sẽ được thông báo khi hồ sơ đã
      được xử lý.
    </MessageOverlay>

    <!-- Alert Message -->
    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="primary"
        title="Xe đã có trong đội"
        :message="alertMessage"
        confirmText="Đã hiểu"
        @confirm="dialog = false"
      />
    </b-modal>

    <!-- Toast Message -->
    <CustomToast
      v-show="isShowToast"
      type="error"
      position="bottom"
      :message="message"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { ChevronIcon, SuccessIcon } from "@/common/icons";
import { STEP_FORM_TEXT } from "@/common/constants";
import AlertDialog from "@/common/components/AlertDialog";
import CustomToast from "@/common/components/CustomToast";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import MessageOverlay from "@/common/components/MessageOverlay";
import FirstStepForm from "@/pages/Management/components/Form/StepFormFirst";
import SecondStepForm from "@/pages/Management/components/Form/StepFormSecond";
import StepFormCheck from "@/pages/Management/components/Form/StepFormCheck";
import {
  createVehicle,
  checkVehicle,
} from "@/pages/Management/services/vehicle";

export default {
  name: "AddTruckModal",
  components: {
    CustomTopBar,
    CustomButton,
    StepFormCheck,
    FirstStepForm,
    SecondStepForm,
    MessageOverlay,
    AlertDialog,
    CustomToast,
  },
  data() {
    return {
      successIcon: SuccessIcon,
      arrowIcon: ChevronIcon,
      submitText: STEP_FORM_TEXT.CONTINUE,
      plate_number: "",
      firstStepFields: "",
      secondStepFields: "",
      formStep: 0,
      isLoading: false,
      isDisabled: true,
      isStep1Continue: false,
      showMessage: false,
      dialog: false,
      isShowToast: false,
      message: "",
      alertMessage: "",
    };
  },
  methods: {
    emitBack() {
      if (this.formStep === 0) {
        this.$emit("back");
        return;
      }

      this.submitText = STEP_FORM_TEXT.CONTINUE;
      this.isDisabled = false;
      this.formStep -= 1;
    },
    handleCheckForm(value) {
      this.plate_number = value;
      this.isDisabled = !value;
    },
    handleFirstForm(fields) {
      this.firstStepFields = fields;
      this.isDisabled = this.validateForm(this.firstStepFields);
    },
    handleSecondForm(fields) {
      this.secondStepFields = fields;
    },
    confirmStep() {
      if (this.formStep === 0) {
        //TODO: Checking Plate number
        this.stepCheck();
        return;
      }

      // TODO: Proceed update information
      if (this.formStep === 1) {
        this.submitText = STEP_FORM_TEXT.SEND_DOCUMENT;
        this.isStep1Continue = true;
        this.formStep = 2;
        return;
      }

      // TODO: Add vehicle
      this.submitVehicle();
    },
    moveBack() {
      this.$emit("confirm");
    },
    validateForm(fields) {
      const needValidate = _.pick(fields, ["type", "brand", "model", "color"]);
      return Object.values(needValidate).some((el) => !el);
    },
    loadingOn() {
      this.submitText = "";
      this.isLoading = true;
    },
    async stepCheck() {
      this.loadingOn();
      const plateNumberString = this.plate_number.replace(/[^A-Za-z0-9]/g, "");
      try {
        const { data: result } = await checkVehicle(
          this.$route.query.supplier_token,
          plateNumberString
        );
        const { id } = this.$route.params;
        this.dialog = true;
        this.isLoading = false;
        if (result.owner === id) {
          this.alertMessage =
            "Xe đang thuộc một đội xe của bạn. Vui lòng kiểm tra lại.";
        } else {
          this.alertMessage =
            "Xe đang thuộc một đội xe khác. Vui lòng kiểm tra lại.";
        }
      } catch (error) {
        const { data, status } = error.response;

        if (data.code === "NOT_EXIST") {
          this.formStep = 1;
          if (this.isStep1Continue) {
            this.isDisabled = this.validateForm(this.firstStepFields);
          } else {
            this.isDisabled = true;
          }
        } else {
          this.type = "error";
          this.message = `Đã xảy ra lỗi, xin vui lòng thử lại!\n${status}: ${data.title}`;
          this.isShowToast = true;
        }
      } finally {
        this.isLoading = false;
        this.submitText = STEP_FORM_TEXT.CONTINUE;
      }
    },
    async submitVehicle() {
      if (this.formStep === 2) {
        this.loadingOn();

        const { brand, weight, length, width, height, manufactured_year } =
          this.firstStepFields;
        const _id = this.plate_number.replace(/[^A-Za-z0-9]/g, "");

        const {
          plate_number_img,
          insurance_img,
          insurance_date,
          badge_img,
          badge_date,
          badge_cooperative_img,
          badge_cooperative_date,
          registration_img,
          registration_date,
          inspection_front_img,
          inspection_back_img,
          inspection_date,
          front_img,
          front_left_img,
          front_right_img,
        } = this.secondStepFields;

        const formValue = {
          _id,
          ...this.firstStepFields,
          manufactured_year: manufactured_year.toString(),
          weight: parseFloat(weight),
          length: parseInt(length),
          width: parseInt(width),
          height: parseInt(height),
          type_name_vi_vn: brand,
          type_name_en_us: brand,
          plate_number: this.plate_number,
          plate_number_urls: [plate_number_img],
          vehicle_urls: [front_img, front_left_img, front_right_img],
          insurance_expired_at: insurance_date,
          insurance_urls: [insurance_img],
          vehicle_badge_expired_at: badge_date,
          vehicle_badge_urls: [badge_img],
          cooperative_badge_expired_at: badge_cooperative_date,
          cooperative_badge_urls: [badge_cooperative_img],
          register_expired_at: registration_date,
          register_urls: [registration_img],
          inspection_expired_at: inspection_date,
          inspection_urls: [inspection_front_img, inspection_back_img],
        };

        try {
          await createVehicle(this.$route.query.supplier_token, formValue);
          this.showMessage = true;
        } catch (error) {
          this.submitText = STEP_FORM_TEXT.SEND_DOCUMENT;
          this.message = `Đã xảy ra lỗi, xin vui lòng thử lại!\n${error.response.status}: ${error.response.data.message}`;
          this.isShowToast = true;
        } finally {
          this.isLoading = false;
        }
      }
      return;
    },
  },
};
</script>

<style lang="scss">
.success-screen {
  .icon-image {
    max-width: 48px;
  }
}
</style>

<style lang="scss" scoped>
.content {
  text-align: left;
  position: relative;
  height: 100%;
}
.step-form {
  position: absolute;
  width: 100%;
}

#step-0 {
  height: 100%;
}

//Animation Transition
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
