<template>
  <div class="score-item">
    <div class="label">
      <span>{{ label }}</span>
      <b-tooltip
        v-if="!star"
        :label="tooltipData"
        position="is-left"
        size="is-small"
        type="is-light"
        multilined
      >
        <!-- <AlertIcon /> -->
        <inline-svg :src="alertIcon" />
      </b-tooltip>
    </div>
    <div v-if="star" class="value">
      {{ value || 0 }}
      <img :src="starIcon" alt="star_icon" />
    </div>
    <div v-if="!star" class="value" :class="onGetStatusClass(keyName, value)">
      {{ value * 100 || 0 }}%
    </div>
  </div>
</template>
<script>
import { StarIcon, AlertIcon } from "@/common/icons";

export default {
  name: "ScoreComponent",
  props: {
    label: String,
    value: Number,
    star: Boolean,
    tooltipData: String,
    keyName: String,
  },
  data() {
    return {
      starIcon: StarIcon,
      alertIcon: AlertIcon
    };
  },
  methods: {
    onGetStatusClass(name, value) {
      if (
        (name === "acceptance_rate" && value < 0.9) ||
        (name === "cancellation_rate" && value > 0.3)
      ) {
        return "warning";
      }

      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.score-item {
  .label {
    font-size: 12px;
    line-height: 20px;
    color: #f2f2f2;
    display: flex;
    align-items: center;
  }
  .value {
    color: #f2f2f2;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.warning {
      color: #ff4747;
    }
    img {
      margin-left: 4px;
    }
  }
}
</style>
