<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Bổ sung giấy tờ</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <div class="bg-updater">
        <div class="heading">
          <div class="title">Bổ sung chứng từ xác thực</div>
          <img :src="documentLogo" alt="document_logo" />
        </div>
        <div class="text">
          Chọn dịch vụ và dịch vụ kèm theo mà bạn muốn nhận đơn hàng. Bạn cần
          phải chọn ít nhất 1 dịch vụ từ danh sách đã đăng ký.
        </div>
        <!-- <a class="btn-more" href="">
          <p>Tìm hiểu thêm về giấy tờ xác thực</p>
          <span class="icon spin-180 ic-orange">
            <inline-svg :src="arrowIcon"
          /></span>
        </a> -->
      </div>
      <Divider />
      <ul class="menu-box">
        <MenuItem
          line
          v-for="element in menu"
          :key="element.id"
          @menu-click="onNavigate(element.path)"
        >
          <span class="menu-row">
            {{ element.name }}
            <span class="icon ic-black"> <inline-svg :src="arrowIcon" /></span>
            <span v-if="onCheckExpired(element.fields_check)" class="status"
              >Cần bổ sung</span
            >
          </span>
        </MenuItem>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { ChevronIcon, DocumentLogo } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import MenuItem from "@/common/components/MenuItem";
import Divider from "@/common/components/CustomDivider";

export default {
  name: "DocumentUpdate",
  components: { CustomTopBar, Divider, MenuItem },
  computed: {
    ...mapGetters({
      vehicleDetail: "managementStore/getVehicleDetail",
    }),
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      documentLogo: DocumentLogo,
      menu: [
        {
          id: 0,
          name: "Thông tin phương tiện",
          path: "info",
          fields_check: ["register_expired_at"],
        },
        {
          id: 1,
          name: "Giấy đăng kiểm, bảo hiểm",
          path: "inspection",
          fields_check: ["insurance_expired_at", "inspection_expired_at"],
        },
        {
          id: 2,
          name: "Phù hiệu phương tiện",
          path: "badge",
          fields_check: [
            "cooperative_badge_expired_at",
            "vehicle_badge_expired_at",
          ],
        },
      ],
    };
  },
  mounted() {
    const { vehicleId } = this.$route.params;
    const { supplier_token } = this.$route.query;
    this.getVehicleDetail({ token: supplier_token, vehicleId });
  },
  methods: {
    ...mapActions({
      getVehicleDetail: "managementStore/fetchVehicleDetail",
    }),
    goBack() {
      this.$router.back();
    },
    onNavigate(path) {
      const { id, vehicleId } = this.$route.params;
      const { supplier_token } = this.$route.query;
      this.$router.push({
        path: `/management/${id}/documents/${vehicleId}/${path}`,
        query: { supplier_token },
      });
    },
    onCheckExpired(keys) {
      const { vehicleId } = this.$route.params;
      if (this.vehicleDetail[vehicleId]) {
        const pickVal = _.pick(this.vehicleDetail[vehicleId], keys);

        const current = new Date();
        let isExpired = false;

        for (const item in pickVal) {
          const expired = new Date(pickVal[item]);
          if (expired.getTime() < current.getTime()) {
            isExpired = true;
            break;
          }
        }

        return isExpired;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box {
  .menu-item {
    font-weight: 600;
  }

  .icon {
    font-weight: normal;
  }
}
.bg-updater {
  background: linear-gradient(184.26deg, #d6e7ff 3.47%, #ffffff 96.52%);
  padding-bottom: 16px;
  padding-top: 16px;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  margin-bottom: 16px;
  .title {
    font-size: 16px;
    margin-bottom: 0;
    letter-spacing: -0.32px;
  }
}

.text {
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #7a8189;
}

.btn-more {
  margin-left: 16px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #ff8200;
}

.status {
  font-weight: normal;
  display: block;
  background: #ffe5d5;
  border-radius: 50px;
  padding: 6px;
  font-size: 12px;
  color: #e8313b;
  padding: 4px 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
}
</style>
