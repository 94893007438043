<template>
  <div class="modal-card">
    <div class="header">
      <CustomTopBar>
        <span
          v-if="formStep !== 2"
          class="icon ic-close ic-white"
          @click="emitBack"
        >
          <inline-svg :src="closeIcon"
        /></span>
        <p>{{ topBarLabel }}</p>
      </CustomTopBar>
    </div>
    <section class="modal-card-body" :class="{ greyscale: formStep === 1 }">
      <div class="content">
        <Transition name="slide-fade">
          <div v-show="formStep === 0" id="step-0" class="step-form">
            <AccessForm @login-gps-house="handleLoginGPSHouse" />
          </div>
        </Transition>

        <Transition name="slide-fade">
          <div v-show="formStep === 1" id="step-1" class="step-form">
            <SelectedForm
              v-if="vendor"
              :vendor="vendor"
              @selected="onSelected"
            />
          </div>
        </Transition>

        <Transition name="slide-fade">
          <div v-show="formStep === 2" id="step-2" class="step-form">
            <p>Xe thêm vào: {{ getAddedNumber() }} xe</p>
            <p>Xe bị trùng: {{ getDupNumber() }} xe</p>
            <VehicleItem
              v-for="(vehicle, index) in selectedVehicles"
              :key="index"
              :notice="true"
              :detail="vehicle"
            />
          </div>
        </Transition>
      </div>
    </section>
    <div v-if="formStep !== 0" :class="['actions', { shadow: formStep === 2 }]">
      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="isLoading || isDisabled"
        @clicked="confirmStep"
      />
    </div>

    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="primary"
        :title="alertTitle"
        :message="alertMessage"
        :confirmText="confirmText"
        :cancelText="cancelText"
        :loading="alertLoading"
        @confirm="cloneVehicle"
        @cancel="dialog = false"
      />
    </b-modal>
  </div>
</template>

<script>
import { CloseIcon, SuccessIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import AlertDialog from "@/common/components/AlertDialog";
import { getFilterQuantity } from "@/common/utils";
import AccessForm from "./AccessForm";
import SelectedForm from "./SelectForm";
import VehicleItem from "@/pages/Management/components/VehicleItem";
import { createMultiVehicles } from "@/pages/Management/services/vehicle";

export default {
  name: "GPSHouseModal",
  components: {
    CustomTopBar,
    CustomButton,
    AccessForm,
    SelectedForm,
    VehicleItem,
    AlertDialog,
  },
  data() {
    return {
      successIcon: SuccessIcon,
      closeIcon: CloseIcon,
      isLoading: false,
      isDisabled: true,
      submitText: "Thêm",
      formStep: 0,
      topBarLabel: "Đăng nhập hệ thống GPS",
      selectedVehicles: [],
      vendor: "",
      dialog: false,
      alertLoading: false,
      alertTitle: "Thay thế thông tin",
      alertMessage:
        "Thông tin trên hệ thống của các xe mà chủ xe đã chọn sẽ được thay thế bằng thông tin trên Adsun. Xác nhận thay thế?",
      confirmText: "Thay thế",
      cancelText: "Huỷ",
    };
  },
  methods: {
    emitBack() {
      this.$emit("back");
    },
    async handleLoginGPSHouse(state) {
      this.vendor = state.vendor;
      this.formStep = 1;
      this.topBarLabel = "Chọn xe vào đội xe";
      this.isDisabled = true;
    },
    onSelected($vehicles) {
      this.isDisabled = $vehicles.length < 1;
      this.selectedVehicles = $vehicles;
    },
    confirmStep() {
      if (this.formStep === 1) {
        this.dialog = true;
        return;
      }

      if (this.formStep === 2) {
        this.moveBack();
        return;
      }
    },
    async cloneVehicle() {
      this.alertLoading = true;
      this.confirmText = "";

      const { data } = await createMultiVehicles(
        this.$route.query.supplier_token,
        this.selectedVehicles
      );

      this.dialog = false;
      this.formStep = 2;
      this.topBarLabel = "Kết quả thêm vào đội xe";
      this.submitText = "Hoàn thành";

      this.selectedVehicles = this.selectedVehicles.map((item) => {
        const tmp = data.filter(({ _id }) => _id === item._id);
        const result = {
          ...tmp[0],
          ...item,
        };

        return result;
      });

      return;
    },
    moveBack() {
      this.$emit("confirm");
    },
    validateForm(fields) {
      return Object.values(fields).some((el) => !el);
    },
    getAddedNumber() {
      return getFilterQuantity(this.selectedVehicles, { status: 200 });
    },
    getDupNumber() {
      return getFilterQuantity(this.selectedVehicles, (o) => o.status !== 200);
    },
  },
};
</script>

.
<style lang="scss">
.gps-house-select {
  .button {
    background-color: #3a86ff;
    color: #fff;
  }
  .dropdown-menu {
    .dropdown-item {
      &:not(:last-child) {
        border-bottom: 1px solid #cecece;
      }
    }
  }
}

.gps-house-modal {
  .modal-card-body {
    &.greyscale {
      background-color: #f5f5f5;
    }
  }
}
</style>

<style lang="scss" scoped>
.content {
  text-align: left;
  position: relative;
  height: 100%;
}
.step-form {
  position: absolute;
  width: 100%;
  &#step-0 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &#step-2 {
    & > p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #a2a9b0;
    }
    .vehicle-card {
      margin-left: -12px;
      margin-right: -12px;
      &::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 52px;
        right: 12px;
        border-bottom: 1px solid #e6e9ec;
      }
    }
  }
}

//Animation Transition
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
