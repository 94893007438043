<template>
  <div class="stock-message-layer">
    <img class="icon" alt="stock_layer_icon" :src="successIcon" />
    <div class="stock-message"><slot /></div>
  </div>
</template>

<script>
import { SuccessIcon } from "@/common/icons";
export default {
  name: "StockMessage",
  data() {
    return {
      successIcon: SuccessIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.stock-message-layer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.stock-message {
  padding: 12px;
  background: #e8f9ef;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}
</style>
