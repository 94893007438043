<template>
  <div :class="['profile-box', { vertical, horizontal: !vertical }]">
    <div class="box-container">
      <div class="wrapper">
        <div class="profile-info">
          <div class="header-box">
            <img class="avatar" :src="data.src || placeholder" alt="avatar" />
            <div v-if="rating" class="rating">
              <span>{{ data.rating || 0 }}</span>
              <img class="ic-rating" :src="starIcon" alt="rating" />
            </div>
          </div>
          <div class="text">
            <p v-if="data.title" class="heading">{{ data.title }}</p>
            <p v-if="data.subtitle" class="sub-heading">{{ data.subtitle }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from "@/assets/reporting/profile.png";
import { StarIcon, VehicleImg } from "@/common/icons";

export default {
  name: "ProfileCard",
  props: {
    data: [Object],
    vertical: [Boolean],
    rating: [Boolean],
    isVehicle: [Boolean],
  },
  data() {
    return {
      placeholder: this.isVehicle ? VehicleImg : ProfileImage,
      starIcon: StarIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
$scope-white: #ffffff;

.profile-box {
  &.horizontal {
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 16px;
  }
}

.box-container {
  .vertical & {
    min-height: 60px;
  }
  .horizontal & {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  width: 74px;
  margin-right: 16px;
  border-radius: 50%;
}

.rating {
  padding: 3px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  background: $scope-white;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-right: 2px;
    margin-top: 4px;
  }
}

.header-box {
  .vertical & {
    display: inline-block;
    width: 90px;
    height: 90px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background: $scope-white;
    border-radius: 50%;
    box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);

    .avatar {
      position: absolute;
      width: 84px;
      height: 84px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.heading {
  font-size: 16px;
  font-weight: bold;
  color: #121619;
  margin-bottom: 0px;
  .vertical & {
    font-size: 20px;
    margin-top: 56px;
  }
}
.sub-heading {
  font-size: 15px;
  color: #4d5358;
}
.text {
  text-align: left;
  .vertical & {
    text-align: center;
  }
}

.profile {
  &-info {
    width: 100%;
    display: flex;
    align-items: center;
    .vertical & {
      display: block;
      position: absolute;
      z-index: 1;
      background: white;
      border-radius: 16px;
    }
  }
}
</style>
