<template>
  <div class="datepicker-masked">
    <div class="masked-text">{{ textInput }}</div>
    <b-datepicker
      :min-date="minDated"
      v-model="date"
      :mobile-native="false"
      @input="updateVal"
    >
    </b-datepicker>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "CustomDatePicker",
  props: ["name", "selectedDate", "minDated"],
  data() {
    return {
      textInput: "__/__/____",
      date: new Date(),
    };
  },
  methods: {
    updateVal(value) {
      this.textInput = format(new Date(value), "dd/MM/yyyy");
      this.$emit("input", { name: this.name, value });
    },
  },
  watch: {
    selectedDate: {
      handler(newValue) {
        this.textInput = format(new Date(newValue), "dd/MM/yyyy");
        this.date = new Date(newValue);
      },
    },
  },
};
</script>

<style lang="scss">
.datepicker-masked {
  position: relative;
  max-width: 154px;
  border: 1px solid #c1c7cd;
  border-radius: 4px;
  padding: 8px 16px;
  .masked-text {
    letter-spacing: 4px;
    color: #121619;
    font-weight: 700;
    text-align: center;
  }
  .datepicker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .input {
    opacity: 0;
  }
}
</style>
