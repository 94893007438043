export const orderList = {
  state: {
    supplier_token: "",
    supplierServices: [],
    selectedServices: [],
    selectedStatuses: []
  },
  mutations: {
    setSupplierToken(state, payload) {
      state.supplier_token = payload.value;
    },
    setSupplierServices(state, payload) {
      state.supplierServices = payload.value;
    },
    setSelectedServices(state, payload) {
      state.selectedServices = payload.value;
    },
    setSelectedStatuses(state, payload) {
      state.selectedStatuses = payload.value;
    }
  },
  actions: {
    actionSetSupplierToken(context, { value }) {
      context.commit("setSupplierToken", { value });
    },
    actionSetSupplierServices(context, { value }) {
      context.commit("setSupplierServices", { value });
    },
    actionSetSelectedServices(context, { value }) {
      context.commit("setSelectedServices", { value });
    },
    actionSetSelectedStatuses(context, { value }) {
      context.commit("setSelectedStatuses", { value });
    }
  },
  namespaced: true
};