<template>
  <div class="push-notification" v-if="detail">
    <div class="body">
      <div class="banner">
        <div class="supplier">
          <div class="avatar">
            <img :src="ownerPhoto || profileIcon" alt="owner_photo" />
          </div>
          <div class="name">{{ detail.owner_name }}</div>
          <div class="phone">{{ `+${detail.fleet_id}` }}</div>
        </div>
        <img :src="shakeHandIcon" alt="shake_hand_icon" />
        <div class="supplier">
          <div class="avatar">
            <img :src="driverPhoto || profileIcon" alt="driver_photo" />
          </div>
          <div class="name">{{ detail.supplier_name }}</div>
          <div class="phone">{{ `+${detail.supplier_id}` }}</div>
        </div>
      </div>
      <div class="description">
        <h5>Thư mời tham gia đội xe</h5>
        <p>
          Xin chào đối tác, chủ xe {{ detail.owner_name }} đang mời bạn tham gia đội xe của họ.<br />Quý đối tác vui
          lòng chọn “Tham gia" nếu đồng ý tham gia đội xe hoặc chọn “Từ chối" nếu không muốn tham gia.<br />Cảm ơn quý
          đối tác!
        </p>
      </div>
      <div class="action-button">
        <CustomButton className="full-width primary" label="Tham gia" @clicked="onConfirm" />
        <CustomButton className="full-width" label="Từ chối" @clicked="onReject" />
      </div>
    </div>

    <!-- Show Message Overlay -->
    <MessageOverlay
      v-show="showMessage"
      className="center success-screen"
      btnLabel="Trang chủ"
      :src="overlayIcon"
      :title="overlayTitle"
      @clicked="onExit"
    >
      {{ overlayMessage }}
    </MessageOverlay>

    <!-- Show Alert Dialog -->
    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="primary"
        :title="titleAlert"
        :message="messageAlert"
        cancelText="Huỷ"
        :confirmText="confirmText"
        :loading="dialogLoading"
        @confirm="handleConfirm"
        @cancel="handleCancel"
      />
    </b-modal>
  </div>
</template>

<script>
import ProfileImage from "@/assets/reporting/profile.png";
import AlertDialog from "@/common/components/AlertDialog";
import CustomButton from "@/common/components/CustomButton";
import MessageOverlay from "@/common/components/MessageOverlay";
import { BUTTON_TEXT, MESSAGE_TEXT } from "@/common/constants";
import { ShakeHandIcon, SorryIcon, SuccessIcon } from "@/common/icons";
import { getAvatar } from "@/common/utils";
import { getStatusInvitation, handleDriverAction } from "@/pages/Management/services/driver";
import { mapState } from "vuex";

export default {
  name: "PushNotification",
  components: {
    CustomButton,
    AlertDialog,
    MessageOverlay,
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
  },
  created() {
    this.setUpProfile();
  },
  async mounted() {
    const { id } = this.$route.params;
    const { supplier_token } = this.$route.query;
    if (id) {
      const { data } = await this.getStatusInvitation(id);
      this.detail = data.invite;
      this.supplier_token = this.token || supplier_token || this.detail.supplier_token;

      if (this.detail.status === 2) {
        this.overlayIcon = SuccessIcon;
        this.overlayTitle = MESSAGE_TEXT.NOTICE_TITLE_JOIN;
        this.overlayMessage = MESSAGE_TEXT.MESSAGE_JOIN;
        this.showMessage = true;
      }

      if (this.detail.status === 3) {
        this.overlayIcon = SorryIcon;
        this.overlayTitle = MESSAGE_TEXT.NOTICE_TITLE_REJECT;
        this.overlayMessage = MESSAGE_TEXT.MESSAGE_REJECT;
        this.showMessage = true;
      }

      this.ownerPhoto = await getAvatar(this.detail.fleet_id);
      this.driverPhoto = await getAvatar(this.detail.supplier_id);
    }
  },
  data() {
    return {
      shakeHandIcon: ShakeHandIcon,
      profileIcon: ProfileImage,
      supplier_token: "",
      detail: "",
      ownerPhoto: "",
      driverPhoto: "",
      confirmType: "",
      messageAlert: "",
      titleAlert: "",
      confirmText: "",
      dialog: false,
      dialogLoading: false,

      showMessage: false,
      overlayIcon: SuccessIcon,
      overlayTitle: "",
      overlayMessage: "",
    };
  },
  methods: {
    getStatusInvitation,
    async onConfirm() {
      //TODO: Handle show Confirm content
      this.confirmType = 1;
      this.titleAlert = MESSAGE_TEXT.JOIN_TITLE_DRIVER;
      this.messageAlert = `Tài xế chắc chắn tham gia đội xe của chủ xe ${this.detail.owner_name} ?`;
      this.confirmText = BUTTON_TEXT.JOIN;
      this.dialog = true;
    },
    async onReject() {
      //TODO: Handle show Reject content
      this.confirmType = 0;
      this.titleAlert = MESSAGE_TEXT.NOT_JOIN_TITLE_DRIVER;
      this.messageAlert = `Tài xế chắc chắn muốn từ chối tham gia đội xe của chủ xe ${this.detail.owner_name} ?`;
      this.confirmText = BUTTON_TEXT.NOT_JOIN;
      this.dialog = true;
    },
    handleCancel() {
      this.confirmType = "";
      this.titleAlert = "";
      this.messageAlert = "";
      this.confirmText = "";
      this.dialog = false;
      this.dialogLoading = false;
    },
    async handleConfirm() {
      const { id } = this.$route.params;
      this.dialogLoading = true;
      this.confirmText = "";

      // TODO: 0 is Reject and 1 is Accept
      if (this.confirmType === 0) {
        try {
          await handleDriverAction(this.supplier_token, id, "reject");
          this.overlayIcon = SorryIcon;
          this.overlayTitle = MESSAGE_TEXT.NOTICE_TITLE_REJECT;
          this.overlayMessage = MESSAGE_TEXT.MESSAGE_REJECT;
          this.showMessage = true;
        } catch (error) {
          console.log(error);
        } finally {
          this.handleCancel();
        }

        return;
      }

      if (this.confirmType === 1) {
        try {
          await handleDriverAction(this.supplier_token, id, "accept");
          this.overlayIcon = SuccessIcon;
          this.overlayTitle = MESSAGE_TEXT.NOTICE_TITLE_JOIN;
          this.overlayMessage = MESSAGE_TEXT.MESSAGE_JOIN;
          this.showMessage = true;
        } catch (error) {
          console.log(error);
        } finally {
          this.handleCancel();
        }

        return;
      }
    },
    onExit() {
      // TODO: Handle exit WebView
      this.postMessage({ name: this.EVENT_BACK });
    },
  },
};
</script>
<style lang="scss" scoped>
.push-notification {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.supplier {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.avatar {
  position: relative;
  width: 88px;
  height: 88px;
  margin: 0 auto;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 19px -1px rgba(0, 0, 0, 0.2);

  img {
    padding: 2px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.phone {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.description {
  margin-top: 32px;
  margin-bottom: 32px;

  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

button {
  margin-bottom: 8px;
}
</style>
