import _ from "lodash";
import client from "@/services/client";

export const fetchAsyncManagerInfo = async (token, start_date, end_date) => {
  let params = { token };
  if (!_.isEmpty(start_date) || !_.isEmpty(end_date)) {
    params = {
      ...params,
      start_date,
      end_date,
    };
  }
  const res = await client.appApiOnwheelUtility.get(
    `/metatruck/get_manager_info`,
    { params }
  );
  return res.data;
};

export const fetchAsyncDrivers = async (token, start_date, end_date) => {
  let params = { token };
  if (!_.isEmpty(start_date) || !_.isEmpty(end_date)) {
    params = {
      ...params,
      start_date,
      end_date,
    };
  }
  const res = await client.appApiOnwheelUtility.get(`/metatruck/get_drivers`, {
    params,
  });
  return res.data;
};
