<template>
  <div
    class="custom-select"
    :class="{
      active: selected || open,
    }"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <img class="logo" v-if="selected.src" :src="selected.src" />
      {{ selected.label }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="option of options" :key="option.id" @click="onClick(option)">
        <img class="logo" v-if="option.src" :src="option.src" />
        {{ option.label }}
      </div>
    </div>
    <span class="icon ic-down">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 11.5565L4.94467 6.54762L0 1.53869L1.52227 0L8 6.54762L1.52227 13.0952L0 11.5565Z"
          fill="#007CFF"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CustomDropdown",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    label: String,
    name: String,
  },
  data() {
    return {
      valueSelected: this.default ? this.default : null,
      selected: {
        label: "",
        src: "",
      },
      open: false,
    };
  },
  mounted() {
    if (this.default) {
      const selected = _.filter(this.options, { id: this.default });
      this.selected = selected[0];
      this.$emit("input", { name: this.name, value: this.selected });
    }
  },
  methods: {
    onClick(option) {
      this.selected = option;
      this.open = false;
      this.$emit("input", { name: this.name, value: option });
    },
  },
};
</script>

<style lang="scss" scoped>
$scope-white: #ffffff;
$scope-black: #697077;
$scope-grey: #c1c7cd;
$scope-blue: #007cff;

.custom-select {
  position: relative;
  font-family: SVN-Gilroy;
  width: 100%;
  height: 58px;
  text-align: left;
  outline: none;
  background-color: $scope-white;
  border: 1px solid $scope-grey;
  border-radius: 4px;
}

.selected {
  position: relative;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
}

.logo {
  max-width: 48px;
  max-height: 48px;
  margin-right: 8px;
}

.items {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $scope-grey;
  position: absolute;
  top: 5px;
  left: -1px;
  right: -1px;
  background-color: $scope-white;
  z-index: 1;
  div {
    position: relative;
    height: 58px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid $scope-grey;
    &:hover {
      background-color: $scope-blue;
      color: $scope-white;
      transition: 0.2s ease;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.selectHide {
  display: none;
}

.ic-down {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  svg {
    path {
      fill: $scope-black;
    }
  }
}
</style>
