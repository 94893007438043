export const REFRESH_CONFIG = {
  errorLabel: "Lỗi cập nhật",
  loadingLabel: "Đang tải",
  startLabel: "Kéo xuống để tải lại trang",
  readyLabel: "Chuẩn bị tải lại trang",
};

export const STEP_FORM_TEXT = {
  CONTINUE: "Tiếp tục",
  SEND_DOCUMENT: "Gửi hồ sơ",
  SEND_INVITATION: "Gửi lời mời",
};

export const MESSAGE_TEXT = {
  NOTICE_TITLE: "Đã gửi thông báo",
  NOTICE_TEXT:
    "Hệ thống đã gửi thông báo đến tài xế của bạn. Hãy liên hệ và nhờ tài xế truy cập vào thông báo để hoàn tất quá trình này nhé!",
  NOTICE_TITLE_REJECT: "Bạn đã từ chối tham gia",
  MESSAGE_REJECT:
    "Rất tiếc bạn đã từ chối tham gia đội xe. Hy vọng có cơ hội làm việc thêm với tài xế trong tương lai.",
  MESSAGE_JOIN:
    "Cám ơn tài xế đã đồng ý tham gia đội xe. Hy vọng sẽ được làm việc với tài xế nhiều hơn trong tương lai.",
  NOTICE_TITLE_JOIN: "Tham gia đội xe thành công",
  MESSAGE_NOT_FOUND_TITLE: "Không tìm thấy tài xế",
  MESSAGE_NOT_FOUND:
    "Có vẻ tài xế này chưa có thông tin trên hệ thống. Vui lòng liên hệ với tài xế, yêu cầu tài app Ahamove và hoàn tất đăng ký để có thể thêm tài xế vào đội xe.",
  MESSAGE_FOUND_TITLE: "Mời tài xế vào đội xe",
  MESSAGE_FOUND:
    "Có vẻ tài xế này đã có thông tin trên hệ thống Ahamove. Bạn có muốn gửi lời mời tài xế vào đội xe của mình không?",
  JOIN_TITLE_DRIVER: "Tham gia vào đội xe",
  NOT_JOIN_TITLE_DRIVER: "Từ chối tham gia đội xe",
  DRIVER_BUSY: "Tài xế thuộc đội khác",
  MESSAGE_DRIVER_BUSY: "Có vẻ tài xế này đang thuộc đội xe",
};

export const BUTTON_TEXT = {
  CONFIRM: "Đã hiểu",
  CANCEL: "Huỷ",
  INVITE_DRIVER: "Mời tài xế",
  JOIN: "Tham gia",
  NOT_JOIN: "Từ chối ",
};
