<template>
  <div>
    <b-button @click="toUti"> To utilities</b-button>
  </div>
</template>

<script>
export default {
  name: "EmbeddedMain",
  methods: {
    toUti() {
      this.$router.push({
        name: "Utilities",
      });
    }
  },
}
</script>
