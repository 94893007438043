import client from "@/services/client";

export const fetchBigCDriverStats = async (token, supplier_id) => {
  const params = {
    token,
    supplier_id,
  };
  const res = await client.appApiOnwheelUtility.get(`/bigc/get_driver_stats`, {
    params,
  });
  return res.data;
};
