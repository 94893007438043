export const utilities = {
    state: {
        utiList: []
    },
    mutations: {
        update(state, {field, value}) {
            state[field] = value;
        },
    },
    actions: {
        updateUtiList({ commit }, payload) {
            commit("update", {
                field: 'utiList',
                value: payload.value});
        },
    },
    namespaced: true
}
