<template>
    <div :class="['type_' + type]" class="noti-container">
      <b-icon
          :icon="type === 'success' ? 'check-outline' : 'alert-circle-outline'"
          size="is-large">
      </b-icon>
      <div class="noti-message">{{ message }}</div>
    </div>
</template>

<script>
export default {
  name: "PopupModal",
  props: ["type", "message"],
};
</script>

<style scoped lang="scss">
.noti-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .noti-message {
    margin-left: 10px;
  }
}
.type {
  color: black;

  &_success {
    font-weight: 600;
    color: white;
    background: #42b983;
  }

  &_error {
    color: white;
    background: indianred;
  }
}
</style>
