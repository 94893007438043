<template>
  <div class="tab-wrapper">
    <VuePullRefresh :on-refresh="onRefresh" :config="config">
      <div class="inner">
        <VehicleItem
          v-for="(truck, index) in list"
          :edit="true"
          :key="index"
          :detail="truck"
          @update="updateDocuments"
          @get-detail="navigateDetail"
        />
      </div>
    </VuePullRefresh>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VuePullRefresh from "vue-pull-refresh";
import { REFRESH_CONFIG } from "@/common/constants";
import VehicleItem from "@/pages/Management/components/VehicleItem";

export default {
  name: "LinkedTab",
  props: ["tabIndex"],
  computed: {
    ...mapGetters({
      list: "managementStore/getLinkedVehicles",
    }),
  },
  components: {
    VehicleItem,
    VuePullRefresh,
  },
  mounted() {
    //TODO: Get list data and set to store
    const token = this.$route.query.supplier_token;
    this.getList({ token });
  },
  data() {
    return {
      detailModal: false,
      detailInfo: "",
      config: REFRESH_CONFIG,
    };
  },
  methods: {
    ...mapActions({
      getList: "managementStore/fetchLinkedVehicles",
    }),
    async onRefresh() {
      const token = this.$route.query.supplier_token;

      return new Promise((resolve) => {
        this.getList({ token }).then(() => resolve());
      });
    },
    updateDocuments(emitValue) {
      const { id } = this.$route.params;
      const { supplier_token } = this.$route.query;
      this.$router.push({
        path: `/management/${id}/documents/${emitValue._id}`,
        query: { supplier_token },
      });
    },
    onConfirmAddModal() {
      this.addModal = false;
      this.gpsHouseModal = false;
      this.$emit("change-tab", 1);
    },
    onBackDetail() {
      this.detailModal = false;
      this.detailInfo = "";
    },
    navigateDetail(value) {
      const { id } = this.$route.params;
      const { supplier_token } = this.$route.query;
      const { _id } = value;
      this.$router.push({
        path: `/management/${id}/vehicle/detail/${_id}`,
        query: { supplier_token },
      });
    },
  },
};
</script>
