<template>
  <div>
    <!-- <button v-on:click="makeACall">
      <b-icon icon="phone"></b-icon>
      Call to {{ callTo }}
    </button> -->

    <!-- Calling {{ callTo }} -->

    <script
      omi-sdk
      type="application/javascript"
      src="https://cdn.omicrm.com/sdk/2.0.0/sdk.min.js"
    ></script>
  </div>
</template>

<script>
export default {
  props: {
    domain: {
      type: String,
      // default: "",
    },
    username: {
      type: String,
      // default: "",
    },
    password: {
      type: String,
      // default: "",
    },
    callTo: {
      type: String,
      // default: "",
    },
  },
  methods: {
    register: function (autocall = false) {
      const { domain, username, password, callTo } = this;
      console.log("before unregister...");
      // eslint-disable-next-line no-undef
      omiSDK.unregister();

      console.log("unregistered successfully!");

      // document.onreadystatechange = () => {
      let config = {
        theme: "default",
        debug: true,
        language: "vi", // Ngôn ngữ giao diện dialog,
        ringtoneVolume: 0.5,
        options: {
          hideCallButton: true,
        },
        callbacks: {
          register: (data) => {
            console.log("hei broo!!! registered!");
            // Sự kiện xảy ra khi ghi danh tổng đài

            this.adjustCSS();

            console.log("finished adjusting CSS");

            if (data.status && autocall) {
              console.log({ data });
              console.log("before making call");
              this.makeACall();
            }
          },
          connecting: (data) => {
            // Sự kiện xảy ra khi bắt đầu thực hiện cuộc gọi ra
            console.log("connecting:", data);
          },
          ringing: (data) => {
            // Sự kiện xảy ra khi bắt đầu rung chuông
            console.log("ringing:", data);
          },
          invite: (data) => {
            // Sự kiện xảy ra khi có một cuộc gọi tới
            console.log("invite:", data);
          },
          inviteRejected: (data) => {
            // Sự kiện xảy ra khi có một cuộc gọi tới
            console.log("inviteRejected:", data);
          },
          incall: (data) => {
            // Sự kiện xảy ra sau khi cuộc gọi đã được kết nối mỗi 1 giây
            console.log("incall:", data);
          },
          accepted: (data) => {
            // Sự kiện xảy ra khi cuộc gọi được chấp nhận
            console.log("accepted:", data);
          },
          acceptedByOther: (data) => {
            // Sự kiện xảy ra khi cuộc gọi hiện tại được nghe máy ở thiết bị khác
            console.log("acceptedByOther:", data);
          },
          ended: (data) => {
            // Sự kiện xảy ra khi cuộc gọi kết thúc
            console.log("ended:", data);
          },
          holdChanged: (status) => {
            // Sự kiện xảy ra khi cuộc gọi đang được giữ
            console.log("on hold:", status);
          },
        },
      };

      if (domain && username && password && callTo) {
        console.log("enough information");
        // eslint-disable-next-line no-undef
        omiSDK.init(config, () => {
          console.log("finished init");
          // eslint-disable-next-line no-undef
          console.log(omiSDK, "register now!");
          // eslint-disable-next-line no-undef
          omiSDK.register({
            domain,
            username,
            password,
          });
        });
        console.log("already called init");
      } else {
        this.$buefy.dialog.alert({
          type: "is-danger",
          message: "No Omi Info was found!",
          title: "Error",
        });
      }
    },
    askForPermission: function () {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          // video: true,
        })
        .then(() => {
          console.log("allow permission, let's call!!!");
          this.register(true);
        })
        .catch((error) => {
          console.log(error);
          alert("Permission denied");
        });
    },
    makeACall: function () {
      console.log(`calling to: ${this.callTo}`);
      // eslint-disable-next-line no-undef
      omiSDK.makeCall(this.callTo);
    },

    removeOmiSDK: function () {
      // eslint-disable-next-line no-undef
      omiSDK.stopCall();
      // eslint-disable-next-line no-undef
      // omiSDK.unregister();
    },
    adjustCSS: function () {
      if (document.getElementById("omi_sdk_nqs")) {
        document.getElementById("omi_sdk_nqs").style.right = "unset";
        document.getElementById("omi_sdk_nqs").style.margin = "auto";
        document.getElementById("omi_sdk_nqs").style.position = "unset";
        document.getElementById("omi_sdk_nqs").style.maxHeight = "unset";
      }
    },
  },
  mounted: function () {
    document.onreadystatechange = () => {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          this.removeOmiSDK();
        }
      });
      this.register(true);
      // this.makeCall();
      // this.askForPermission();
    };
  },
  beforeDestroy: function () {
    this.removeOmiSDK();
  },
};
</script>

<style></style>
