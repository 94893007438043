export const supplierList = {
  state: {
    order_id: "",
    order_service: "",
    supplier_token: "",
    supplier_id: "",
    supplier_name: "",
    admin_token: "",
    order_assigned: false,
    master_supplier_id: "",
    user_id: "",
    show_confirm_modal: false,
    show_caller_modal: false,
    show_toast: {
      type: "", message: "",
      enable: false
    },
    open_in_app: false
  },
  mutations: {
    setOrderId(state, payload) {
      state.order_id = payload.value;
    },
    setOrderService(state, payload) {
      state.order_service = payload.value;
    },
    setMasterSupplierId(state, payload) {
      state.master_supplier_id = payload.value;
    },
    setSupplierId(state, payload) {
      state.supplier_id = payload.value;
    },
    setSupplierName(state, payload) {
      state.supplier_name = payload.value;
    },
    setUserId(state, payload) {
      state.user_id = payload.value;
    },
    setSupplierToken(state, payload) {
      state.supplier_token = payload.value;
    },
    setAdminToken(state, payload) {
      state.admin_token = payload.value;
    },
    setOrderAssigned(state, payload) {
      state.order_assigned = payload.value;
    },
    setShowConfirmModal(state, payload) {
      state.show_confirm_modal = payload.value;
    },
    setShowCallerModal(state, payload) {
      state.show_caller_modal = payload.value;
    },
    setShowToast(state, payload) {
      state.show_toast = payload.value;
    },
    setOpenInApp(state, payload) {
      state.open_in_app = payload.value;
    }
  },
  actions: {
    actionSetOrderId(context, { value }) {
      // console.log(value);
      context.commit("setOrderId", { value });
    },
    actionSetOrderService(context, { value }) {
      context.commit("setOrderService", { value });
    },
    actionSetMasterSupplierId(context, { value }) {
      context.commit("setMasterSupplierId", { value });
    },
    actionSetSupplierId(context, { value }) {
      context.commit("setSupplierId", { value });
    },
    actionSetSupplierName(context, { value }) {
      context.commit("setSupplierName", { value });
    },
    actionSetUserId(context, { value }) {
      context.commit("setUserId", { value });
    },
    actionSetSupplierToken(context, { value }) {
      context.commit("setSupplierToken", { value });
    },
    actionSetAdminToken(context, { value }) {
      context.commit("setAdminToken", { value });
    },
    actionSetOrderAssigned(context, { value }) {
      context.commit("setOrderAssigned", { value });
    },
    actionSetShowConfirmModal(context, { value }) {
      context.commit("setShowConfirmModal", { value });
    },
    actionSetShowCallerModal(context, { value }) {
      context.commit("setShowCallerModal", { value });
    },
    actionSetShowToast(context, { value }) {
      context.commit("setShowToast", { value });
    },
    actionSetOpenInApp(context, { value }) {
      context.commit("setOpenInApp", { value });
    }
  },
  namespaced: true
};
