import client from "@/services/client";

export async function getOrderListing(supplier_token, supplier_id, service_ids, statuses) {
  try {
    let queryParams = [];
    if (service_ids && service_ids.length > 0)
      service_ids.forEach(service_id => queryParams.push(`service_id=${service_id}`));
    if (statuses && statuses.length > 0)
      statuses.forEach(status => queryParams.push(`status=${status}`));

    const res = await client.appApiOnwheelUtility.get(
      `orders/${supplier_id}/truck${queryParams.length > 0 ? "?" + queryParams.join("&") : ""}`,
      {
        headers: {
          token: supplier_token
        }
      }
    );

    return res.data;
  } catch (err) {
    console.log(`getOrderListing err: ${JSON.stringify(err)}`);
    // return err;
  }
}