<template>
  <div></div>
</template>

<script>
export default {
  name: "Caller",
  methods: {
    caller() {
      console.log(`caller -> ${this.$route.params.id}`)
      window.location = `tel:${this.$route.params.id}`;
    }
  },
  created() {
    if (this.$route.params.id && this.$route.params.id !== '')
      this.caller()
  }
}
</script>

<style scoped>

</style>