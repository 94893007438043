<template>
  <div class="form-group">
    <label>
      <input
        placeholder=" "
        autocomplete="off"
        :type="type || 'text'"
        :name="name"
        :value="value"
        @input="updateValue"
      />
      <span class="float-label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: ["label", "value", "name", "type"],
  methods: {
    updateValue(event) {
      const { name, value } = event.target;
      this.$emit("input", { name, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  border: 1px solid #c1c7cd;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 13px 16px;
  margin: 8px 0;
}

label {
  position: relative;
  display: block;
}

.float-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

input {
  border: none;
  outline: none;
  width: 100%;
  position: relative;
  top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
}

input:focus + .float-label,
input:not(:placeholder-shown) + .float-label {
  top: 0;
  white-space: nowrap;
  transform: scale(0.75) translateY(-68%) translateX(-16%);
}

/* For IE Browsers*/
input:focus + .float-label,
input:not(:-ms-input-placeholder) + .float-label {
  top: 0;
  white-space: nowrap;
  transform: scale(0.75) translateY(-68%) translateX(-16%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
