<template>
  <div class="header-wrapper">
    <img
      @click="$router.back()"
      :src="arrowIcon"
      alt="back_icon"/>
    <span>Thống kê cho chủ xe</span>
    <img class="icon" :src="image" alt="alert_icon" />
  </div>
</template>

<script>
import AlertIcon from "@/assets/reporting/alert-icon.png";
import { ArrowIcon } from "@/common/icons";

export default {
  name: "Header",
  data() {
    return {
      image: AlertIcon,
      arrowIcon: ArrowIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
}
.icon {
  max-height: 21px;
  max-width: 21px;
}
</style>
