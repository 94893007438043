<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Hồ sơ tài xế</p>
      </CustomTopBar>
    </div>
    <div class="content-box">
      <DriverInfoForm :detail="detail" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import DriverInfoForm from "@/pages/Management/components/Form/DriverInfoForm";

export default {
  name: "DriverInfo",
  components: {
    CustomTopBar,
    DriverInfoForm,
  },
  computed: {
    ...mapGetters({
      driverDetail: "managementStore/getDriverDetail",
    }),
  },
  async mounted() {
    const { driverId } = this.$route.params;
    const { supplier_token } = this.$route.query;
    await this.getDriverDetail({
      token: supplier_token,
      supplier_id: driverId,
    });
    this.detail = this.mapData(this.driverDetail[driverId].extra);
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      detail: "",
    };
  },
  methods: {
    ...mapActions({
      getDriverDetail: "managementStore/fetchDriverDetail",
    }),
    goBack() {
      this.$router.back();
    },
    mapData(data) {
      if (data.documents) {
        const id_urls = data.documents.filter(
          (item) =>
            item.type === "id_card_front" || item.type === "id_card_back"
        );
        return {
          ...data,
          id_urls,
        };
      }

      return data;
    },
  },
};
</script>
