import {
  getLinkedVehicles,
  getUnlinkedVehicles,
  getProcessVehicles,
  getVehicleById,
} from "@/pages/Management/services/vehicle";

import {
  getAssignedDrivers,
  getNoAssignedDrivers,
  getVerifyingDrivers,
  getChildDriverProfile,
} from "@/pages/Management/services/driver";

const fetchLinkedVehicles = async ({ commit }, { token }) => {
  try {
    const data = await getLinkedVehicles(token);
    commit("setLinkedVehicles", data);
  } catch (error) {
    console.log(error);
  }
};

const fetchUnlinkedVehicles = async ({ commit }, { token }) => {
  try {
    const data = await getUnlinkedVehicles(token);
    commit("setUnlinkedVehicles", data);
  } catch (error) {
    console.log(error);
  }
};

const fetchProcessVehicles = async ({ commit }, { token }) => {
  try {
    const data = await getProcessVehicles(token);
    commit("setProcessVehicles", data);
  } catch (error) {
    console.log(error);
  }
};

const fetchAssignedDrivers = async ({ commit }, { token }) => {
  try {
    const data = await getAssignedDrivers(token);
    commit("setAssignedDrivers", data);
  } catch (error) {
    console.log(error);
  }
};
const fetchNoAssignedDrivers = async ({ commit }, { token }) => {
  try {
    const data = await getNoAssignedDrivers(token);
    commit("setNoAssignedDrivers", data);
  } catch (error) {
    console.log(error);
  }
};
const fetchVerifyingDrivers = async ({ commit }, { token, owner_id }) => {
  try {
    const data = await getVerifyingDrivers(token, owner_id);

    commit("setVerifyDrivers", data);
  } catch (error) {
    console.log(error);
  }
};

const fetchDriverDetail = async ({ commit }, { token, supplier_id }) => {
  try {
    const data = await getChildDriverProfile(token, supplier_id);

    commit("setDriverDetail", data);
  } catch (error) {
    console.log(error);
  }
};

const fetchVehicleDetail = async ({ commit }, { token, vehicleId }) => {
  try {
    const data = await getVehicleById(token, vehicleId);
    commit("setVehicleDetail", data);
  } catch (error) {
    console.log(error);
  }
};

const setDriverServices = ({ commit }, data) => {
  commit("setDriverServices", data);
};

export default {
  fetchLinkedVehicles,
  fetchUnlinkedVehicles,
  fetchProcessVehicles,
  fetchAssignedDrivers,
  fetchNoAssignedDrivers,
  fetchVerifyingDrivers,
  fetchDriverDetail,
  fetchVehicleDetail,
  setDriverServices,
};
