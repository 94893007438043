<template>
  <div class="modal-card" style="width: auto">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Chọn xe để liên kết</p>
      </CustomTopBar>
    </div>
    <section class="modal-card-body">
      <div
        class="custom-radio linked-truck"
        v-for="item in list"
        :key="item._id"
      >
        <div class="text">
          <span>{{ item.plate_number }}</span>
          <span>{{ item.type_name_vi_vn }}</span>
        </div>
        <b-radio
          v-model="radio"
          name="truck"
          :native-value="item._id"
          @input="onChange"
        />
      </div>
    </section>
    <div class="actions">
      <CustomButton
        class="primary full-width"
        :label="submitText"
        :loading="isLoading"
        :disabled="!radio || isLoading"
        @clicked="linkTruck"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ChevronIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";

export default {
  name: "LinkModal",
  components: {
    CustomTopBar,
    CustomButton,
  },
  computed: {
    ...mapGetters({
      list: "managementStore/getUnlinkedVehicles",
    }),
  },
  mounted() {
    //TODO: Get list data and set to store
    const token = this.$route.query.supplier_token;
    this.getList({ token });
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      submitText: "Liên kết",
      isLoading: false,
      radio: "",
    };
  },
  methods: {
    ...mapActions({
      getList: "managementStore/fetchUnlinkedVehicles",
    }),
    goBack() {
      this.$emit("back");
    },
    linkTruck() {
      this.isLoading = true;
      this.submitText = "";
      this.$emit("action-linked-truck", this.radio);
    },
    onChange(value) {
      this.radio = value;
    },
  },
};
</script>

<style lang="scss">
$color-main: #1890ff;

.custom-radio {
  position: relative;
  .b-radio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.radio {
      input[type="radio"] + .check {
        position: absolute;
        right: 20px;
        &:before {
          background-color: $color-main;
        }
      }

      &:hover {
        input[type="radio"]:not(:disabled) + .check {
          border-color: $color-main;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.linked-truck {
  &:not(:first-child) {
    padding-top: 8px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #e6e9ec;
  }

  .text {
    text-align: left;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #a2a9b0;
    span {
      &:first-child {
        font-size: 16px;
        color: #121619;
      }
    }
  }
}
</style>
