<template>
  <div class="listing-container">
    <div class="header-container">
      <TopBar
          title="Lịch sử thanh toán"
          :left-icon-path="backIconPath"
          @leftChildClick="goBack"
      />
    </div>
    <div class="content-container">
      <Loader :is-loading="isLoading"/>
      <div v-if="!isLoading">
        <div v-if="!needPayment">
          <PaymentTransactions :transactions="transactions" />
        </div>
        <div v-else>
          <PaymentModal :needPayment="needPayment" :paymentInfo="paymentInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {fetchTransactions, makePaymentRequest} from "@/pages/PaymentHistory/services/payment";
import PaymentModal from "@/pages/PaymentHistory/components/PaymentModal";
import PaymentTransactions from "@/pages/PaymentHistory/components/PaymentTransactions";
import Loader from "@/common/Loader";
import TopBar from "@/pages/OrderListing/components/TopBar.vue";
import router from "@/router";
import { format } from "date-fns";

const viTimezone = "Asia/Ho_Chi_Minh";


export default {
  name: "PaymentHistory",
  components: {TopBar, Loader, PaymentModal, PaymentTransactions},
  computed: {
    ...mapState(['token'])
  },
  data() {
    return {
      backIconPath: "order-listing/left-arrow.png",
      isLoading: true,
      needPayment: true,
      paymentInfo: {},
      transactions: []
    }
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  methods: {
    goBack() {
      router.go(-1);
    },
  },
  async mounted() {
    const token = this.token || this.$route.query.supplier_token;
    const data = await makePaymentRequest(token);
    this.isLoading = false;
    if (data) {
      if ('paid' in data && data.paid === true) {
        this.needPayment = false;
        const transactionResp = await fetchTransactions(token);
        if ('transactions' in transactionResp) {
          this.transactions = transactionResp
              .transactions
                .map((trans) => {
                  if ('status' in trans) {
                    if (trans.status === 'PAID' && 'paid_at' in trans) {
                      trans['created_at'] = format(new Date(trans.paid_at), 'HH:mm dd/MM/yyyy', { timeZone: viTimezone });
                    } else if (trans.status === 'CANCELLED' && 'cancelled_at' in trans) {
                      trans['created_at'] = format(new Date(trans.cancelled_at), 'HH:mm dd/MM/yyyy', { timeZone: viTimezone });
                    }
                  }

                  return trans
                });
        }
      }
      if ('transactions' in data && Object.keys(data.transactions).length > 0) this.paymentInfo = data.transactions;
    }
  }
}
</script>

<style lang="scss" scoped>
.listing-container {
  padding-bottom: 24px;
  font-family: "SVN-Gilroy";
  height: 100vh;
  background: #ffffff;

  .header-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }

  .search-box-wrapper {
    padding: 0 16px 13px;
    margin-top: 16px;
  }
}

.content-container {
  position: relative;
  height: calc(100% - 112px);
  background-color: #ffffff;
  padding-top: 1px;

  .notification {
    position: absolute;
    height: 100%;
    min-height: 100px;
    width: 100%;
  }
}

.toast-container {
  padding: 0 16px 8px;
  position: fixed;
  width: 100vw;
  height: auto;
  bottom: 16px;
  z-index: 20;
}
</style>