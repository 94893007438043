<template>
  <div class="uti" @click="handleIframe()">
    <!--  <div class="uti" @click="/*redirect*/">-->
    <img class="uti-icon" :src="itemInfo.icon_url" alt="utility_icon" />
    <div class="uti-name">{{ itemInfo.name_vi }}</div>
  </div>
</template>

<script>
export default {
  name: "UtilityItem",
  props: {
    itemInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    handleIframe() {
      if (!("iframe" in this.itemInfo)) {
        return;
      }

      if (this.itemInfo?.name === "Booking") {
        if (this.itemInfo?.url) location.href = this.itemInfo.url;
        return;
      }

      this.$router.push({
        name: "IframeView",
        query: {
          url: this.itemInfo.url,
        },
      });
    },
    async redirect() {
      if (this.itemInfo.name === "Booking") {
        this.itemInfo.out = true;
        window.location.href = this.itemInfo.url;
        return;
      }
      if (this.itemInfo.out) {
        window.location.href = this.itemInfo.url;
        return;
      }
      if (this.itemInfo.is_app) {
        window.location = this.itemInfo.url;
      } else {
        await this.$router.push({
          name: "IframeView",
          query: {
            url: this.itemInfo.url,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.uti {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  &-icon {
    max-width: 50%;
    height: 80px;
  }

  &-name {
    margin-top: 5px;
    max-width: 50%;
    word-wrap: break-word;
    max-height: 40px;

    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #001529;
  }
}
</style>
