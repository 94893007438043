import client from "@/services/client";

const token = process.env.VUE_APP_ADMIN_TOKEN;

export async function pushAssignedNotification(
  admin_token,
  supplier_id,
  assigned_order_id,
  assigned_order_service_id,
  assigned_order_user_id,
  assigned_supplier_id,
  assigned_supplier_name
) {
  const res = await client.appApiAhamove.post(
    `/admin/notification/push`,
    {
      type: "supplier",
      alive_until: Math.round(Date.now() / 1000) + 86400 * 7,
      app: "OnWheel",
      ids: [supplier_id],
      title: `Gán đơn hàng ${assigned_order_id}`,
      message: `Đơn hàng [ ${assigned_order_id} - ${assigned_order_service_id} ] đã được điều cho tài xế [ ${assigned_supplier_name} - ${assigned_supplier_id} ]. Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại [Link]`,
      formatted_message: `<blockquote><p>Đơn hàng <em>[ <strong>${assigned_order_id}</strong> - <strong>${assigned_order_service_id} </strong>]</em> đã được điều cho tài xế <em>[ <strong>${assigned_supplier_name}</strong> - <strong>${assigned_supplier_id}</strong> ]</em>.</p><p>Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại <em>[<a href="${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true"><span style="color: #f26624;"><strong>Link</strong></span></a>]</em></p></blockquote>`,
      url: `${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true`,
      noti_type: "news",
      is_immediate: true,
      is_silent: false,
      save: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // console.log({ res });
  return res.data;
}

export async function sendPushAssignNotification(
  supplier_id,
  assigned_order_id,
  assigned_order_service_id,
  assigned_order_user_id,
  assigned_supplier_id,
  assigned_supplier_name
) {
  const title = `Gán đơn hàng ${assigned_order_id}`,
    message = `Đơn hàng [ ${assigned_order_id} - ${assigned_order_service_id} ] đã được điều cho tài xế [ ${assigned_supplier_name} - ${assigned_supplier_id} ]. Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại [Link]`,
    formatted_message = `<blockquote><p>Đơn hàng <em>[ <strong>${assigned_order_id}</strong> - <strong>${assigned_order_service_id} </strong>]</em> đã được điều cho tài xế <em>[ <strong>${assigned_supplier_name}</strong> - <strong>${assigned_supplier_id}</strong> ]</em>.</p><p>Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại <em>[<a href="${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true"><span><strong>Link</strong></span></a>]</em></p></blockquote>`,
    url = `${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true`,
    alive_until = Math.round(Date.now() / 1000) + 86400 * 7;
  const res = await client.appApiAhamoveV1.post(
    `/admin/send_push?token=${process.env.ADMIN_TOKEN}&type=supplier&app=OnWheel&alive_until=${alive_until}&ids=${supplier_id}&noti_type=news&city_id=SGN&is_immediate=true&is_silent=false&save=true&title=${title}&url=${url}&message=${message}&formatted_message=${formatted_message}`
  );
  // console.log({ res });
  return res.data;
}

export async function adminPushNotification(
  supplier_id,
  assigned_order_id,
  assigned_order_service_id,
  assigned_order_user_id,
  assigned_supplier_id,
  assigned_supplier_name
) {
  const title = `Gán đơn hàng ${assigned_order_id}`,
    message = `Đơn hàng [ ${assigned_order_id} - ${assigned_order_service_id} ] đã được điều cho tài xế [ ${assigned_supplier_name} - ${assigned_supplier_id} ]. Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại [Link]`,
    formatted_message = `<blockquote><p>Đơn hàng <em>[ <strong>${assigned_order_id}</strong> - <strong>${assigned_order_service_id} </strong>]</em> đã được điều cho tài xế <em>[ <strong>${assigned_supplier_name}</strong> - <strong>${assigned_supplier_id}</strong> ]</em>.</p><p>Quý Đối tác có thể theo dõi chi tiết thông tin đơn hàng tại <em>[<a href="${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true"><span><strong>Link</strong></span></a>]</em></p></blockquote>`,
    url = `${process.env.VUE_APP_AHAMOVE_CLOUD}/share-order/${assigned_order_id}/${assigned_order_user_id}?process_location=true`,
    alive_until = Math.round(Date.now() / 1000) + 86400 * 7;
  const res = await client.appApiAdmin.post(
    `/send_push_notification`,
    {
      notification: {
        type: "supplier",
        app: "OnWheel",
        title,
        message,
        formatted_message,
        url,
        alive_until,
        ids: supplier_id,
        token,
      },
      extra: {
        admin_id: "84935095650",
      },
    },
    {
      headers: { token },
    }
  );
  return res.data;
}
