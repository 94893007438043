<template>
  <div>
    <div v-if="!isEdit" class="subtitle">Bước 1/2: Cung cấp thông tin xe</div>
    <Divider v-if="!isEdit" fluid />
    <form class="vehicle-form">
      <div class="form-section">
        <h6>Thông tin phương tiện</h6>

        <div class="edit-form">
          <div class="group">
            <label for="">Loại phương tiện</label>
            <select v-model="fields.type" name="type" @input="selectTypeChange">
              <option
                v-for="item in vehicle_types"
                :key="item.code"
                :value="item.code"
              >
                {{ item.description }}
              </option>
            </select>
          </div>

          <div class="group">
            <label for="">Hãng phương tiện</label>
            <select
              name="brand"
              v-model="fields.brand"
              @input="selectBrandChange"
            >
              <option
                v-for="item in brands"
                :key="item.brand"
                :value="item.brand"
              >
                {{ item.brand }}
              </option>
            </select>
          </div>

          <div v-if="models" class="group">
            <label for="">Kiểu phương tiện</label>
            <select name="model" v-model="fields.model" @input="selectChange">
              <option v-for="item in models" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div v-if="colors" class="group">
            <label for="">Màu phương tiện</label>
            <select name="color" v-model="fields.color" @input="selectChange">
              <option v-for="item in colors" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <Divider fluid />
      <div class="form-section">
        <h6>Thông tin xe</h6>

        <CustomInput
          label="Số hiệu xe"
          name="serial"
          :value="fields.serial"
          @input="inputChange"
        />
        <CustomInput
          label="Giấy phép lái xe"
          name="license_id"
          :value="fields.license_id"
          @input="inputChange"
        />
        <CustomInput
          label="Năm sản xuất"
          name="manufactured_year"
          type="number"
          :value="fields.manufactured_year"
          @input="inputChange"
        />
        <CustomInput
          label="Đăng ký kinh doanh/Mã số thuế"
          name="tax_id"
          :value="fields.tax_id"
          @input="inputChange"
        />
        <CustomInput
          label="Địa chỉ công ty"
          name="company_address"
          :value="fields.company_address"
          @input="inputChange"
        />
        <CustomInput
          label="Số giấy phép vận tải"
          name="transport_license"
          :value="fields.transport_license"
          @input="inputChange"
        />
      </div>
      <Divider fluid />
      <div class="form-section">
        <h6>Khối lượng và kích thước</h6>
        <p class="weight-field-label">Kích thước lòng, thùng xe (mm)</p>
        <div class="weight-field">
          <CustomInput
            label="Dài (mm)"
            name="length"
            type="number"
            :value="fields.length"
            @input="inputChange"
          />
          x
          <CustomInput
            label="Rộng (mm)"
            name="width"
            type="number"
            :value="fields.width"
            @input="inputChange"
          />
          x
          <CustomInput
            label="Cao (mm)"
            name="height"
            type="number"
            :value="fields.height"
            @input="inputChange"
          />
        </div>

        <CustomInput
          label="Khối lượng hàng CC theo TK/CP TGGT (kg)"
          name="weight"
          type="number"
          :value="fields.weight"
          @input="inputChange"
        />
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";
import Divider from "@/common/components/CustomDivider";
import CustomInput from "@/common/components/CustomInput";
import { getVehicleTypes } from "@/pages/Management/services/vehicle";

export default {
  name: "FirstStepForm",
  props: ["isEdit", "detailData"],
  components: {
    CustomInput,
    Divider,
  },
  async mounted() {
    const { data } = await getVehicleTypes();
    this.vehicle_types = data;
    if (this.$props.detailData && this.$props.isEdit) {
      const { detailData } = this.$props;
      const selectedType = _.filter(
        this.vehicle_types,
        (o) => o.code === detailData.type
      );
      const brands = selectedType[0].vehicle;
      this.brands = brands;
      const selectedBrand = _.filter(
        this.brands,
        (o) => o.brand === detailData.brand
      );
      const colors = selectedBrand[0].color;
      const models = selectedBrand[0].model;
      this.colors = colors;
      this.models = models;

      const fields = {
        ...this.fields,
        type: detailData.type,
        brand: detailData.brand,
        color: detailData.color,
        model: detailData.model,
        license_id: detailData.license_id,
        manufactured_year: detailData.manufactured_year,
        tax_id: detailData.tax_id,
        company_address: detailData.company_address,
        transport_license: detailData.transport_license,
        serial: detailData.serial,
        weight: detailData.weight,
        length: detailData.length,
        width: detailData.width,
        height: detailData.height,
      };
      this.fields = fields;
    }
  },
  data() {
    return {
      vehicle_types: [],
      brands: "",
      models: "",
      colors: "",
      fields: {
        type: "", //Required
        brand: "", //Required
        model: "", //Required
        color: "", //Required
        license_id: "",
        manufactured_year: "",
        tax_id: "",
        company_address: "",
        transport_license: "",
        serial: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    };
  },
  methods: {
    inputChange(input) {
      const { name, value } = input;
      this.fields[name] = value;
      this.$emit("input", this.fields);
    },
    selectChange($event) {
      const { name, value } = $event.target;
      this.fields[name] = value;
      this.$emit("input", this.fields);
    },
    selectTypeChange($event) {
      const { value, name } = $event.target;

      const selectedType = _.filter(
        this.vehicle_types,
        (o) => o.code === value
      );
      this.fields[name] = value;

      const brands = selectedType[0].vehicle;
      this.brands = brands;

      this.fields.brand = "";
      this.fields.model = "";
      this.fields.color = "";

      this.$emit("input", this.fields);
    },
    selectBrandChange($event) {
      const { value, name } = $event.target;
      const selectedBrand = _.filter(this.brands, (o) => o.brand === value);
      this.fields[name] = value;

      const colors = selectedBrand[0].color;
      const models = selectedBrand[0].model;

      this.colors = colors;
      this.models = models;

      this.fields.color = "";
      this.fields.model = "";

      this.$emit("input", this.fields);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-form {
  select {
    border: 1px solid #c1c7cd;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 13px 8px;
    font-size: 16px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #a2a9b0;
  margin-bottom: 16px;
}
.desc {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #a2a9b0;
  font-style: italic;
}
.form-section {
  padding-left: 0px;
  padding-right: 0px;
  .weight-field {
    display: flex;
    align-items: center;
    gap: 4px;
    &-label {
      margin-bottom: -4px;
    }
  }
}
</style>
