<template>
  <div class="card">
    <div class="card-box">
      <div class="order-header" @click="toTrackUrl(data.order_id, data.user_id)">
        <img :src="data.icon_url" alt="service_icon" class="order-header__icon" />
        <div class="order-header__text">
          <div class="order-header__text--datetime">
            {{ data.order_time }} |
            {{ data.distance > 1 ? data.distance + " km" : data.distance * 1000 + " m" }}
          </div>
          <div class="order-header__text--info">{{ data.service_vi }} #{{ data.order_id }}</div>
        </div>
      </div>
      <div class="order-info">
        <div class="order-info__row">
          <img class="order-info__icon" src="@/assets/order-listing/pickup-icon.png" alt="" />
          <div class="order-info__text">{{ data.pick_up }}</div>
        </div>
        <div class="order-info__row order-info__row--bottom-border">
          <img class="order-info__icon" src="@/assets/order-listing/dropoff-icon.png" alt="" />
          <div class="order-info__text">{{ data.drop_off }}</div>
        </div>
        <div class="order-info__row order-info__row--bottom-border">
          <img class="order-info__icon" src="@/assets/order-listing/cash-icon.png" alt="" />
          <div class="order-info__text">
            {{ parseCurrency(parseInt(data.total_fee)) }} | COD {{ parseCurrency(parseInt(data.cod)) }}
          </div>
        </div>
        <div class="order-info__row order-info__row--bottom-border" v-if="data.unloading_request">
          <img class="order-info__icon" src="@/assets/order-listing/special-request-icon.png" alt="" />
          <div class="order-info__text">Có bốc xếp</div>
        </div>
      </div>
      <div class="order-button" @click="navigateSupplierListing(data.order_id, supplier_token)">Danh sách gán đơn</div>
    </div>
  </div>
</template>

<script>
import { parseCurrency } from "@/common/utils";
import { getOrderInfo } from "@/pages/SupplierListing/services/orderService";
import { mapActions, mapState } from "vuex";

export default {
  name: "OrderCard",
  props: ["data"],
  computed: {
    ...mapState("supplierList", ["supplier_token", "admin_token"]),
  },
  methods: {
    ...mapActions("supplierList", ["actionSetShowToast"]),
    parseCurrency,
    navigateSupplierListing(order_id, supplier_token) {
      this.$router.push({
        path: `/supplier-listing-new?order_id=${order_id}&supplier_token=${supplier_token}`,
      });
    },
    async toTrackUrl(order_id, user_id) {
      let order_info = null;
      try {
        order_info = await getOrderInfo(this.admin_token, order_id);
        if (order_info.length === 0) {
          this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! 404: Đơn hàng không có thông tin`;
          this.actionSetShowToast({ value: { type: "failed", message: this.error_message, enable: true } });
          setTimeout(() => {
            this.actionSetShowToast({ value: { enable: false } });
          }, 3000);
        }
      } catch (error) {
        this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
        await this.actionSetShowToast({ value: { type: "failed", message: this.error_message, enable: true } });
        setTimeout(() => this.actionSetShowToast({ value: { enable: false } }), 5000);
      }
      const url = `https://${
        process.env["NODE_ENV"] === "production" ? "cloud" : "cloudstg"
      }.ahamove.com/share-order/${order_id}/${user_id}?process_location=true`;
      this.postMessage({
        name: this.EVENT_SHOW_TOOLBAR,
      });
      await this.$router.push({
        name: "IframeView",
        query: { url },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
  padding: 0 16px;
  margin-bottom: -8px;

  .card-box {
    border: 1px solid #dde1e6;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
  }

  .order-header {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 7.5px;
    //background: #F2F4F8;
    border-bottom: 1px solid #e5e7e8;

    &__icon {
      height: 33px;
      width: 33px;
      //left: 1.5px;
      //top: 1.5px;
      //border-radius: 0px;
    }

    &__text {
      font-family: "SVN-Gilroy";
      font-style: normal;
      margin-left: 7.5px;
      font-size: 14px;
      line-height: 22px;
      text-align: left;

      &--datetime {
        font-weight: 700;
        color: #21272a;
      }

      &--info {
        font-weight: 500;
        color: #a2a9b0;
      }
    }
  }

  .order-info {
    padding: 0 16px;

    &__row {
      display: flex;
      align-items: center;
      align-content: center;
      padding: 10px 0;

      &--bottom-border {
        border-bottom: 1px solid #e5e7e8;
      }
    }

    &__icon {
      height: 16px;
      width: 16px;
    }

    &__text {
      font-family: "SVN-Gilroy";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-left: 16px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-wrap: inherit;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .order-button {
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 10px 16px;

    display: flex;
    align-items: center;

    color: #3a86ff;
  }
}
</style>
