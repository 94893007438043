import client from "@/services/client";

export const fetchLoadingInfo = async (params) => {
  const res = await client.appApiOnwheelUtility.get(
    `/seedlog/v1/get_loading_info`,
    { params }
  );
  return res.data;
};

export const updateLoadingInfo = async (payload) => {
  const res = await client.appApiOnwheelUtility.post(
    `/seedlog/v1/update_loading_info`,
    payload
  );
  return res.data;
};
