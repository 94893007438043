<template>
  <div class="card">
    <div class="card-box">
      <div class="media">
        <div class="media-left">
          <figure class="image is-48x48">
            <img v-if="data.avatar" :src="data.avatar" alt="profile" />
            <img v-else :src="profile" alt="profile" />
          </figure>
        </div>
        <div class="media-content">
          <p class="title">{{ data.name }}</p>
          <p
            class="subtitle"
            v-bind:class="{
              success: data.status === 'ONLINE',
              danger: data.status === 'BUSY'
            }"
          >
            <span>{{ data.last_activity }}</span> | {{ data.distanceText }}
          </p>
          <p
            class="subtitle same_route_score_text"
            v-bind:class="{ success: data.same_route_score.score >= 90, danger: data.same_route_score.score < 90 }"
            v-if="data.same_route_score && data.same_route_score && data.same_route_score.score > 0"
          >
            <span
              >Tỉ lệ trùng tuyến {{ Math.floor(data.same_route_score.score) }}% -
              {{ data.same_route_score.order_id || "trước đó" }}
            </span>
          </p>
        </div>
        <div class="media-right btn-icon" @click="showCallerModal(data)">
          <img :src="phoneIcon" alt="phone_icon" />
        </div>
      </div>
      <div class="info">
        <div class="columns is-mobile">
          <div class="column is-half-mobile">
            <div class="label-item">
              <label>Biển số xe</label>
              <p>{{ data.plate_number }}</p>
            </div>
          </div>
          <div class="column is-half-mobile">
            <div class="label-item">
              <label>Phương tiện</label>
              <p>{{ data.vehicle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="actions" v-if="data.is_in_range">
        <b-button @click="checkSupplierOrder(data)">Gán đơn</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneIcon from "@/assets/reporting/phone-icon.svg";
import Profile from "@/assets/reporting/profile.png";
import { getSupplierOrder } from "@/pages/SupplierListing/services/orderService";
import { getSameRouteScore } from "@/pages/SupplierListing/services/supplierService";
import { mapActions, mapState } from "vuex";

export default {
  name: "CardItem",
  props: ["data"],
  data() {
    return {
      phoneIcon: PhoneIcon,
      profile: Profile,
      status: true,
      nonPreemptiveServiceList: [
        "-TRICYCLE",
        "-PICKUP-TRUCK",
        "-VAN-500",
        "-TRUCK-500",
        "-VAN-1000",
        "-TRUCK-1000",
        "-TRUCK-1500",
        "-TRUCK-2000",
        "-VAN-B2B-500",
        "-TRUCK-B2B-500",
        "-VAN-B2B-1000",
        "-TRUCK-B2B-1000",
        "-TRUCK-B2B-1500",
        "-TRUCK-B2B-2000",
      ],
    };
  },
  computed: {
    ...mapState("supplierList", ["supplier_token", "order_service", "order_id"]),
  },
  methods: {
    ...mapActions("supplierList", [
      "actionSetSupplierId",
      "actionSetSupplierName",
      "actionSetShowConfirmModal",
      "actionSetShowCallerModal",
      "actionSetShowToast",
    ]),
    async checkSupplierOrder(supplier) {
      try {
        const orders = await getSupplierOrder(this.supplier_token, supplier._id);
        console.log("service", this.order_service);
        console.log("orders", orders);
        if (orders && orders.length > 0) {
          for (const order of orders) {
            const regexStr = new RegExp(`(${this.nonPreemptiveServiceList.join("|")})$`);
            if (order.service_id === this.order_service && order.service_id.match(regexStr)) {
              this.show_toast = {
                type: "failed",
                message: `Tài xế đã có đơn hàng với dịch vụ. Vui lòng kết thúc đơn hàng ${order.id} trước khi nhận đơn hàng mới!`,
                enable: true,
              };
              this.actionSetShowToast({ value: this.show_toast });
              setTimeout(() => {
                this.show_toast.enable = false;
                this.actionSetShowToast({ value: this.show_toast });
              }, 5000);
              return;
            }
          }
        }
        this.showConfirmModal(supplier);
      } catch (err) {
        this.show_toast = {
          type: "failed",
          message: `Không thể kiểm tra đơn hàng của tài xế. Lỗi ${err.toString()}`,
          enable: true,
        };
        this.actionSetShowToast({ value: this.show_toast });
        setTimeout(() => {
          this.show_toast.enable = false;
          this.actionSetShowToast({ value: this.show_toast });
        }, 5000);
      }
    },
    showConfirmModal(item) {
      // console.log({ item });
      this.supplier_id = item._id;
      this.actionSetSupplierId({ value: this.supplier_id });
      this.supplier_name = item.name;
      this.actionSetSupplierName({ value: this.supplier_name });
      this.isShowConfirmModal = true;
      this.actionSetShowConfirmModal({ value: this.isShowConfirmModal });
    },
    showCallerModal(item) {
      // console.log(`TableListing-showCallerModal: item-> ${JSON.stringify(item)}`);
      this.actionSetSupplierId({ value: item._id });
      this.actionSetSupplierName({ value: item.name });
      this.actionSetShowCallerModal({ value: true });
    },
  },
  async mounted() {
    if (this.data.coordinates && this.data.is_in_range) {
      const same_route_score = await getSameRouteScore(
        this.order_id,
        this.data._id,
        this.supplier_token,
        this.data.coordinates[0],
        this.data.coordinates[1]
      );
      this.$set(this.data, "same_route_score", same_route_score);
    }
  },
  watch: {
    data: {
      handler: async function (newVal, oldVal) {
        if (
            newVal.coordinates !== oldVal.coordinates ||
            newVal.is_in_range !== oldVal.is_in_range
        ) {
          if (newVal.coordinates && newVal.is_in_range) {
            const same_route_score = await getSameRouteScore(
                this.order_id,
                newVal._id,
                this.supplier_token,
                newVal.coordinates[0],
                newVal.coordinates[1]
            );
            this.$set(newVal, "same_route_score", same_route_score);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.card-box {
  padding: 16px;

  .media {
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &-content {
      text-align: left;

      .title {
        color: #343a3f;
        line-height: 24px;
        font-size: 16px;
      }

      .subtitle {
        position: relative;
        font-size: 14px;
        line-height: 22px;
        padding-left: 12px;

        &::before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &.success {
          span {
            color: #48bb78;
          }

          &::before {
            background-color: #48bb78;
          }
        }

        &.danger {
          span {
            color: #f5222d;
          }

          &::before {
            background-color: #f5222d;
          }
        }
      }

      .same_route_score_text {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        margin-top: -20px;

        &.success {
          span {
            color: #48bb78;
          }
        }

        &.danger {
          span {
            color: #f5222d;
          }
        }
      }
    }

    &-left {
      img {
        border-radius: 50%;
      }
    }
  }
}

.label-item {
  text-align: left;

  label {
    color: #c1c7cd;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  p {
    color: #4d5358;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.info {
  //border-bottom: 1px solid #f2f4f8;
  border-top: 1px solid #f2f4f8;
  padding-top: 8px;
  //padding-bottom: 8px;
  //margin-bottom: 12px;
}

.actions {
  padding-top: 8px;

  button {
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    width: 100%;
    padding: 14px 16px;
    background: #3a86ff;
    border-radius: 4px;
  }
}

.btn-icon {
  padding: 16px;
  border-radius: 50%;
  border: none;
  background-color: #e5e7e8;
  display: block;
  min-width: 36px;
  height: 36px;
  position: relative;

  img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
