import client from "@/services/client";

export async function getSupplierInfo(supplier_token) {
  const res = await client.appApiAhamove.get(`/supplier/profile?token=${supplier_token}`);
  return res.data;
}

export async function getAdminToken(supplier_token) {
  // console.log({supplier_token})
  const res = await client.appApiAhamove.post(
    "/supplier/generate-admin-token",
    {},
    {
      headers: {
        Authorization: `Bearer ${supplier_token}`,
      },
    }
  );
  return res.data.token;
}

export async function getFavoriteSuppliersInfo(supplier_token, supplier_id) {
  try {
    const res = await client.appApiOnwheelUtility.get(`/suppliers/${supplier_id}/truck?favorite=true`, {
      headers: {
        token: supplier_token,
      },
    });
    // console.log(`getFavoriteSuppliersInfo res.data: ${JSON.stringify(res.data)}`);
    let data = res.data.filter((item) => item.account_status === "ACTIVATED");
    return data.map((item) => {
      return {
        name: item.name,
        vehicle: item.vehicle_type ? item.vehicle_type : "",
        coordinates: (item?.updated_location?.coordinates) || item?.location?.coordinates || [0, 0],
        last_activity: item.last_activity,
        status: item.status,
        _id: item._id,
        services: item.services,
        plate_number: item.plate_number ? item.plate_number : "",
        avatar: item.avatar.url,
      };
    });
  } catch (err) {
    console.log(`getFavoriteSuppliersInfo err: ${JSON.stringify(err)}`);
    return err;
  }
}

export async function getSameRouteScore(order_id, supplier_id, supplier_token, sup_lng, sup_lat) {
  try {
    const res = await client.appApiOnwheelUtility.post(
      `/orders/${order_id}/same_route_score`,
      {
        supplier: {
          _id: supplier_id,
          location: {
            coordinates: [sup_lng, sup_lat],
          },
        },
      },
      {
        headers: {
          token: supplier_token,
        },
      }
    );
    return {
      score: (res.data && res.data.score) || 0,
      order_id: (res.data.matched_order && res.data.matched_order._id) || "",
    };
  } catch (err) {
    console.log(`getSameRouteScore err: ${JSON.stringify(err)}`);
  }
}

export async function getOsrmDistance(sup_coord_list, order_lat, order_lng) {
  console.log("getOsrmDistance", order_lat, order_lng);
  try {
    const res = await client.appApiOnwheelUtility.post("/osrm/get_distance_from_pickup", {
      sources: [[order_lat, order_lng]],
      destinations: sup_coord_list,
    });
    console.log("getOsrmDistance", res.data);
    return res.data;
  } catch (err) {
    console.log(`getOsrmDistance err: ${JSON.stringify(err)}`);
  }
}
