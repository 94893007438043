<template>
  <div class="home">
    <TopBar />
    <router-view />
  </div>
</template>

<script>
import TopBar from "@/common/components/TopBar";

export default {
  name: "EmbeddedActions",
  components: {
    TopBar,
  },
  mounted() {
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR,
    });
  },
};
</script>
