<template>
  <div class="tab-wrapper">
    <VuePullRefresh :on-refresh="onRefresh" :config="config">
      <div class="inner">
        <DriverListItem
          v-for="item in list"
          :key="item._id"
          :data="item"
          @open-detail="openDetail(item)"
        />
      </div>
    </VuePullRefresh>
    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import CustomToast from "@/common/components/CustomToast";
import { REFRESH_CONFIG } from "@/common/constants";
import DriverListItem from "@/pages/Management/components/DriverListItem";
import VuePullRefresh from "vue-pull-refresh";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AssignedTab",
  components: { CustomToast, DriverListItem, VuePullRefresh },
  computed: {
    ...mapGetters({
      list: "managementStore/getAssignedDrivers",
    }),
  },
  mounted() {
    //TODO: init action api - get data from init state in store
    const token = this.$route.query.supplier_token;
    try {
      this.getAssignedList({ token });
    } catch (error) {
      this.type = "error";
      this.message = `Không thể lấy thông tin tài xế`;
      this.isShowToast = true;

      setTimeout(() => {
        this.message = ``;
        this.isShowToast = false;
      }, 2000);
    }
  },
  data() {
    return {
      config: REFRESH_CONFIG,
      isShowToast: false,
      type: "",
      message: "",
      position: "bottom",
    };
  },
  methods: {
    ...mapActions({
      getAssignedList: "managementStore/fetchAssignedDrivers",
    }),
    onRefresh() {
      const token = this.$route.query.supplier_token;

      return new Promise((resolve) => {
        this.getAssignedList({ token }).then(() => resolve());
      });
    },
    openDetail(data) {
      //TODO path is driverId
      // this.setDriverDetail(data);
      const { id } = this.$route.params;
      const supplierToken = this.$route.query.supplier_token;
      this.$router.push({
        path: `/management/${id}/driver/detail/${data._id}`,
        query: { supplier_token: supplierToken },
      });
    },
  },
};
</script>

<style lang="scss">
.pull-down-header {
  background-color: transparent;
}
</style>
