<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="container profile-detail">
        <div class="sticky">
          <CustomTopBar :parentModal="$parent">
            <div class="content">Thông tin tài xế</div>
          </CustomTopBar>
        </div>
        <ProfileInfo :data="detail">
          <a :href="'tel:+' + detail.driver_id" class="btn-icon">
            <img :src="phoneIcon" alt="phone_icon" />
          </a>
        </ProfileInfo>
        <Divider fluid />
        <div class="profile-display__container">
          <DisplayItem
            label="Loại xe:"
            :content="detail.vehicle.text"
            :imageURL="detail.vehicle.photo"
          />
          <DisplayItem label="Biển số xe" :imageURL="detail.vehicle_plate" />
          <DisplayItem
            label="Đăng kiểm xe"
            :imageURL="detail.registration_certificate"
          />
          <DisplayItem
            label="Giấy tờ xe"
            :imageURL="detail.vehicle_registration"
          />
          <DisplayItem label="Bằng lái xe" :imageURL="detail.driver_license" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PhoneIcon from "@/assets/reporting/phone-icon.svg";
import CustomTopBar from "@/common/components/CustomTopBar";
import Divider from "@/common/components/CustomDivider";
import ProfileInfo from "@/pages/Profile/components/MainProfile/ProfileInfo";
import DisplayItem from "@/pages/Profile/components/DetailProfile/DisplayItem";

export default {
  name: "DetailProfile",
  components: { CustomTopBar, ProfileInfo, Divider, DisplayItem },
  props: ["detail"],
  data() {
    return {
      phoneIcon: PhoneIcon,
    };
  },
};
</script>

<style lang="scss">
.btn-icon {
  padding: 16px;
  border-radius: 50%;
  border: none;
  background-color: #e5e7e8;
  display: block;
  min-width: 50px;
  height: 50px;
  position: relative;
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.profile-display__container {
  text-align: left;
}
.sticky {
  position: fixed;
  width: 100%;
  left: 0;
  top: 30px;
  z-index: 2;
  transform: translateY(-30px);
}
.profile-detail {
  .profile-box.horizontal {
    margin-top: 54px;
  }
}

.img-item-zoom-holder .animation-content{
    width: fit-content !important;
}
</style>
