<template>
  <div>
    <!-- move the params of username / password / domain to env / whatever else to avoid hardcode here -->
    <Iqos
      v-if="$route.params.phone && !isIOSDevice"
      username="108"
      password="yKA3LUzgXR"
      domain="namtrantrung1"
      v-bind:callTo="$route.params.phone"
    />
    <div v-if="isIOSDevice" class="not-support-container">
      <img src="../../assets/not-support-ios.jpg" style="width: 100%" />
      <div class="not-support-center">
        We're currently not supporting iOS for this feature.
      </div>
    </div>
  </div>
</template>

<script>
import Iqos from "@/common/components/iqos/Iqos.vue";

export default {
  components: {
    Iqos,
  },
  computed: {
    isIOSDevice: function () {
      return navigator.userAgent.includes("like Mac");
    },
  },
};
</script>

<style>
body {
  min-height: 100vh;
  display: flex;
  vertical-align: middle;
}

.not-support-center {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not-support-container {
  position: relative;
  text-align: center;
}
</style>
