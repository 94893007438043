<template>
  <div class="toast-wrapper" :class="`${type} ${position}`">
    <img class="toast-icon" alt="toast_icon" :src="type === 'success' ? successIcon : errorIcon" />
    <img
      v-if="showCloseIcon"
      class="toast-icon--close"
      alt="close_icon"
      :src="closeIcon"
      @click="$emit('close-toast')"
    />
    <div class="toast-message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SuccessIcon, CloseIcon, ErrorIcon } from "@/common/icons";

export default {
  name: "CustomToast",
  props: ["type", "position", "message", "showCloseIcon"],
  data() {
    return {
      successIcon: SuccessIcon,
      errorIcon: ErrorIcon,
      closeIcon: CloseIcon,
    };
  },
  computed: { ...mapState("supplierList", ["supplier_name"]) },
};
</script>

<style lang="scss">
.toast-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 94%;
  padding: 16px 24px;
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "SVN-Gilroy";
  z-index: 21;

  &.success {
    background: #e8f9ef;
  }

  &.error {
    background: #ffebea;
  }

  &.bottom {
    top: auto;
    bottom: 16px;
    transform: translateX(-50%);
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.toast-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  &--close {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
  }
}

.toast-message {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  white-space: pre-line;
}
</style>
