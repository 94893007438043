<template>
  <div class="map-marker-inner">
    <img
      class="marker-icon"
      v-if="data.status === 'busy'"
      :src="truckBusy"
      alt="truck_icon_busy"
    />
    <img
      class="marker-icon"
      v-if="data.status === 'offline' || data.status === 'other'"
      :src="truckOffline"
      alt="truck_icon_offline"
    />
    <img
      width="80px"
      class="marker-icon"
      v-if="data.status === 'online'"
      :src="truckOnline"
      :style="{'transform': `rotate(${data.direction ? data.direction : 0}deg)`}"
      alt="truck_icon_online"
    />
    <div class="marker-text">{{ data.plate || "" }}</div>
  </div>
</template>

<script>
import BusyIcon from "@/assets/map/truck-busy.png";
// import OnlineIcon from "@/assets/map/truck-online.png";
import OnlineIcon from "@/assets/map/truck-online-v2.png";
import OfflineIcon from "@/assets/map/truck-offline.png";

export default {
  name: "MarkerItem",
  props: ["data"],
  data() {
    return {
      truckBusy: BusyIcon,
      truckOnline: OnlineIcon,
      truckOffline: OfflineIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.marker-text {
  padding: 4px;
  border-radius: 2px;
  background-color: rgba(48, 58, 70, 0.5);
  color: #fff;
  margin-top: -30px;
}
</style>
