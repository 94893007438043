<template>
  <div class="confirm-container">
    <div class="confirm-title">Thông tin đơn hàng</div>
    <div class="confirm-note">
      Nhấn vào item để chỉnh sửa*
    </div>
    <div>
      <b-table
          class="item-list-table"
          :data="filteredItemList"
          :focusable="false"
          :mobile-cards="false"
          :show-header="false"
      >

        <b-table-column :centered="false" field="first_name" v-slot="props">
          <div @click="openModify(props.row)" class="item-header">
            <div class="img-container">
              <img :src="require(`@/assets/kamegomi/${imgData[props.row.img] || imgData['OTHER']}.png`)" alt="">
            </div>
            <div>
              <div class="item-info">{{ props.row.name }}</div>
              <div class="item-price-unit">{{ props.row.price_unit.toLocaleString() }}đ/{{ props.row.unit_type }}</div>
            </div>
          </div>
        </b-table-column>

        <b-table-column field="last_name" v-slot="props">
          <div @click="openModify(props.row)" class="item-info">
            <div>
              {{ props.row.weight.toLocaleString() }} {{ props.row.unit_type }}
            </div>
          </div>
        </b-table-column>

        <b-table-column field="last_name" v-slot="props">
          <div @click="openModify(props.row)" class="item-info">
            {{ (props.row.weight * props.row.price_unit).toLocaleString() }}đ
          </div>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Giỏ hàng trống</div>
        </template>

      </b-table>
    </div>
    <div class="checkout-footer-container">
      <CheckoutFooter @open-tip="toggleTip(true)"
                      @submit="submitGarbage"
                      :total-cash="totalValue.totalCash"
                      :total-weight="totalValue.totalWeight"/>
    </div>
    <Modal noPadding closeButton isCloseOnClickOutside v-show="isTipModalOpen" @close="toggleTip(false)">
      <div class="tip-container">
        <Tip @close="toggleTip(false)"/>
      </div>
    </Modal>
    <Loader :is-loading="isSubmitting"/>
  </div>
</template>

<script>
import CheckoutFooter from "../components/CheckoutFooter";
import {mapGetters, mapState} from "vuex";
import Modal from "@/common/components/Modal";
import Tip from "../components/Tip";
import services from '../services'
import Loader from "../../../common/Loader";


export default {
  name: "ConfirmPage",
  components: {
    CheckoutFooter,
    Modal,
    Tip,
    Loader
  },
  data: () => ({
    isTipModalOpen: false,
    isSubmitting: false
  }),
  computed: {
    ...mapState({
      filteredItemList: state => state.kamegomi.itemList.filter(item => item.weight > 0),
      orderId: state => state.kamegomi.orderId,
      imgData: state => state.kamegomi.imgData,
      itemList: state => state.kamegomi.itemList,
      ahaOrderId: state => state.ahaOrderId
    }),
    ...mapGetters('kamegomi', [
      'totalValue'
    ])
  },
  methods: {
    toggleTip(status) {
      this.isTipModalOpen = status
    },
    openModify(data) {
      this.$emit("open-modify", {...data})
    },
    noticeFail() {
      this.$buefy.notification.open({
        duration: 3000,
        message: 'Đã xảy ra lỗi, xin vui lòng thử lại',
        position: 'is-top-right',
        type: 'is-danger',
        hasIcon: true
      })
    },
    async submitGarbage() {
      try {
        this.isSubmitting = true
        await services.submitGarbage({
          orderId: this.orderId,
          data: {
            bins: this.itemList,
            status: 'COLLECTED',
            aha_order_id: this.ahaOrderId
          }
        })
        await this.completeOrder()
        setTimeout(async () => {
          this.isSubmitting = false
          this.postMessage({
            name: this.EVENT_CLOSE
          });
        }, 200)
      } catch {
        this.isSubmitting = false
        this.noticeFail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-container {
  background: linear-gradient(183.61deg, rgba(4, 126, 255, 0.1) 5%, rgba(252, 252, 252, 0) 50%);
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 20px 10px 25vh 10px;

  .confirm-title {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .confirm-note {
    text-align: left;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .checkout-footer-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .item-list-table {
    /deep/ table.table {
      background: none;

      td {
        padding: 10px 0;
      }
    }

    .item-info {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .item-price-unit {
      font-size: 12px;
      line-height: 20px;
      text-align: left;
    }

    .item-header {
      display: flex;
      align-items: center;

      .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        img {
          width: 50px;
          height: auto;
        }
      }
    }
  }
}

.tip-container {
  padding: 10px
}
</style>
