import Vue from "vue";
import Vuex from "vuex";
import { utilities } from "@/store/utilities";
import { kamegomi } from "@/store/kamegomi";
import { supplierList } from "@/store/supplier-listing";
import { orderList } from "@/store/order-listing";
import { managementStore } from "@/store/management";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    supplierProfile: {},
    token: "",
    ahaOrderId: "",
  },
  mutations: {
    updateProfile(state, payload) {
      state.supplierProfile = payload.profile;
    },
    updateToken(state, { token }) {
      state.token = token;
    },
    updateAhaOrderId(state, { ahaOrderId }) {
      state.ahaOrderId = ahaOrderId;
    },
  },
  actions: {
    actionUpdateProfile({ commit }, payload) {
      commit("updateProfile", payload);
    },
    actionUpdateToken({ commit }, payload) {
      commit("updateToken", payload);
    },
    setAhaOrderId(context, { ahaOrderId }) {
      context.commit("updateAhaOrderId", { ahaOrderId });
    },
  },
  modules: {
    utilities,
    kamegomi,
    supplierList,
    orderList,
    managementStore,
  },
});
