import client from "@/services/client";

export const getQRString = async (params) => {
  const response = await client.appApiOnwheelUtility.get(`/ancuong/v1/generate_qrcode`,
    { params }
  );
  return response.data;
};

export const insertCOD = async (body) => {
  return client.appApiEPOnWheel.post(
    `/ancuong/v1/set_dropoff_cash_collection`,
    body
  );
};
