<template>
  <div class="table__wrapper">
    <div class="table__display table__show-all">
      <b-table
        :data="transactions"
        :paginated="true"
        :per-page="10"
        sort-icon="chevron-up"
        default-sort="user"
        :mobile-cards="false"
      >
        <b-table-column field="period" label="Kỳ thanh toán" cell-class="custom-cell" v-slot="props">
          <p>{{ props.row.period }}</p>
          <p v-if="props.row.status === 'PAID'" class="has-text-success">Đã thanh toán</p>
          <p v-else class="has-text-danger">Huỷ</p>
        </b-table-column>

        <b-table-column field="period" label="Thông tin" cell-class="custom-cell" v-slot="props">
          <p v-if="props.row.created_at"><b>Ngày:</b> {{ props.row.created_at }}</p>
          <p><b>Số tiền:</b> {{ new Intl.NumberFormat('en-US').format(props.row.amount || 0 )}} vnđ</p>
          <p><b>Mô tả:</b> {{ props.row.description }}</p>
        </b-table-column>

        <template #empty>
          <div class="no-records has-text-centered">Không có dữ liệu.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

export default {
  name: "PaymentTransactions",
  props: ["transactions"],
};
</script>

<style lang="scss">
.table {
  &__wrapper {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }

    .btn-show-all {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #007cff;

      img {
        margin-left: 8px;
        margin-top: 4px;
      }
    }
  }
  &__search-box {
    margin-top: 12px;
  }
  &__show-all {
    margin-top: 24px;
    .pagination-next,
    .pagination-previous {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .pagination-list li {
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    .pagination-link {
      border-color: transparent;
    }
    .pagination-link.is-current {
      background-color: #ffffff;
      color: #007cff;
      border-color: #007cff;
    }
  }
}

.table-wrapper {
  thead th {
    font-size: 14px;
    color: #a2a9b0;
    white-space: nowrap;
  }
  .custom-cell {
    font-size: 14px;
    vertical-align: middle;
    text-align: left;
  }
}

.width {
  &-150 {
    width: 150px;
  }
  &-120 {
    width: 120px;
  }
}

.no-records {
  height: 120px;
}
</style>
