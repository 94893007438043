<template>
  <div class="confirm-footer-container">
    <div class="confirm-outline total">
      <div>Tổng:</div>
      <div class="total-number">{{ totalWeight ? totalWeight : 0 }}kg</div>
      <div class="total-number">{{ (totalCash ? totalCash : 0).toLocaleString() }}đ</div>
    </div>

    <!--    <div class="confirm-outline cash">-->
    <!--      <div>Thu nhập <span class="note" @click="openTip">(Tìm hiểu thêm)</span></div>-->
    <!--      <div>{{ cashReceivable ? cashReceivable : 0 }}đ</div>-->
    <!--    </div>-->
    <div class="confirm-button-container">
      <button @click="back" class="button-confirm button-confirm-back">Quay lại</button>
      <button @click="submit" class="button-confirm button-confirm-confirm">Xác nhận đơn hàng</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutFooter",
  props: {
    totalCash: Number,
    totalWeight: {
      type: [String, Number]
    },
    cashReceivable: Number
  },
  methods: {
    back() {
      this.$router.push({
        name: "KameGomi"
      })
    },
    openTip() {
      this.$emit('open-tip')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-footer-container {
  background: #FFFFFF;
  box-shadow: 0px -2px 10px -4px rgba(15, 16, 17, 0.2);
  padding: 10px 15px;

  .confirm-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-confirm {
      padding: 15px 0;
      width: 48%;
      border: none;
      outline: none;

      &:hover {
        outline: none;
      }

      &-back {
        background: #F2F2F2;
        border-radius: 2px;
      }

      &-confirm {
        background: #FF8200;
        border-radius: 2px;
        color: white
      }
    }
  }
}

.confirm-outline {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  .note {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #0F5AA8;
  }
}

.cash {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.total {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;

  .total-number {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }
}

.border-bottom {
  border-bottom: 0.5px solid #CACACA;
}
</style>
