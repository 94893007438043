<template>
  <div class="iframe-container">
    <iframe class="iframe" :src="url"></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IframeView",
  computed: {
    ...mapState(['token'])
  },
  data: () => ({
    url: ''
  }),
  mounted() {
    this.url = this.$route.query.url
    if (this.url.includes('onwheel.io') && this.token) {
      try {
        const uri = new URL(this.url)
        const currentTime = this.$moment().utcOffset(7).unix()
        const payload = {
          key: 'apiToken',
          value: process.env.VUE_APP_ADMIN_TOKEN,
          userToken: this.token, // TODO: need pass user token
          created: currentTime,
          accessed: currentTime,
          expires: currentTime
        }
        const iframeEl = document.getElementById('embedded')
        iframeEl.contentWindow.postMessage(JSON.stringify(payload), uri.origin)
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }
}
</script>

<style lang="scss" scoped>

.iframe-container {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
.iframe {
  width: 100%;
  height: 100%;
}
}
</style>
