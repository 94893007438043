<template>
  <div class="form-wrapper">
    <form>
      <h6>Hệ thống</h6>
      <CustomDropdown
        name="vendor"
        :options="gpsHouse"
        :default="fields.vendor"
        @input="handleDropdown"
      />

      <h6>Đăng nhập</h6>
      <CustomInput
        label="Tài Khoản"
        name="username"
        :value="fields.username"
        @input="inputChange"
      />
      <CustomInput
        label="Mật khẩu"
        name="password"
        type="password"
        :value="fields.password"
        @input="inputChange"
      />

      <CustomButton
        className="full-width primary"
        :label="submitText"
        :loading="isLoading"
        :disabled="validateForm(fields) || isLoading"
        @clicked="loginGPSHouse"
      />
    </form>
    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      :showCloseIcon="true"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import AdsunLogo from "@/assets/logo/adsun.png";
// import BinhMinhLogo from "@/assets/logo/binh-minh.png";
// import SkysoftLogo from "@/assets/logo/skysoft.png";
import CustomInput from "@/common/components/CustomInput";
import CustomButton from "@/common/components/CustomButton";
import CustomDropdown from "@/common/components/CustomDropdown";
import CustomToast from "@/common/components/CustomToast";
import { login3PLHouse } from "@/pages/Management/services/vehicle";

export default {
  name: "AccessForm",
  components: {
    CustomInput,
    CustomButton,
    CustomDropdown,
    CustomToast,
  },
  data() {
    return {
      label: "Adsun",
      gpsHouse: [{ id: "adsun", label: "Adsun", src: AdsunLogo }],
      fields: {
        vendor: "adsun",
        username: "",
        password: "",
      },
      submitText: "Đăng nhập",
      isLoading: false,
      isShowToast: false,
      type: "error",
      message: "",
      position: "bottom",
    };
  },
  methods: {
    inputChange(input) {
      const { name, value } = input;
      this.fields[name] = value;
    },
    handleDropdown($input) {
      const { name, value } = $input;
      this.label = value.label;
      this.fields[name] = value.id;
    },
    async loginGPSHouse() {
      this.isLoading = true;
      this.submitText = "";
      try {
        await login3PLHouse(this.$route.query.supplier_token, this.fields);
        this.$emit("login-gps-house", {
          vendor: this.fields.vendor,
        });
      } catch (error) {
        this.type = "error";
        this.message = `Đã xảy ra lỗi, xin vui lòng thử lại!\n${error.response.status}: ${error.response.data.title}`;
        this.isShowToast = true;
        setTimeout(() => {
          this.isShowToast = false;
        }, 3000);
      } finally {
        this.submitText = "Đăng nhập";
        this.isLoading = false;
      }
    },
    validateForm(fields) {
      return Object.values(fields).some((el) => !el);
    },
  },
};
</script>

<style lang="scss" scoped>
#step-0 {
  h6 {
    margin-top: 24px;
  }
}
.custom-item {
  display: flex;
  align-items: center;
  min-height: 48px;
  span {
    margin-left: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
