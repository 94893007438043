<template>
  <div class="message-overlay">
    <div :class="['content', className]">
      <img v-if="src" :src="src" alt="icon_message" class="icon-image" />
      <div class="title">{{ title }}</div>
      <div class="subtitle">
        <slot></slot>
      </div>
      <CustomButton
        className="full-width primary"
        :label="btnLabel"
        @clicked="$emit('clicked')"
      />
    </div>
  </div>
</template>

<script>
import CustomButton from "@/common/components/CustomButton";
export default {
  name: "MessageOverlay",
  props: ["src", "title", "btnLabel", "className"],
  components: { CustomButton },
};
</script>

<style lang="scss" scoped>
$scoped-dark: #121619;
$scoped-white: #ffffff;

.message-overlay {
  top: 0;
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
  background: $scoped-white;
}

.content {
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $scoped-dark;
  }
  .subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $scoped-dark;
  }
  .icon-image {
    margin-bottom: 16px;
  }
}

.center {
  text-align: center;
  .icon-image {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
