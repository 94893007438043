<template>
  <div class="container management">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Management",
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
};
</script>
<style lang="scss">
$color-white: #ffffff;

//Global Style
.management {
  font-family: SVN-Gilroy;
}

.page-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content-box {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
  }
}

.blue-header {
  .header {
    padding: 16px;
    padding-bottom: 0px;
    .top-bar {
      background-color: #142246;
      color: $color-white;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.driver-management,
.vehicle-management {
  .custom-tab {
    .tabs {
      font-weight: bold;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      li {
        a {
          font-size: 16px;
          line-height: 24px;
          color: #a2a9b0;
        }
        &.is-active {
          a {
            color: #21272a;
            border-bottom: 2px solid #3a86ff;
          }
        }
      }
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.driver-management {
  .inner {
    .wrapper {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

svg {
  path {
    .ic-white & {
      fill: $color-white;
    }

    .ic-black & {
      fill: #000;
    }

    .ic-orange & {
      fill: #ff8200;
    }
  }
}

.actions {
  padding: 8px 16px;
  background-color: $color-white;
  &.shadow {
    box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px -10px 11px 0px rgba(0, 0, 0, 0.15);
  }
}

.btn-float {
  width: 48px;
  height: 48px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  z-index: 4;
  cursor: pointer;
}

.menu-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.form-section {
  padding: 16px;
  &.align-left {
    text-align: left;
  }

  h6 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
</style>
