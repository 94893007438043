<template>
  <div class="container profile">
    <CustomTopBar detailBar="true">
      <span class="icon ic-white" @click="goBackMenu">
        <inline-svg :src="arrowIcon"
      /></span>
    </CustomTopBar>
    <MainProfile />
  </div>
</template>

<script>
import { ArrowIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import MainProfile from "@/pages/Profile/components/MainProfile/MainProfile";

export default {
  name: "Profile",
  components: { CustomTopBar, MainProfile },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  data() {
    return {
      arrowIcon: ArrowIcon,
    };
  },
  methods: {
    goBackMenu() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss">
$color-white: #ffffff;

.profile {
  font-family: SVN-Gilroy;
}

svg {
  path {
    .ic-white & {
      fill: $color-white;
    }
  }
}
</style>
