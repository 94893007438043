import _ from "lodash";
import client from "@/services/client";

export function parseCurrency(amount) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "VND",
  });
}

export function filterClient(query, originalData, propFilter) {
  const queryCase = query.toLowerCase();
  return _.filter(originalData, function (obj) {
    if (obj[propFilter]) {
      return obj[propFilter].toLowerCase().indexOf(queryCase) !== -1;
    }
  });
}

export function getFieldValuePhoto(array, field, query) {
  return _.find(array, function (o) {
    return o[field] === query;
  });
}

export function getFilterQuantity(array, params) {
  const value = _.filter(array, params);
  return value.length;
}

export async function uploadImage(token, body) {
  const bodyFormData = new FormData();
  bodyFormData.append("file", body.file);
  bodyFormData.append("file_type", body.file_type);

  const { data } = await client.appApiAhamove.post(
    "/assets/images/upload",
    bodyFormData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data.url;
}

export function convertPlateNumber(id, vehicle_type) {
  if (vehicle_type === "MOTORBIKE") {
    return id.replace(/^(.{4})(.{3})/, "$1-$2.");
  }

  return id.replace(/^(.{3})(.{3})/, "$1-$2.");
}

export const getAvatar = async (id) => {
  const url = `https://apistg.ahamove.com/v1/supplier/file?file_id=avatar&id=${id}&size=128`;
  const data = await checkImageExist(url);
  return data ? url : "";
};

export function replaceSpecial(string) {
  return string ? string.replace(/[^a-zA-Z0-9]/g, "") : string;
}

export function convertDriverService(string) {
  switch (string) {
    case "TMDT":
      return "Thương mại điện tử";
    case "HUB-SP":
      return "HUB theo điểm";
    case "2H-INTERNAL":
      return "2H";
    case "BIKE":
      return "Siêu tốc";
    case "POOL":
      return "Siêu rẻ";
    case "RENT_BIKE":
      return "Thuê tài xế cố định";

    default:
      return string;
  }
}

const checkImageExist = (url) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};
