const setLinkedVehicles = (state, payload) => {
  state.linkedVehicles = payload;
};
const setUnlinkedVehicles = (state, payload) => {
  state.unlinkedVehicles = payload;
};
const setProcessVehicles = (state, payload) => {
  state.processVehicles = payload;
};

const setVehicleDetail = (state, payload) => {
  state.vehicleDetail = { ...state.vehicleDetail, [payload._id]: payload };
};

const setAssignedDrivers = (state, payload) => {
  state.assignedDrivers = payload;
};
const setNoAssignedDrivers = (state, payload) => {
  state.noAssignedDrivers = payload;
};
const setVerifyDrivers = (state, payload) => {
  state.verifyingDrivers = payload;
};

const setDriverDetail = (state, payload) => {
  state.detail = { ...state.detail, [payload._id]: payload };
};

const setDriverServices = (state, payload) => {
  state.driverServices = {
    ...state.driverServices,
    [payload._id]: payload.services,
  };
};

export default {
  setLinkedVehicles,
  setUnlinkedVehicles,
  setProcessVehicles,
  setAssignedDrivers,
  setNoAssignedDrivers,
  setVerifyDrivers,
  setDriverDetail,
  setDriverServices,
  setVehicleDetail,
};
