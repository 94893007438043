<template>
  <b-loading :is-full-page="isFullPage" v-model="isLoading">
    <div class="loader-container">
      <b-icon
          icon="autorenew"
          size="is-large">
      </b-icon>
    </div>
  </b-loading>
</template>
<script>
export default {
  name: "Loader",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    isFullPage: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.loader-container {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
