<template>
  <div class="report-component section-box">
    <div class="text">
      <p class="name">{{ driver.name }}</p>
      <p class="plate">{{ driver.plate_number || "Chưa cập nhật"}}</p>
    </div>

    <BoardWrapper>
      <ScoreComponent label="Mức đánh giá" :value="4" star />
      <ScoreComponent
        label="Tỷ lệ chấp nhận"
        key-name="acceptance_rate"
        :value="parseFloat(0.7)"
        :tooltipData="getARMessage(0.7)"
      />
      <ScoreComponent
        label="Tỷ lệ hủy đơn"
        key-name="cancellation_rate"
        :value="parseFloat(0.2)"
        :tooltipData="getCRMessage(0.2)"
      />
    </BoardWrapper>

    <b-carousel-list
      class="chart-carousel no-shadow"
      v-model="carousel"
      :data="items"
      :items-to-show="1"
      :animated="animated"
      :autoplay="autoPlay"
      :arrow-hover="false"
      :repeat="repeat"
    >
      <template #item="list">
        <ChartItem :data="list" />
      </template>
    </b-carousel-list>

    <div class="bottom-text">ĐƠN THEO NGÀY</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, endOfWeek, getDay, addMonths } from "date-fns";
import vi from "date-fns/locale/vi";
import BoardWrapper from "@/common/components/Chart/BoardWrapper";
import ScoreComponent from "@/common/components/Chart/ScoreComponent";
import ChartItem from "@/common/components/Chart/ChartItem";
import { fetchAsyncDriverStats } from "@/pages/Profile/services/driverService";

export default {
  name: "ReportComponent",
  components: { BoardWrapper, ScoreComponent, ChartItem },
  computed: {
    ...mapGetters({
      detail: "managementStore/getDriverDetail",
    }),
  },
  async mounted() {
    const { driverId } = this.$route.params;
    const { supplier_token } = this.$route.query;
    this.driver = this.detail[driverId]
    if (driverId) {
      const data = await fetchAsyncDriverStats(supplier_token, driverId);
      this.items = this.mapData(data);
      this.carousel = data.length - 1;
    }
  },
  data() {
    return {
      driver: '',
      carousel: 0,
      animated: "slide",
      autoPlay: false,
      indicator: false,
      repeat: false,
      scoreData: "",
      items: [],
    };
  },
  methods: {
    mapData(data) {
      const array = data.slice().reverse();
      return array.map((item) => {
        const { period, total_fee, active_time } = item;
        const dateObj = new Date(period);
        const periodString = this.getPeriodTitle(dateObj);
        const timeString = this.getOnlineTime(active_time);

        let totalOrder = 0;
        const { daily_stats } = item;

        const chartData = daily_stats.map((chartItem) => {
          const chartDateObj = new Date(chartItem.period);
          const dateIndex = getDay(chartDateObj);
          const label = this.convertKeyDate(dateIndex);
          let order = chartItem.total_order;
          totalOrder += order;

          return {
            ...chartItem,
            label,
            dateIndex,
          };
        });

        return {
          ...item,
          periodString,
          totalOrder,
          totalFee: total_fee,
          active_time: timeString,
          chartData,
        };
      });
    },
    getPeriodTitle(dateObj) {
      const firstMonth = format(dateObj, "MMMM", { locale: vi });
      const firstDay = format(dateObj, "d");
      const nextMonthObj = addMonths(dateObj, 1);
      const nextMonth = format(nextMonthObj, "MMMM", { locale: vi });

      const lastDay = format(
        new Date(endOfWeek(dateObj, { weekStartsOn: 1 })),
        "d"
      );

      if (parseInt(firstDay) > parseInt(lastDay)) {
        return `Doanh thu ${firstDay} ${firstMonth} - ${lastDay} ${nextMonth}`;
      } else {
        return `Doanh thu ${firstDay} - ${lastDay} ${firstMonth}`;
      }
    },
    getOnlineTime(active_time) {
      if (active_time === 0) {
        return 0;
      } else if (active_time < 1) {
        const minute = active_time * 60;
        return `${minute}'`;
      } else {
        const minuteTime = active_time - Math.floor(active_time);
        const minuteRound = minuteTime.toFixed(1);
        const minute = minuteRound * 60;
        const hour = Math.round(active_time - minuteRound);
        return `${hour}h${minute}'`;
      }
    },
    getARMessage(score) {
      if (score < 0.9) {
        return "Cần cải thiện trên 90%";
      }

      return "Tốt";
    },
    getCRMessage(score) {
      if (score > 0.3) {
        return "Cần cải thiện dưới 30%";
      }

      return "Tốt";
    },
    convertKeyDate(index) {
      switch (index) {
        case 0:
          return "SU";
        case 1:
          return "MO";
        case 2:
          return "TU";
        case 3:
          return "WE";
        case 4:
          return "TH";
        case 5:
          return "FR";
        case 6:
          return "SA";
        default:
          return "";
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.name {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #121619;
  margin-bottom: 4px;
}
.plate {
  font-size: 16px;
  line-height: 24px;
  color: #4d5358;
  margin-bottom: 8px;
}

.section-box {
  padding: 16px;
}

.no-shadow {
  box-shadow: none;
}
</style>
