<template>
  <div>
    <div class="img-container">
      <img src="../../../assets/kamegomi/tip.png" alt="">
    </div>
    <div class="tip-title">
      Thu nhập còn lại sau khi đã trừ số tiền cần nộp về lại cho KameGomi
    </div>
    <button @click="close" class="close-button">Đã hiểu</button>
  </div>
</template>

<script>
export default {
  name: "Tip",
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
  img {
    width: 40%;
    height: auto;
  }
}
.close-button {
  background: #0F1011;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  padding: 15px;
  outline: none;
  border: none;
  margin-top: 15px;
}

.tip-title {
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  text-align: center;
}
</style>
