import { DefaultAvatar } from "@/common/icons";
import _ from "lodash";
import client from "@/services/client";
import { getAvatar } from "@/common/utils";

export const getOwnerProfile = async (token) => {
  const res = await client.appApiAhamove.get("/supplier/profile", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
};

export const getAssignedDrivers = async (token) => {
  const res = await client.appApiMetatruck.get("/supplier/favourites", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return _.filter(mapData(res.data), (o) => o.status === "BUSY");
};

export const getNoAssignedDrivers = async (token) => {
  const res = await client.appApiMetatruck.get("/supplier/favourites", {
    headers: { Authorization: `Bearer ${token}` },
  });

  const statusOrder = ["ONLINE", "OFFLINE"];
  const data = _.filter(mapData(res.data), (o) => o.status === "ONLINE" || o.status === "OFFLINE");

  const result = data.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

  return result;
};

export const getVerifyingDrivers = async (token, owner_id) => {
  const { data } = await client.appApiOnwheelUtility.get("metatruck/fleet/invite", {
    params: { owner_id },
    headers: { token },
  });

  const result = await Promise.all(
    data.map(async (item) => {
      const avatar = await getAvatar(item.supplier_id);
      const currentTime = new Date().getTime();

      const oneDay = 8.64e7;
      const timeLength = currentTime - item.time * 1000;
      const obj = {
        ...item,
        _id: item.supplier_id,
        invitation_id: item._id,
        name: item.supplier_name,
        invitation_status: item.status,
        avatar,
        isExpired: timeLength >= oneDay,
      };
      return obj;
    })
  );

  return _.filter(result, (o) => o.invitation_status !== 2 && !o.isExpired);
};

export const linkVehicle = async (token, body, plateNumber) => {
  return await client.appApiMetatruck.put(`/vehicle/${plateNumber}/link`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const unlinkVehicle = async (token, body, plateNumber) => {
  return await client.appApiMetatruck.put(`/vehicle/${plateNumber}/unlink`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const removeDriverFromFleet = async (token, body, ownerPhoneNumber) => {
  return await client.appApiMetatruck.put(`/supplier/${ownerPhoneNumber}/favourite/remove`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const checkPhoneNumber = async (token, phoneNumber) => {
  return await client.appApiMetatruck.get(`/supplier/${phoneNumber}/exist`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const inviteDriver = async (token, body) => {
  return await client.appApiOnwheelUtility.post("/metatruck/fleet/invite", body, { headers: { token } });
};

export const getStatusInvitation = async (invite_id) => {
  return await client.appApiOnwheelUtility.get("/metatruck/fleet/invite", {
    params: { invite_id },
  });
};

export const handleDriverAction = async (token, invite_id, action) => {
  return await client.appApiOnwheelUtility.put(
    `/metatruck/fleet/invite/${action}`,
    { invite_id },
    { headers: { token } }
  );
};

export const getChildDriverProfile = async (token, supplier_id) => {
  const res = await client.appApiAhaMovePrivate.get(`/supplier/childs/${supplier_id}/profile`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

const mapData = (data) => {
  return data.map((item) => {
    const { files } = item;
    const avatar =
      files?.avatar && Object.keys(files.avatar).length > 0
        ? files.avatar[Object.keys(files.avatar)[0]]
        : DefaultAvatar;
    return { ...item, avatar };
  });
};

export const addChildAccount = async (id, token) =>
  await client.appApiAhaMovePrivate.patch(
    `/supplier-fleet-owner/child-accounts/${id}`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );

export const activeChildAccount = async (id, otp, token) =>
  await client.appApiAhaMovePrivate.post(
    `/supplier-fleet-owner/child-accounts/${id}/activate`,
    { activation_code: otp },
    { headers: { Authorization: `Bearer ${token}` } }
  );

export const deleteChildAccount = async (id, token) =>
  await client.appApiAhaMovePrivate.delete(`/supplier-fleet-owner/child-accounts/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
