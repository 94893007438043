import actions from "./management/actions";
import getters from "@/store/management/getters";
import mutations from "@/store/management/mutations";

export const managementStore = {
  state: {
    linkedVehicles: [],
    unlinkedVehicles: [],
    processVehicles: [],
    assignedDrivers: [],
    noAssignedDrivers: [],
    verifyingDrivers: [],
    detail: {},
    driverServices: {},
    vehicleDetail: {},
  },
  getters,
  actions,
  mutations,
  namespaced: true,
};
