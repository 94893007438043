<template>
  <form class="driver-info-form">
    <div class="form-section align-left">
      <h6 class="form-section--title">Thông tin cá nhân</h6>

      <div class="display-item">
        <div class="label">Ngày tháng năm sinh</div>
        <div class="text">
          {{ detail.date_of_birth || "Chưa có thông tin" }}
        </div>
      </div>

      <div class="display-item">
        <div class="label">Giới tính</div>
        <div class="text">{{ detail.gender || "Chưa có thông tin" }}</div>
      </div>

      <div class="display-item">
        <div class="label">Nơi sinh</div>
        <div class="text">{{ detail.birth_place || "Chưa có thông tin" }}</div>
      </div>

      <div class="display-item">
        <div class="label">Tình trạng hôn nhân</div>
        <div class="text">
          {{ detail.marriage_status || "Chưa có thông tin" }}
        </div>
      </div>
    </div>

    <CustomDivider />

    <div class="form-section align-left">
      <h6 class="form-section--title">Thông tin CCCD</h6>

      <div class="display-item">
        <div class="label">Số CCCD</div>
        <div class="text">{{ detail.id_number || "Chưa có thông tin" }}</div>
      </div>

      <div class="display-item">
        <div class="label">Ngày cấp</div>
        <div class="text">
          {{ detail.id_issue_date || "Chưa có thông tin" }}
        </div>
      </div>

      <div class="display-item">
        <div class="label">Nơi cấp</div>
        <div class="text">
          {{ detail.id_issue_place || "Chưa có thông tin" }}
        </div>
      </div>
    </div>

    <CustomDivider />

    <div class="form-section align-left">
      <h6 class="form-section--title">Địa chỉ</h6>

      <div class="display-item">
        <div class="label">Địa chỉ tạm trú</div>
        <div class="text">
          {{ detail.current_address || "Chưa có thông tin" }}
        </div>
      </div>

      <div class="display-item">
        <div class="label">Địa chỉ thường trú</div>
        <div class="text">
          {{ detail.family_reg_address || "Chưa có thông tin" }}
        </div>
      </div>
    </div>

    <CustomDivider />

    <div class="form-section align-left">
      <h6 class="form-section--title">Hình ảnh</h6>

      <div class="display-item">
        <div class="label">CCCD</div>
        <div class="image-row" v-if="detail.id_urls">
          <img
            v-for="idURL in detail.id_urls"
            :key="idURL.type"
            :src="idURL.img_url"
            :alt="idURL.type"
          />
        </div>
        <div class="text" v-else>Chưa cập nhật thông tin</div>
      </div>

      <div class="display-item">
        <div class="label">Bằng lái xe</div>
        <div class="image-row" v-if="detail.license_urls">
          <img
            v-for="licenseURL in detail.license_urls"
            :key="licenseURL.type"
            :src="licenseURL.img_url"
            :alt="licenseURL.type"
          />
        </div>
        <div class="text" v-else>Chưa cập nhật thông tin</div>
      </div>

      <div class="display-item">
        <div class="label">Giấy khám sức khoẻ</div>
        <div class="image-row" v-if="detail.covid_19">
          <img
            v-for="covidURL in detail.covid_19"
            :key="covidURL.type"
            :src="covidURL.certificate_url"
            :alt="covidURL.type"
          />
        </div>
        <div class="text" v-else>Chưa cập nhật thông tin</div>
      </div>
    </div>
  </form>
</template>

<script>
import CustomDivider from "@/common/components/CustomDivider";

export default {
  name: "DriverInfoForm",
  components: { CustomDivider },
  props: ["detail"],
};
</script>

<style lang="scss">
.driver-info-form {
  .label {
    font-weight: 400;
    font-size: 14px;
  }
}

.full-length {
  .datepicker-masked {
    max-width: 100%;
    width: 100%;
  }
  .masked-text {
    text-align: left;
  }
}
</style>

.
<style lang="scss" scoped>
.display-item {
  width: 100%;
  margin-bottom: 8px;
  .label {
    font-weight: 500;
    font-size: 15px;
  }
  .text {
    font-size: 13px;
    padding: 10px 8px;
    border: 1px solid #95a5a6;
    border-radius: 6px;
    color: #95a5a6;
    font-weight: 500;
  }
}

.image-row {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-items: center;
  align-items: center;
  * {
    border-radius: 4px;
    width: 100%;
  }
}
</style>
