<template>
  <div class="profile-wrapper">
    <ProfileInfo :data="driverInfo" vertical />
    <Divider />
    <ul class="menu profile--menu">
      <MenuItem :icon="accountIcon" @menu-click="openItem('detail')">
        <span>Thông tin tài xế</span>
      </MenuItem>
      <MenuItem :icon="chartIcon" @menu-click="openItem('chart')">
        <span>Chi tiết doanh thu</span>
      </MenuItem>
    </ul>
    <b-modal v-model="detailState" has-modal-card full-screen :can-cancel="false">
      <DetailProfile :detail="driverInfo" />
    </b-modal>
    <b-modal v-model="chartState" has-modal-card full-screen :can-cancel="false">
      <ChartProfile :detail="driverInfo" :token="supplierToken" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { PersonIcon, ChartIcon } from "@/common/icons";
import MenuItem from "@/common/components/MenuItem";
import Divider from "@/common/components/CustomDivider";
import ProfileInfo from "@/pages/Profile/components/MainProfile/ProfileInfo";
import DetailProfile from "@/pages/Profile/components/DetailProfile/DetailProfile";
import ChartProfile from "@/pages/Profile/components/ChartProfile/ChartProfile";
import { fetchAsyncDriverInfo } from "@/pages/Profile/services/driverService";

export default {
  name: "ProfileContainer",
  components: { Divider, ProfileInfo, DetailProfile, ChartProfile, MenuItem },
  computed: {
    ...mapState(["token"]),
  },
  data() {
    return {
      accountIcon: PersonIcon,
      chartIcon: ChartIcon,
      driverInfo: "",
      detailState: false,
      chartState: false,
      supplierToken: "",
    };
  },
  async mounted() {
    console.log(JSON.stringify(this.$route.query));
    const { id } = this.$route.params;
    // this.supplierToken = this.token;
    this.supplierToken = this.$route.query.supplier_token;
    if (id) {
      const data = await fetchAsyncDriverInfo(this.supplierToken, id);
      const { photo, driver_vehicle_info } = data;
      let { driver_avatar } = data;
      let registration_certificate = [];
      let driver_license = [];
      let vehicle_registration = [];
      let vehicle_plate = [];
      let vehicle_photo = [];

      const object = _.keyBy(photo, "type");
      _.each(object, (val, key) => {
        if (key === "registration_certificate_front" || key === "registration_certificate_back") {
          registration_certificate = [...registration_certificate, val.img_url];
        }

        if (key === "driver_license_front" || key === "driver_license_back") {
          driver_license = [...driver_license, val.img_url];
        }

        if (key === "vehicle_registration") {
          vehicle_registration = [...vehicle_registration, val.img_url];
        }

        if (key === "vehicle_decals_1") {
          vehicle_plate = [...vehicle_plate, val.img_url];
        }

        if (key === "vehicle_photo") {
          vehicle_photo = [...vehicle_photo, val.img_url];
        }
      });

      this.driverInfo = {
        ...data,
        imageURL: driver_avatar,
        vehicle: {
          ...object.vehicle_photo,
          photo: vehicle_photo,
          text: driver_vehicle_info || "",
        },
        driver_license,
        registration_certificate,
        vehicle_registration,
        vehicle_plate,
      };
    }
  },
  methods: {
    openItem(path) {
      this[`${path}State`] = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  display: flex;
  padding: 16px;
  align-items: center;
  border-top: 2px solid #eef2f5;

  img {
    margin-right: 16px;
    max-width: 20px;
    max-height: 20px;
  }
}

.profile-wrapper {
  margin-top: 32px;
}
</style>
