<template>
  <div class="tab-wrapper">
    <VuePullRefresh :on-refresh="onRefresh" :config="config">
      <div class="inner">
        <DriverListItem
          v-for="(item, index) in list"
          :key="index"
          :data="item"
          @action-delete="openAlert"
        />
      </div>
    </VuePullRefresh>

    <b-modal class="alert-dialog" v-model="dialog" has-modal-card>
      <AlertDialog
        btnClass="error"
        title="Mời ra khỏi đội xe"
        :message="dialogMessage"
        cancelText="Huỷ"
        :confirmText="confirmText"
        :loading="dialogLoading"
        @confirm="deleteDriver"
        @cancel="closeAlert"
      />
    </b-modal>

    <CustomToast
      v-show="isShowToast"
      :type="type"
      :message="message"
      :position="position"
      @close-toast="isShowToast = false"
    />
  </div>
</template>

<script>
import AlertDialog from "@/common/components/AlertDialog";
import CustomToast from "@/common/components/CustomToast";
import { REFRESH_CONFIG } from "@/common/constants";
import DriverListItem from "@/pages/Management/components/DriverListItem";
import { removeDriverFromFleet } from "@/pages/Management/services/driver";
import VuePullRefresh from "vue-pull-refresh";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProcessDriverTab",
  components: {
    VuePullRefresh,
    AlertDialog,
    CustomToast,
    DriverListItem,
  },
  computed: {
    ...mapGetters({
      list: "managementStore/getVerifyingDrivers",
    }),
  },
  async mounted() {
    //TODO: init action api - get data from init state in store -- pass params format object
    const token = this.$route.query.supplier_token;
    const owner_id = this.$route.params.id;
    try {
      await this.getVerifyList({ token, owner_id });
    } catch (error) {
      this.type = "error";
      this.message = `Không thể lấy thông tin tài xế`;
      this.isShowToast = true;

      setTimeout(() => {
        this.message = ``;
        this.isShowToast = false;
      }, 2000);
    }
  },
  data() {
    return {
      config: REFRESH_CONFIG,
      confirmText: "Đồng ý",
      selectedDriver: "",
      dialog: false,
      dialogLoading: false,
      dialogMessage: "",
      isShowToast: false,
      type: "success",
      message: "",
      position: "bottom",
    };
  },
  methods: {
    ...mapActions({
      getVerifyList: "managementStore/fetchVerifyingDrivers",
    }),
    onRefresh() {
      const token = this.$route.query.supplier_token;
      const owner_id = this.$route.params.id;

      return new Promise((resolve) => {
        this.getVerifyList({ token, owner_id }).then(() => resolve());
      });
    },
    openAlert($data) {
      this.dialogMessage = `Bạn chắc chắn muốn mời tài xế ${$data.name} ra khỏi đội xe?`;
      this.selectedDriver = $data;
      this.dialog = true;
    },
    closeAlert() {
      this.dialog = false;
      this.dialogLoading = false;
      this.dialogMessage = "";
      this.selectedDriver = "";
    },
    async deleteDriver() {
      this.confirmText = "";
      this.dialogLoading = true;
      const owner_id = this.$route.params.id;
      const { supplier_token: token } = this.$route.query;

      try {
        await removeDriverFromFleet(
          token,
          { supplier_id: this.selectedDriver._id },
          owner_id
        );
        await this.getVerifyList({ token, owner_id });
        this.type = "success";
        this.message = `Tài xế ${this.selectedDriver.name} đã rời khỏi đội xe`;
        this.isShowToast = true;
      } catch (error) {
        this.type = "error";
        this.message = `Đã xảy ra lỗi, xin vui lòng thử lại!\n${error.response.status}: ${error.response.data.title}`;
        this.isShowToast = true;
      } finally {
        this.confirmText = "Đồng ý";
        this.closeAlert();

        setTimeout(() => {
          this.message = ``;
          this.isShowToast = false;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss"></style>
