<template>
  <div class="page-wrapper blue-header">
    <div class="header">
      <CustomTopBar>
        <span class="icon ic-white spin-180" @click="goBack">
          <inline-svg :src="arrowIcon"
        /></span>
        <p>Dịch vụ đã đăng ký</p>
      </CustomTopBar>
    </div>
    <section class="content-box">
      <ServiceListItem
        v-for="(service, index) in services"
        :key="index"
        :detail="service"
      />
    </section>
    <!-- <div class="actions shadow">
      <CustomButton
        className="full-width primary"
        label="Thêm dịch vụ"
        @clicked="openAddServiceMenu"
      />
    </div> -->

    <!-- <BottomModal :open="open" :fluid="true" @close="onCloseServiceMenu">
      <div class="bottom-menu">
        <div class="bottom-menu--head">
          <span class="icon" @click="onCloseServiceMenu">
            <inline-svg :src="closIcon"
          /></span>
          <p>Dịch vụ đã đăng ký</p>
        </div>
        <div
          class="bottom-menu--body"
          v-if="unassignServices && unassignServices.length > 0"
        >
          <ServiceListItem
            v-for="(service, index) in unassignServices"
            :key="index"
            :checkbox="true"
            :detail="service"
            @input="handleChange"
          />
        </div>

        <div class="actions shadow">
          <CustomButton
            className="full-width primary"
            :label="submitText"
            :loading="isLoading"
            :disabled="isLoading || selectedServices.length < 1"
            @clicked="onConfirm"
          />
        </div>
      </div>
    </BottomModal> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { ChevronIcon, CloseIcon } from "@/common/icons";
import { REFRESH_CONFIG } from "@/common/constants";
import CustomTopBar from "@/common/components/CustomTopBar";
import ServiceListItem from "@/pages/Management/components/ServiceListItem";

export default {
  name: "DriverService",
  components: {
    CustomTopBar,
    ServiceListItem,
  },
  computed: {
    ...mapGetters({
      driverServices: "managementStore/getDriverServices",
    }),
  },
  mounted() {
    const { driverId } = this.$route.params;
    if (driverId) {
      this.services = _.uniq(this.mapData(this.driverServices[driverId]));
    }
  },
  data() {
    return {
      arrowIcon: ChevronIcon,
      closIcon: CloseIcon,
      config: REFRESH_CONFIG,
      services: [],
      submitText: "Xác nhận",
      isLoading: false,
      open: false,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    mapData(data) {
      return data.map((item) => {
        const array = item.split("-");
        array.shift();
        let currResult = "";
        if (array.length > 1) {
          currResult = array.join("-");
        } else {
          currResult = array.toString();
        }

        return currResult;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-menu {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow: hidden;
  &--head {
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
    .icon {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--body {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }
}
</style>
