//VEHICLE GETTERS FUNCTION
const getLinkedVehicles = (state) => state.linkedVehicles;
const getUnlinkedVehicles = (state) => state.unlinkedVehicles;
const getProcessVehicles = (state) => state.processVehicles;
const getVehicleDetail = (state) => state.vehicleDetail;

//DRIVER GETTERS FUNCTION
const getAssignedDrivers = (state) => state.assignedDrivers;
const getNoAssignedDrivers = (state) => state.noAssignedDrivers;
const getVerifyingDrivers = (state) => state.verifyingDrivers;
const getDriverDetail = (state) => state.detail;
const getDriverServices = (state) => state.driverServices;

export default {
  getLinkedVehicles,
  getUnlinkedVehicles,
  getProcessVehicles,
  getAssignedDrivers,
  getNoAssignedDrivers,
  getVerifyingDrivers,
  getDriverDetail,
  getDriverServices,
  getVehicleDetail,
};
