<template>
  <div class="toast-bottom" :class="`toast-bottom_${type}`">
    <img v-if="type === 'success'" class="toast-bottom__image" src="@/assets/supplier-listing/success-icon.png" alt=""/>
    <img v-else class="toast-bottom__image" src="@/assets/supplier-listing/failed-icon.png" alt=""/>
    <div class="toast-bottom__text">{{ message }}</div>
  </div>
</template>

<script>

export default {
  name: "Toast",
  props: ["type", "message"],
  data() {
    return {
      img: {
        success: '@/assets/supplier-listing/success-icon.png',
        failed: '@/assets/supplier-listing/failed-icon.png'
      }
    };
  },
};
</script>

<style lang="scss">
.toast-bottom {
  position: fixed;
  bottom: 70px;

  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  &_success {
    background: #E8F9EF;
  }

  &_failed {
    background: #FFEBEA;
  }

  &__image {
    width: 24px;
    height: auto;
    margin: 12px 0 12px 12px;
  }

  &__text {
    margin: 12px 12px 12px 16px;

    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
}
</style>
