<template>
  <div class="form-wrapper">
    <div class="form-head">
      <b-checkbox
        class="custom-checkbox gps-checkbox-all"
        v-model="isCheckAll"
        :indeterminate="isIndeterminate"
        @input="handleCheckAll"
      >
        Chọn tất cả
      </b-checkbox>
    </div>
    <div class="form-body">
      <VehicleItem
        v-for="(item, index) in vehicles"
        :checkbox="true"
        :key="index"
        :detail="item"
        @input="handleCheckItem"
      />
    </div>
  </div>
</template>

<script>
import VehicleItem from "@/pages/Management/components/VehicleItem";
import { get3PLVehicles } from "@/pages/Management/services/vehicle";

export default {
  name: "SelectedForm",
  components: { VehicleItem },
  props: ["vendor"],
  async mounted() {
    if (this.vendor) {
      const response = await get3PLVehicles(
        this.$route.query.supplier_token,
        this.vendor
      );

      this.vehicles = this.mapData(response);
      this.handleCheckAll(true);
    }
  },
  data() {
    return {
      isCheckAll: false,
      isIndeterminate: false,
      vehicles: [],
      selected: [],
    };
  },
  methods: {
    mapData(data) {
      return data && data.map((item) => ({ ...item, checked: false }));
    },
    handleCheckItem($input) {
      if ($input.checked === true) {
        this.selected = [...this.selected, $input];
      }

      if ($input.checked === false) {
        this.selected = this.selected.filter(
          ({ plate_number }) => plate_number !== $input.plate_number
        );
      }

      this.selectAllHandler();
    },
    handleCheckAll(checked) {
      this.isCheckAll = checked;
      this.vehicles = this.vehicles.map((item) => ({ ...item, checked }));

      if (checked) {
        this.selected = this.vehicles;
      } else {
        this.selected = [];
      }

      this.$emit("selected", this.selected);
    },
    selectAllHandler() {
      const selectedLength = this.selected.length;
      const originalLength = this.vehicles.length;

      this.isIndeterminate =
        selectedLength < originalLength && selectedLength > 0;
      this.isCheckAll = this.selected.length === this.vehicles.length;

      this.$emit("selected", this.selected);
    },
  },
};
</script>

<style lang="scss">
$color-main: #1890ff;

.custom-checkbox {
  cursor: auto;
  &.b-checkbox.checkbox {
    input,
    .check {
      cursor: pointer;
    }
    &:hover {
      input[type="checkbox"]:not(:disabled) + .check {
        border-color: $color-main;
        box-shadow: none;
      }
    }
    input[type="checkbox"]:checked + .check {
      border-color: $color-main;
      background-color: $color-main;
      box-shadow: none;
    }
    input[type="checkbox"]:indeterminate + .check {
      background: none;
      position: relative;
      box-shadow: none;
      border-color: $color-main;
      &::before {
        content: "";
        background-color: $color-main;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 7px);
        height: calc(100% - 7px);
        transition: ease-out 0.2s;
      }
    }
  }
}

.gps-checkbox-all {
  .control-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #21272a;
  }
}
</style>

<style lang="scss" scoped>
.form-head {
  text-align: right;
}
</style>
