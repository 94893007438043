<template>
  <div class="bottom-modal" :class="{ active: open }">
    <div class="inner" v-show="open">
      <CustomTopBar :noShadow="true">
        <img :src="closeIcon" alt="close_icon" @click="$emit('close')" />
        <p>Hướng dẫn</p>
      </CustomTopBar>
      <div class="content">
        <div class="status">
          <h5>Trạng thái phương tiện</h5>
          <div class="row">
            <div class="item" v-for="elStatus in status" :key="elStatus.id">
              <img class="marker-icon" :src="elStatus.src" alt="truck_icon" />
              <span>{{ elStatus.name }}</span>
            </div>
          </div>
        </div>
        <div class="notice">
          <h5>Những điều cần lưu ý</h5>
          <div class="item" v-for="elNotice in notice" :key="elNotice.id">
            <img class="marker-icon" :src="elNotice.src" alt="icon" />
            <span>{{ elNotice.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/close-dark.png";
import BusyIcon from "@/assets/map/truck-busy.png";
import OnlineIcon from "@/assets/map/truck-online.png";
import OfflineIcon from "@/assets/map/truck-offline.png";
import RefreshLightIcon from "@/assets/map/refresh-light.png";
import ZoomIcon from "@/assets/map/zoom.png";
import CustomTopBar from "@/common/components/CustomTopBar";

export default {
  name: "BottomModal",
  components: {
    CustomTopBar,
  },
  props: ["open"],
  data() {
    return {
      closeIcon: CloseIcon,
      status: [
        { name: "Bận", src: BusyIcon, id: "BUSY" },
        { name: "Trực tuyến", src: OnlineIcon, id: "ONLINE" },
        { name: "Ngoại tuyến", src: OfflineIcon, id: "OFFLINE" },
      ],
      notice: [
        {
          id: "zoom",
          text: "Dùng hai ngón tay để phóng to/thu nhỏ bản đồ.",
          src: ZoomIcon,
        },
        {
          id: "refresh",
          text: "Nhấn biểu tượng để cập nhật lại vị trí và thông tin các xe khi kéo bản đồ.",
          src: RefreshLightIcon,
        },
      ],
    };
  },
  methods: {
    onCloseFocus() {
      console.log("on close");
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-modal {
  position: absolute;
  width: 100%;
  background: #fff;
  bottom: 0;
  height: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px;
  z-index: 2;
  opacity: 0;
  transition: ease-in-out 0.7s;
  &.active {
    opacity: 1;
    height: 60%;
    transition: ease-in-out 0.7s;
  }

  .top-bar {
    border-bottom: 1px solid #f2f2f2;
    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .content {
    text-align: left;
    .active & {
      display: block;
    }
    h5 {
      font-weight: 700;
      color: #c1c7cd;
      font-size: 14px;
      line-height: 22px;
    }
    .status {
      margin-bottom: 8px;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  span {
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    .notice & {
      margin-left: 16px;
    }
  }
}
</style>
