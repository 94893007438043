import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "./assets/fonts/stylesheet.css";
import { utils } from "@/utils";
import { isAndroid, isIOS } from "mobile-device-detect";
import eruda from "eruda";
import VueAnalytics from "vue-analytics";
import InlineSvg from "vue-inline-svg";
import VueQrcode from "@chenfengyuan/vue-qrcode";

if (process.env.NODE_ENV !== "production") {
  eruda.init();
}

Vue.use(VueAnalytics, {
  id: "UA-224882668-1",
  router,
});

Vue.use(Buefy);
Vue.component("inline-svg", InlineSvg);
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false;

Vue.prototype.$device = {
  isAndroid,
  isIOS,
};
Vue.mixin({
  mixins: [utils],
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
