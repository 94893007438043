import axios from "axios";

const appApiKamegomi = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE_ONWHEEL}/kamegomi/v1`,
  timeout: 30000,
});

const appApiUtility = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE_ONWHEEL}/utility`,
  timeout: 30000,
});

const appApiOnwheelUtility = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE_ONWHEEL_UTILITY}`,
  // baseURL: 'http://localhost:3000',
  timeout: 30000,
});

const appApiEPOnWheel = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE_ONWHEEL}`,
  timeout: 30000,
});

const appApiAhamove = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/api/v3/private`,
  timeout: 30000,
});

const appApiAhamoveV1 = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/v1`,
  timeout: 30000,
});

const appApiAhaMovePublic = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/api/v3/public`,
  timeout: 30000,
});

const appApiAhaMovePrivate = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/api/v3/private`,
  timeout: 30000,
});

const appApiAhaMovInternal = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/api/v3/internal`,
  timeout: 30000,
});

const appApiOnWheel = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/onwheel/v1`,
  timeout: 30000,
});

const appApiMetatruck = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE}/api/v1/private/meta-truck`,
  timeout: 30000,
});

const appApiEContract = axios.create({
  baseURL: `https://nf22nfynnk.execute-api.ap-southeast-1.amazonaws.com/production`,
  timeout: 30000,
});

const appApiAdmin = axios.create({
  baseURL: `${process.env.VUE_APP_AHAMOVE_ADMIN}/api/custom_action`,
  timeout: 30000,
});

export default {
  appApiKamegomi,
  appApiUtility,
  appApiAhamove,
  appApiAhamoveV1,
  appApiAhaMovePublic,
  appApiAhaMovePrivate,
  appApiAhaMovInternal,
  appApiOnWheel,
  appApiOnwheelUtility,
  appApiEPOnWheel,
  appApiMetatruck,
  appApiEContract,
  appApiAdmin,
};
