export const kamegomi = {
    state: {
        orderId: '',
        itemList: [],
        isLoadingItemList: false,
        imgData: {
            'PAPER': 'open-box',
            'PLASTIC': 'water-bottle',
            'PACKAGING': 'plastic-bag',
            'METAL': 'beam',
            'PAPER-CARTON': 'open-box',
            'PAPER-NEWSPAPER': 'magazine',
            'PAPER-SCRAP_PAPER': 'paper_scrap',
            'PAPER-WHITE_PAPER': 'paper',
            'PLASTIC-PLASTIC': 'water-bottle',
            'PLASTIC-LATEX': 'wash-basin',
            'PLASTIC-MICA': 'packaging',
            'METAL-IRON':'beam',
            'METAL-ALUMINUM':'aluminum',
            'METAL-COPPER':'copper-wire',
            'METAL-CAN_IRON':'cans',
            'METAL-CAN_ALUMINUM':'can',
            'METAL-INOX':'steel',
            'METAL-OTHER':'washing-machine',
            'PLASTIC-DIRTY-PLASTIC': 'dirty-plastic',
            'PLASTIC-CLEAN-PLASTIC': 'clean-plastic',
            'OTHER': 'other',
            'PLASTIC-UBC':'ubc',
            'PLASTIC-TRANSPARENTS': 'transparents',
            'PLASTIC-SOFT_PLASTIC': 'soft_plastic',
            'PLASTIC-UTENSILS': 'utensils',
            'PLASTIC-STYROFOAM': 'styrofoam'
        }
    },
    mutations: {
        setItemList(state, payload) {
            state.itemList = payload.itemList
        },
        setWeight(state, {id, weight, order_proof}) {
            let itemList = state.itemList
            for (let i in itemList) {
                if (itemList[i].id === id) {
                    // console.log({id, weight, order_proof});
                    itemList[i].weight = parseFloat(weight).toFixed(2)
                    itemList[i].order_proof = order_proof
                }
            }
            state.itemList = itemList
        },
        updateOrderId(state, {orderId}) {
            state.orderId = orderId
        },
        updateLoadingItemList(state, {status}) {
            state.isLoadingItemList = status
        }
    },
    getters: {
        totalValue: state => {
            let totalCash = 0
            let totalWeight = 0
            for (let i in state.itemList) {
                let weight = parseFloat(state.itemList[i].weight) ? parseFloat(state.itemList[i].weight) : 0
                let price_unit = parseFloat(state.itemList[i].price_unit) ? parseFloat(state.itemList[i].price_unit) : 0
                totalWeight += weight
                totalCash += weight * price_unit
            }
            return {
                totalCash, totalWeight: totalWeight.toFixed(2)
            }
        },
    },
    actions: {
        loadItemListFromApi(context, {value}) {
            context.commit('setItemList', {itemList: value})
        },
        updateWeight(context, {id, weight, order_proof}) {
            context.commit('setWeight', {id, weight, order_proof})
        },
        setOrderId(context, {orderId}) {
            context.commit('updateOrderId', {orderId})
        },
        setLoadingItemList(context, {status}) {
            context.commit('updateLoadingItemList', {status})
        }
    },
    namespaced: true
}
