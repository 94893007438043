<template>
  <div class="validate-form">
    <div class="title">{{ title }}</div>
    <div class="subtitle">
      {{ description }}
    </div>
    <form>
      <CustomInput
        name="value"
        :label="label"
        :value="value"
        :type="type || 'text'"
        @input="inputChange"
      />
    </form>
  </div>
</template>

<script>
import CustomInput from "@/common/components/CustomInput";

export default {
  name: "StepFormCheck",
  components: { CustomInput },
  props: ["title", "description", "label", "type"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    inputChange(input) {
      const { name, value } = input;
      this[name] = value;
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.validate-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  .subtitle {
    color: #b9c2cd;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  form {
    display: block;
    width: 100%;
  }
}
</style>
