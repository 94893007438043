<template>
  <div class="live-map--container" :class="{ overlay: open }">
    <div class="live-map--header">
      <div class="inner">
        <CustomTopBar>
          <img
            :src="arrowLeftWhite"
            alt="arrow_left_icon"
            @click="goBackMenu"
          />
          <p>Bản đồ trực tuyến</p>
        </CustomTopBar>
        <b-field>
          <b-autocomplete
            icon-pack="fas"
            icon="search"
            :data="data"
            placeholder="Tìm kiếm theo biển số xe"
            field="title"
            @typing="getAsyncData"
            @select="selectOption"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  {{ props.option.id }}
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <div class="actions">
          <div class="item guide" @click="showGuide">
            <p>Hướng dẫn</p>
            <img :src="alertIcon" alt="alert_icon" />
          </div>
          <div class="item">
            <button class="btn-refresh" @click="refreshMap">
              <img :src="refreshIcon" alt="refresh_icon" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <Map
      :markers="markers"
      :defaultCoors="defaultCoors"
      :selectionMarker="selectionMarker"
      @onClickMarker="handleOnClickMaker"
    />

    <BottomModal :open="open" @close="showGuide" />

    <VehicleInfoModal
      :open="isShowVehicleInfo"
      :vehicleData="vehicleData"
      @close="selectionMarker = {}"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import ArrowLeftWhiteIcon from "@/assets/arrow-left-icon-white.png";
import AlertIcon from "@/assets/reporting/alert-icon.png";
import RefreshIcon from "@/assets/refresh.png";
import CustomTopBar from "@/common/components/CustomTopBar";
import Map from "./components/Map";
import BottomModal from "./components/BottomModal";
import VehicleInfoModal from "./components/VehicleInfoModal";
import {mapState} from "vuex";
import {get} from "lodash";
import moment from "moment/moment";

const BASEURI = `${process.env.VUE_APP_AHAMOVE}/api/v1/private/meta-truck/3pl/`

export default {
  name: "LiveMap",
  components: {
    CustomTopBar,
    BottomModal,
    VehicleInfoModal,
    Map
  },
  computed: {
    ...mapState(['token'])
  },
  data() {
    return {
      arrowLeftWhite: ArrowLeftWhiteIcon,
      alertIcon: AlertIcon,
      refreshIcon: RefreshIcon,
      open: false,
      selectedCoordinates: [],
      currentCoordinates: [],

      data: [],

      markers: [],
      defaultCoors: [],
      selectionMarker: {},
      isShowVehicleInfo: false,
      vehicleData: {},
      isProcess: false,
    };
  },
  methods: {
    goBackMenu() {
      // this.$router.back();
    },
    onGetCurrentCoordinates($value) {
      // Return current coordinates
      this.currentCoordinates = $value;
    },
    onSearch(query) {
      console.log(query);
    },
    getDataCurrentLocation() {
      // Trigger API to get all vehicle in current coordinates
      this.selectedCoordinates = this.currentCoordinates;
    },
    showGuide() {
      this.open = !this.open;
    },
    selectOption(option) {
      this.selectionMarker = JSON.parse(JSON.stringify(Object.assign(option, {address: '...'})))
    },
    getAsyncData: _.debounce(function (name) {
      if (this.name !== name) {
        this.name = name;
        this.data = [];
      }

      if (!name.length) {
        this.data = [];
        return;
      }

      this.data = this.markers.filter((item) => item.id.includes(name))
    }, 500),
    transformMarkers(data) {
      return data.map((item) => {
        const vehicleId = get(item, '_id')
        const status = get(item, 'status') || ''
        const lat = get(item, 'location.lat')
        const lng = get(item, 'location.lng')
        const direction = get(item, 'direction') || '0'
        // try {
        //   direction = parseFloat(direction)
        //   if (direction > 180) direction -= 180
        //   else direction += 180
        // } catch (e) {
        //   console.log(`parse direction ${direction} - error: ${e}`)
        // }

        let vehicle = {
          id: vehicleId,
          plate: vehicleId,
          lngLat: [lng, lat],
          status: status.toLowerCase(),
          direction: direction,
        }

        const lastUpdated = get(item, 'updated_at') || ''
        if (lastUpdated && lastUpdated.length > 0) {
          try {
            vehicle['lastUpdated'] = moment(lastUpdated, "YYYY-MM-DDTHH:mm:ss+07:00").format('DD-MM-YYYY HH:mm:ss');
          } catch (e) {
            console.log(`parser lastUpdated ${lastUpdated} - error: ${e}`)
          }
        }

        return vehicle
      })
    },
    getVehiclesLocation() {
      const gpsProvider = this.$route.query.gps_provider || ''
      if (!gpsProvider || gpsProvider.length === 0) return

      this.isProcess = true
      const config = {
        headers: {
          Authorization: `Bearer ${this.token || this.$route.query.supplier_token}`
        }
      }

      axios
        .get(`${BASEURI}${gpsProvider}/vehicles/status`, config)
        .then((data) => {
          const vehicles = get(data, 'data') || []
          if (vehicles && vehicles.length > 0) {
            const markers = this.transformMarkers(vehicles)

            if (this.selectionMarker && Object.keys(this.selectionMarker).length > 0) {
              let newestMarker = markers.find((m) => m.id === get(this.selectionMarker, 'id') || '')
              let update = {
                lngLat: get(newestMarker, 'lngLat'),
                lastUpdated: get(newestMarker, 'lastUpdated')
              }
              let updateMarker = Object.assign(this.selectionMarker, update)
              this.selectionMarker = JSON.parse(JSON.stringify(updateMarker))
              this.getVehicleInfo()
            }

            if (this.defaultCoors && this.defaultCoors.length === 0) {
              const randPosition = Math.floor(Math.random() * markers.length)
              this.defaultCoors = get(markers[randPosition], 'lngLat') || []
            }

            this.markers = markers
          }
        })
        .catch((e) => {
          console.log('getVehiclesLocation - error: ', e)
        })
        .finally(() => {
          this.isProcess = false
        })
    },
    getVehicleInfo() {
      if (!this.selectionMarker || Object.keys(this.selectionMarker).length === 0) {
        return
      }
      this.isProcess = true
      const [lng, lat] = get(this.selectionMarker, 'lngLat') || []
      const currentAddress = get(this.vehicleData, 'address') || ''
      axios
        .get(`https://api.midvietnam.com/api/getGPS/${lat},${lng}`)
        .then((data) => {
          const address = get(data, 'data.data.0.address') || null
          if (address && address != currentAddress) {
            this.selectionMarker = JSON.parse(JSON.stringify(Object.assign(this.selectionMarker, {address: address})))
            this.$forceUpdate()
          }
        })
        .catch((e) => {
          console.log('get address - error: ', e)
        })
        .finally(() => {
          this.isProcess = false
        })
    },
    refreshMap() {
      this.selectionMarker = {}
    },
    handleOnClickMaker(marker) {
      this.selectionMarker = marker
      this.getVehicleInfo()
    }
  },
  mounted() {
    this.getVehiclesLocation()
    setInterval(() => {
      if (!this.isProcess) {
        this.getVehiclesLocation()
      } else {
        console.log('skip')
      }
    }, 15000)
  },
  watch: {
    selectionMarker(value) {
      if (value && Object.keys(value).length > 0) {
        let update = JSON.parse(JSON.stringify(value))
        this.isShowVehicleInfo = true
        if (!('address' in this.vehicleData)) {
          update['address'] = '...'
        }
        this.vehicleData = update
      } else {
        this.isShowVehicleInfo = false
        this.vehicleData = {}
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.live-map {
  &--container {
    font-family: SVN-Gilroy;
    transition: ease-in-out 0.7s;
    &.overlay {
      &:before {
        opacity: 0.2;
        height: 100%;
        transition: ease-in-out 0.7s;
        z-index: 1;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0;
      background-color: #000;
      opacity: 0;
      transition: ease-in-out 0.7s;
    }
  }

  &--header {
    .inner {
      padding: 16px;
      padding-bottom: 0px;
      .top-bar {
        background-color: #142246;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    display: flex;
    align-items: center;
    &.guide {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      img {
        margin-left: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.btn-refresh {
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
}

.media {
  text-align: left;
}
</style>
