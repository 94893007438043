import _ from "lodash";
import client from "@/services/client";

export const getLinkedVehicles = async (token) => {
  const res = await client.appApiMetatruck.get("vehicles", {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { data } = res;
  return _.filter(data, (o) => o.supplier_id && o.supplier_id !== "");
};

export const getUnlinkedVehicles = async (token) => {
  const res = await client.appApiMetatruck.get("vehicles", {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { data } = res;
  return _.filter(data, (o) => !o.supplier_id || o.supplier_id === "");
};

//TODO: Scale to Verifying Tab
export const getProcessVehicles = async (token) => {
  const res = await client.appApiMetatruck.get("vehicles", {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { data } = res;
  return _.filter(data, (o) => !o.verified_status);
};
//--------

export const getVehicleById = async (token, vehicleId) => {
  const res = await client.appApiMetatruck.get(`vehicle/${vehicleId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data;
};

export const updateVehicleById = async (token, vehicleId, body) => {
  return await client.appApiMetatruck.put(`vehicle/${vehicleId}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createVehicle = async (token, body) => {
  return await client.appApiMetatruck.post("vehicle", body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createMultiVehicles = async (token, body) => {
  return await client.appApiMetatruck.post("vehicles", body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const login3PLHouse = async (token, body) => {
  return await client.appApiMetatruck.post("3pl/login", body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const get3PLVehicles = async (token, vendor) => {
  const { data } = await client.appApiMetatruck.get(`3pl/${vendor}/vehicles`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

export const getVehicleTypes = async () => {
  return await client.appApiAhaMovePublic.get("master-data/vehicle-types");
};

export const checkVehicle = async (token, plate_number) => {
  return await client.appApiMetatruck.get(`vehicle/${plate_number}/exist`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const removeVehicle = async (token, plate_number) => {
  return client.appApiMetatruck.delete(`vehicle/${plate_number}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
