<template>
  <div class="uti-container">
    <div class="list-container">
      <div v-for="(uti, key) in utiList" :key="key" class="item-container">
        <div v-if="uti.iframe">
          <UtilityItem :itemInfo="uti" />
        </div>
        <div v-else>
          <router-link :to="uti.url">
            <UtilityItem :itemInfo="uti" />
          </router-link>
        </div>
      </div>
    </div>
    <Loader :is-loading="isLoading" />
    <div class="toast-container" v-show="isShowToast.enable">
      <ToastNew :type="isShowToast.type" :message="isShowToast.message" />
    </div>
  </div>
</template>

<script>
import ToastNew from "@/pages/SupplierListing/components/ListingNew/ToastNew.vue";
import dsBridge from "dsbridge";
import { mapActions, mapState } from "vuex";
import Loader from "../../../common/Loader";
import UtilityItem from "../components/UtilityItem";
import { getUti } from "../services";

export default {
  components: {
    ToastNew,
    UtilityItem,
    Loader,
  },
  data: () => ({
    isLoading: false,
    error_message: "",
  }),
  computed: {
    ...mapState({
      token: (state) => state.token,
      utiList: (state) => state.utilities.utiList,
      isShowToast: (state) => state.supplierList.show_toast,
    }),
  },
  methods: {
    ...mapActions("utilities", ["updateUtiList"]),
    ...mapActions(["actionUpdateToken"]),
    ...mapActions("supplierList", ["actionSetShowToast"]),
    async getUtilityList() {
      let token = this.token || this.$route.query.token;
      this.isLoading = true;

      if (!token) {
        try {
          const v = await new Promise((resolve) => {
            dsBridge.call("getToken", "getToken", (v) => resolve(v));
          });
          const data = JSON.parse(v);
          if (!this.token) await this.actionUpdateToken({ token: data.token });
          token = data.token;
          console.log("getUtilityList-token", token);
        } catch {
          this.isLoading = false;
          return;
        }
      } else await this.actionUpdateToken({ token });

      try {
        if (this.utiList.length > 0) return;
        const isWeb = this.$route.query.is_web || false;
        let data = await getUti(token, isWeb);

        data = data.map((feat) => {
          if (feat.url.includes("onwheel.io")) {
            feat["iframe"] = true;
          }
          return feat;
        });

        await this.updateUtiList({ value: data });
      } catch (error) {
        this.error_message = `Bạn không có quyền sử dụng tính năng này. Vui lòng liên hệ lại Ahamove để hỗ trợ!`;
        await this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });
        console.error("Error fetching utility list:", error.response.data);
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    await this.getToken();
  },
  async mounted() {
    await this.getUtilityList();
  },
};
</script>

<style lang="scss" scoped>
.uti-container {
  width: 100%;

  .list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    align-items: flex-start;

    .item-container {
      width: 48%;
      margin-bottom: 15px;
    }
  }
}
</style>
