<template>
  <div class="item-list-wrapper">
    <div class="list-title">
      Thông tin loại phế liệu
    </div>
    <div class="list-sub-title">
      Chọn loại phế liệu bạn muốn thêm vào
    </div>
    <div class="item-list-container columns is-multiline is-mobile is-gapless">
      <template v-if="!isLoadingItemList">
        <template v-if="itemList.length > 0">
          <div v-for="(item, i) in itemList" :key="item.id" class="column is-half">
            <ScrapItem :noBorderBottom="getBorderBottomFlag(i)" @update-scrap="toggleModifyModal"
                       :name="item.name"
                       :price_unit="item.price_unit"
                       :img="item.img"
                       :weight="item.weight"
                       :item-id="item.id"
                       :unitType="item.unit_type"
                       :order_proof="item.order_proof"
            />
          </div>
        </template>
        <div v-else>
          <div class="emty-list">
            Không tìm thấy danh sách vật liệu*
          </div>
        </div>
      </template>
    </div>
    <div v-if="totalValue.totalWeight > 0" class="footer-button">
      <button class="button-review" @click="toConfirm">
        <div>Xem đơn hàng</div>
        <div>{{ totalValue.totalWeight.toLocaleString() }}kg</div>
        <div v-if="totalValue.totalCash">{{ totalValue.totalCash.toLocaleString() }}đ</div>
      </button>
    </div>
    <Loader :is-loading="isLoadingItemList"/>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import ScrapItem from "../components/ScrapItem";
import Loader from "../../../common/Loader";


export default {
  components: {
    ScrapItem,
    Loader
  },
  computed: {
    ...mapState({
      itemList: state => state.kamegomi.itemList,
      isLoadingItemList: state => state.kamegomi.isLoadingItemList
    }),
    ...mapGetters('kamegomi', [
      'totalValue'
    ])
  },
  methods: {
    toggleModifyModal(itemData) {
      // console.log(`toggled modal: itemData=${JSON.stringify(itemData)}`);
      this.$emit('open-modify', itemData)
    },
    getBorderBottomFlag(index) {
      let listLength = this.itemList.length
      if (listLength % 2 === 0) {
        return (index === listLength - 1) || (index === listLength - 2)
      }
      return (index === listLength - 1)
    },
    toConfirm() {
      this.$router.push({
        name: "Confirm"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-list-wrapper {
  background: linear-gradient(183.61deg, rgba(4, 126, 255, 0.1) 5%, rgba(252, 252, 252, 0) 70%);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10vh;

  .list-title {
    margin-top: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .list-sub-title {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .item-list-container {
    width: 100%;

    .item-name {
      font-weight: 600;
      font-size: 18px;
    }

    .item-price {
      font-size: 12px;
    }

    .emty-list {
      margin-left: 10px;
      font-style: italic;
    }
  }

  .footer-button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 10px;

    .button-review {
      background: #FF8200;
      border-radius: 2px;
      width: 100%;
      margin: 10px 0;
      padding: 15px;
      border: none;
      color: white;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
