import { render, staticRenderFns } from "./VehicleItem.vue?vue&type=template&id=88f8f0c6&scoped=true&"
import script from "./VehicleItem.vue?vue&type=script&lang=js&"
export * from "./VehicleItem.vue?vue&type=script&lang=js&"
import style0 from "./VehicleItem.vue?vue&type=style&index=0&id=88f8f0c6&prod&lang=scss&"
import style1 from "./VehicleItem.vue?vue&type=style&index=1&id=88f8f0c6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f8f0c6",
  null
  
)

export default component.exports