import client from "@/services/client";

export const makePaymentRequest = async (token) => {
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  const res = await client.appApiOnwheelUtility.post(`/v1/payment/requests`, {}, headers);
  return res.data;
};

export const makePaymentRequestSync = async (token) => {
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  return client.appApiOnwheelUtility.post(`/v1/payment/requests`, {}, headers);
};

export const fetchTransactions = async (token) => {
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  const res = await client.appApiOnwheelUtility.get(`/v1/payment/transactions`, headers);
  return res.data;
};
