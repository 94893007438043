<template>
  <div class="house-wrapper bigc-main">
    <div class="header">
      <div class="name">{{ `Tx. ${detail.supplier_name}` || "-" }}</div>
      <div class="plate">{{ detail.plate_number || "-" }}</div>
    </div>
    <div class="chart" v-if="items.length > 0">
      <b-carousel-list
        v-if="items"
        class="chart-carousel"
        v-model="carousel"
        :data="items"
        :items-to-show="1"
        :animated="animated"
        :autoplay="autoPlay"
        :arrow-hover="false"
        :repeat="repeat"
      >
        <template #item="list">
          <ChartDisplayItem :data="list" />
        </template>
      </b-carousel-list>

      <div class="bottom-text">ĐƠN THEO NGÀY</div>
    </div>

    <div class="loading-image" v-if="items.length < 1">
      <img :src="emptyImage" alt="empty_icon" />
      <div class="text">Hiện chưa có chi tiết thống kê</div>
    </div>
  </div>
</template>

<script>
import { format, endOfWeek, getDay, addMonths } from "date-fns";
import vi from "date-fns/locale/vi";
import EmptyImage from "@/assets/supplier-listing/search-empty.png";
import ChartDisplayItem from "@/houses/bigc/components/ChartDisplayItem";
import { fetchBigCDriverStats } from "@/houses/bigc/services";
export default {
  name: "BigCMain",
  components: {
    ChartDisplayItem,
  },
  data() {
    return {
      carousel: 0,
      animated: "slide",
      autoPlay: false,
      indicator: false,
      repeat: false,
      items: [],
      detail: {
        supplier_name: "",
      },
      emptyImage: EmptyImage,
    };
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  async mounted() {
    const { id } = this.$route.params;
    const { token } = this.$route.query;
    if (id && token) {
      const dataInfo = await fetchBigCDriverStats(token, id);
      this.detail = dataInfo.info;
      const dataColumn = dataInfo.stats;
      this.items = this.mapData(dataColumn);
      this.carousel = dataColumn.length - 1;
    }
  },
  methods: {
    mapData(data) {
      const array = data.slice().reverse();
      return array.map((item) => {
        const { period, total_fee, active_time } = item;
        const dateObj = new Date(period);
        const periodString = this.getPeriodTitle(dateObj);
        const timeString = this.getOnlineTime(active_time);

        let totalOrder = 0;
        const { daily_stats } = item;

        const chartData = daily_stats.map((chartItem) => {
          const chartDateObj = new Date(chartItem.period);
          const dateIndex = getDay(chartDateObj);
          const label = this.convertKeyDate(dateIndex);
          let order = chartItem.total_order;
          totalOrder += order;

          return {
            ...chartItem,
            label,
            dateIndex,
          };
        });

        return {
          ...item,
          periodString,
          totalOrder,
          totalFee: total_fee,
          active_time: timeString,
          chartData,
        };
      });
    },
    convertKeyDate(index) {
      switch (index) {
        case 0:
          return "SU";
        case 1:
          return "MO";
        case 2:
          return "TU";
        case 3:
          return "WE";
        case 4:
          return "TH";
        case 5:
          return "FR";
        case 6:
          return "SA";
        default:
          return "";
      }
    },
    getPeriodTitle(dateObj) {
      const firstMonth = format(dateObj, "MMMM", { locale: vi });
      const firstDay = format(dateObj, "d");
      const nextMonthObj = addMonths(dateObj, 1);
      const nextMonth = format(nextMonthObj, "MMMM", { locale: vi });

      const lastDay = format(
        new Date(endOfWeek(dateObj, { weekStartsOn: 1 })),
        "d"
      );

      if (parseInt(firstDay) > parseInt(lastDay)) {
        return `Doanh thu ${firstDay} ${firstMonth} - ${lastDay} ${nextMonth}`;
      } else {
        return `Doanh thu ${firstDay} - ${lastDay} ${firstMonth}`;
      }
    },
    getOnlineTime(active_time) {
      if (active_time === 0) {
        return 0;
      } else if (active_time < 1) {
        const minute = active_time * 60;
        return `${minute}'`;
      } else {
        const minuteTime = active_time - Math.floor(active_time);
        const minuteRound = minuteTime.toFixed(1);
        const minute = minuteRound * 60;
        const hour = Math.round(active_time - minuteRound);
        return `${hour}h${minute}'`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.house-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  padding-bottom: 22px;
  .header {
    margin-bottom: 60px;
  }
}

.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #121619;
  margin-bottom: 4px;
}
.plate {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4d5358;
}

.bottom-text {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #a2a9b0;
  margin-top: 4px;
}

.loading-image {
  img {
    max-width: 50%;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
// CUSTOM CHART CAROUSEL
.chart-carousel {
  box-shadow: none !important;
  .carousel-arrow {
    .icon {
      top: 30px;
      color: #7a8189;
      &:hover {
        border: none;
      }
      i:before {
        font-size: 48px;
      }
    }
    .has-icons {
      &-right {
        right: 16px;
      }
      &-left {
        left: 16px;
      }
    }
  }
}
</style>
