<template>
  <div class="container stock page-wrapper">
    <div class="header blue-header">
      <CustomTopBar :noShadow="true">
        <span class="icon ic-white spin-180" @click="goBackMenu">
          <inline-svg :src="closeIcon"
        /></span>
        <p>Xác nhận đơn hàng</p>
      </CustomTopBar>
    </div>
    <div class="content-box bg-blue">
      <VuePullRefresh :on-refresh="onRefresh" :config="config">
        <div class="card stock-card">
          <div class="heading">
            Vui lòng thông báo cho nhân viên kho thao tác xử lý
          </div>
          <div class="stock-load">
            <div class="label">Đến Kho/CH</div>
            <button
              class="btn btn-load circle"
              v-if="!isArrive"
              @click="onTruckArrive"
              :disabled="isSpinArrive"
            >
              <span v-if="!isSpinArrive" class="icon">
                <inline-svg :src="startIcon"
              /></span>
              <div v-if="isSpinArrive" class="loading-icon"></div>
              <span class="text">Đến nơi</span>
            </button>
            <StockMessage v-if="isArrive">
              Đã đến nơi vào lúc
              <span class="time">{{ supplier_arrival_time }}</span>
            </StockMessage>
          </div>

          <div class="stock-load">
            <div class="label">Load/Unload hàng lên/xuống xe</div>
            <button
              class="btn btn-load circle"
              v-if="!isTruckLoadedOrder"
              @click="onTruckLoaded"
              :disabled="isSpinTruckLoaded || !isArrive"
            >
              <span v-if="!isSpinTruckLoaded" class="icon">
                <inline-svg :src="startIcon"
              /></span>
              <div v-if="isSpinTruckLoaded" class="loading-icon"></div>
              <span class="text">Bắt đầu</span>
            </button>
            <StockMessage v-if="isTruckLoadedOrder">
              Đã bắt đầu load/unload đơn hàng lúc
              <span class="time">{{ loading_start_time }}</span>
            </StockMessage>
          </div>

          <div class="stock-load">
            <div class="label">Hoàn tất load/unload hàng</div>
            <button
              class="btn btn-load circle"
              v-if="!isTruckFinishedLoaded"
              @click="onTruckFinishedLoaded"
              :disabled="!isTruckLoadedOrder || isSpinTruckFinished"
            >
              <span v-if="!isSpinTruckFinished" class="icon">
                <img :src="doubleCheckIcon"
              /></span>
              <div v-if="isSpinTruckFinished" class="loading-icon"></div>
              <span class="text">HOÀN THÀNH</span>
            </button>
            <StockMessage v-if="isTruckFinishedLoaded">
              Load/Unload hàng thành công lúc
              <span class="time">{{ loading_complete_time }}</span>
            </StockMessage>
          </div>

          <div class="stock-load">
            <div class="label">Xác nhận số seal</div>
            <input
              class="field-input"
              v-model="seal_number"
              :disabled="isOrderCompleted || !isTruckFinishedLoaded"
            />
            <div class="label">Ghi chú</div>
            <input
              class="field-input"
              v-model="load_item_note"
              :disabled="isOrderCompleted || !isTruckFinishedLoaded"
            />
            <CustomButton
              class="btn btn-orange"
              label="Hoàn tất"
              :loading="isSpinComplete"
              :disabled="
                isOrderCompleted ||
                isSpinComplete ||
                !seal_number ||
                !isTruckFinishedLoaded
              "
              @clicked="onCompleteOrder"
            >
            </CustomButton>
          </div>
        </div>
      </VuePullRefresh>
    </div>
  </div>
</template>

<script>
import VuePullRefresh from "vue-pull-refresh";
import { format, getUnixTime } from "date-fns";
import { CloseIcon, DoubleCheckIcon, StartIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import StockMessage from "@/pages/Stock/StockMessage";
import { fetchLoadingInfo, updateLoadingInfo } from "./services";
import { mapState } from "vuex";
import { REFRESH_CONFIG } from "@/common/constants";
export default {
  name: "Stock",
  components: {
    CustomTopBar,
    CustomButton,
    StockMessage,
    VuePullRefresh,
  },
  computed: {
    ...mapState({
      ahaOrderId: (state) => state.ahaOrderId,
    }),
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  mounted() {
    this.onFetchLoadingInfo();
  },
  data() {
    return {
      config: REFRESH_CONFIG,
      stockId: "",
      pathIndex: "",
      closeIcon: CloseIcon,
      startIcon: StartIcon,
      doubleCheckIcon: DoubleCheckIcon,
      loading_complete_time: "",
      loading_start_time: "",
      load_item_note: "",
      seal_number: "",
      supplier_arrival_time: "",
      isSpinTruckLoaded: false,
      isTruckLoadedOrder: false,
      isSpinTruckFinished: false,
      isTruckFinishedLoaded: false,
      isOrderCompleted: false,
      isSpinComplete: false,
      isArrive: false,
      isSpinArrive: false,
    };
  },
  methods: {
    goBackMenu() {
      // TODO Back to Utilities
      this.postMessage({ name: this.EVENT_BACK });
    },
    onRefresh() {
      return new Promise((resolve) => {
        this.onFetchLoadingInfo().then(() => resolve());
      });
    },
    async onFetchLoadingInfo() {
      this.pathIndex = this.$route.query.pathIndex;
      if (this.ahaOrderId) {
        this.stockId = this.ahaOrderId;
      } else {
        const { orderId: id } = this.$route.query;
        this.stockId = id;
      }

      const params = {
        orderId: this.stockId,
        pathIndex: this.pathIndex,
      };

      try {
        const response = await fetchLoadingInfo(params);
        const {
          loading_complete_time,
          loading_start_time,
          seal_number,
          load_item_note,
          supplier_arrival_time,
        } = response;
        this.isTruckLoadedOrder = !!loading_start_time;
        this.isTruckFinishedLoaded = !!loading_complete_time;
        this.isOrderCompleted = !!seal_number;
        this.isArrive = !!supplier_arrival_time;
        this.seal_number = seal_number;
        this.load_item_note = load_item_note;
        this.loading_complete_time = format(
          new Date(loading_complete_time * 1000),
          "HH:mm"
        );
        this.loading_start_time = format(
          new Date(loading_start_time * 1000),
          "HH:mm"
        );
        this.supplier_arrival_time = format(
          new Date(supplier_arrival_time * 1000),
          "HH:mm"
        );
      } catch (error) {
        console.log(error);
      }
    },
    async onTruckLoaded() {
      this.isSpinTruckLoaded = true;
      const isCheckAt = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        loading_start_time: isCheckAt,
        pathIndex: this.pathIndex,
      };
      try {
        await updateLoadingInfo(payload);
        this.isSpinTruckLoaded = false;
        this.isTruckLoadedOrder = true;
        this.loading_start_time = format(new Date(isCheckAt * 1000), "HH:mm");
      } catch (error) {
        console.log(error);
      }
    },
    async onTruckFinishedLoaded() {
      this.isSpinTruckFinished = true;
      const isCheckAt = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        loading_complete_time: isCheckAt,
        pathIndex: this.pathIndex,
      };
      try {
        await updateLoadingInfo(payload);
        this.isSpinTruckFinished = false;
        this.isTruckFinishedLoaded = true;
        this.loading_complete_time = format(
          new Date(isCheckAt * 1000),
          "HH:mm"
        );
      } catch (error) {
        console.log(error);
      }
    },
    async onCompleteOrder() {
      this.isSpinComplete = true;
      const payload = {
        orderId: this.stockId,
        seal_number: this.seal_number,
        load_item_note: this.load_item_note,
        pathIndex: this.pathIndex,
      };
      try {
        await updateLoadingInfo(payload);
        this.isSpinComplete = false;
        this.isOrderCompleted = true;
      } catch (error) {
        console.log(error);
      }
    },
    async onTruckArrive() {
      this.isSpinArrive = true;
      const isArriveAt = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        pathIndex: this.pathIndex,
        supplier_arrival_time: isArriveAt,
      };
      try {
        await updateLoadingInfo(payload);
        this.isSpinArrive = false;
        this.isArrive = true;
        this.supplier_arrival_time = format(
          new Date(isArriveAt * 1000),
          "HH:mm"
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.stock {
  font-family: SVN-Gilroy;
}

.bg-blue {
  background-color: #142246;
}

.header {
  &.blue-header {
    padding: 16px;
    padding-bottom: 0px;
    .top-bar {
      background-color: #142246;
      color: #ffffff;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

svg {
  path {
    .ic-white & {
      fill: #ffffff;
    }
  }
}

.page-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content-box {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
    text-align: left;
  }
}
</style>

<style lang="scss" scoped>
.stock-card {
  margin: 24px;
  padding: 16px;
}

.heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  text-align: center;
  margin-bottom: 12px;
}

.label {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}

.circle {
  border-radius: 50px;
}

.btn-load {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  gap: 4px;
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
}

.field-input {
  border: 1px solid #e5e7e8;
  border-radius: 3px;
  padding: 8px;
  width: 100%;
  min-height: 50px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
}

.btn-orange {
  background-color: #ff8200;
  width: 100%;
  color: #fff;
}
.time {
  color: #007cff;
}

.loading-icon {
  &::after {
    content: "";
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    display: block;
    width: 24px;
    height: 24px;
    border-width: 0.25em;
  }
}
</style>
