<template>
  <div class="analytic__display">
    <div class="analytic__display--income">
      <img class="background" :src="background" alt="background" />
      <div class="label">Tổng doanh thu</div>
      <div class="value">{{ parseCurrency(income) }}</div>
    </div>
    <div class="analytic__display--group">
      <div class="background-item">
        <img :src="truckIcon" alt="truck_icon" />
        <div class="value">
          <p>Số phương tiện</p>
          <p>{{ vehicle }}</p>
        </div>
      </div>
      <div class="background-item">
        <img :src="fileIcon" alt="file_icon" />
        <div class="value">
          <p>Tổng số đơn</p>
          <p>{{ orders }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/assets/reporting/card.jpg";
import TruckIcon from "@/assets/reporting/truck-icon.svg";
import FileIcon from "@/assets/reporting/file-icon.svg";
import { parseCurrency } from "@/common/utils";

export default {
  name: "Board",
  props: {
    income: {
      type: Number,
      default: 0,
    },
    vehicle: {
      type: Number,
      default: 0,
    },
    orders: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      background: Background,
      truckIcon: TruckIcon,
      fileIcon: FileIcon,
    };
  },
  methods: {
    parseCurrency,
  },
};
</script>

<style lang="scss">
.analytic__display {
  margin-top: 16px;
  &--income {
    position: relative;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    .background {
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    .label {
      color: #b1b6d3;
      font-size: 12px;
      line-height: 20px;
    }
    .value {
      color: #fff;
      font-size: 30px;
      line-height: 38px;
    }
  }
  &--group {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
      text-align: left;
      p:first-child {
        font-size: 12px;
        line-height: 20px;
        color: #b1b6d3;
      }
      p:last-child {
        color: #fff;
      }
    }
    .background-item {
      display: flex;
      align-items: center;
      background: #101a3c;
      border-radius: 8px;
      padding: 16px;
      img {
        margin-right: 12px;
      }
      @media screen and (min-width: 367px) {
        width: 48%;
      }
    }
  }
}
</style>
