<template>
  <div class="modal-box" ref="modalBox">
    <div class="modal-header">{{ headerText }}</div>
    <div class="modal-body">
      {{ bodyText }}
    </div>
    <div class="modal-footer">
      <div :class="['btn-cancel', 'btn-' + cancelType]">
        <div :class="['btn-cancel-text', 'btn-' + cancelType + '-text']" @click="$emit('cancel-click')">
          {{ cancelText }}
        </div>
      </div>
      <div :class="['btn-confirm', 'btn-' + confirmType]" v-show="confirmText || confirmText !== ''">
        <div :class="['btn-confirm-text', 'btn-' + confirmType + '-text']" @click="$emit('confirm-click')">
          {{ confirmText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirm",
  props: {
    headerText: String,
    bodyText: String,
    cancelType: { type: String, default: "cancel" },
    confirmType: { type: String, default: "confirm" },
    cancelText: { type: String, default: "Quay lại" },
    confirmText: String,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    // document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    // document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    /*handleClickOutside(event) {
      if (!this.$refs.modalBox.contains(event.target)) {
        this.$emit("close-modal");
      }
    },*/
  },
};
</script>

<style lang="scss" scoped>
.modal-box {
  font-family: "SVN-Gilroy";

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 330px;
  height: auto;
  padding: 16px;
  z-index: 20;

  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    &-header,
    &-body {
      text-align: left;
      width: 308px;
      line-height: 1.5;
      word-wrap: break-word;
    }

    &-header {
      color: #1a1a1a;
      font-size: 18px;
      font-weight: 700;
    }

    &-body {
      color: #697077;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 12px;

    .btn {
      &-cancel {
        width: 100%;
        height: 100%;
        padding: 14px 16px;
        background: #e6e9ec;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: inline-flex;

        &-text {
          flex: 1 1 0;
          text-align: center;
          color: #072553;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

      &-dismiss {
        width: 100%;
        height: 100%;
        padding: 14px 16px;
        background: #3a86ff;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: inline-flex;

        &-text {
          flex: 1 1 0;
          text-align: center;
          color: #ffffff;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

      &-confirm {
        width: 100%;
        height: 100%;
        padding: 14px 16px;
        background: #3a86ff;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: inline-flex;

        &-text {
          flex: 1 1 0;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          word-wrap: break-word;
        }
      }

      &-danger {
        width: 100%;
        height: 100%;
        padding: 14px 16px;
        background: #f5222d;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: inline-flex;

        &-text {
          flex: 1 1 0;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
