<template>
  <div>
    <div v-if="!assign_error">
      <Header/>
      <Table :tableData="suppliers" :isLoading="isLoadingSupplier"/>
    </div>
    <Modal v-else v-show="true" no-padding>
      <PopupModal :message="error_message" :type="'error'"/>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/vi";
import Header from "@/pages/SupplierListing/components/Listing/Header";
import Table from "@/pages/SupplierListing/components/Listing/Table";
import {
  getAdminToken,
  getFavoriteSuppliersInfo,
  getSupplierInfo
} from "@/pages/SupplierListing/services/supplierService";
import { mapActions, mapState } from "vuex";
import { getOrderInfo } from "@/pages/SupplierListing/services/orderService";
import PopupModal from "@/pages/SupplierListing/components/Listing/PopupModal";
import Modal from "../../common/components/Modal";

moment.locale("vi");
export default {
  name: "SupplierListing",
  components: {
    PopupModal,
    Header,
    Table,
    Modal
  },
  data: () => ({
    suppliers: [],
    assign_error: false,
    error_message: "",
    isLoadingSupplier: false
  }),
  computed: {
    ...mapState(["token", "ahaOrderId"])
  },
  methods: {
    ...mapActions("supplierList",
      [
        "actionSetOrderId",
        "actionSetOrderService",
        "actionSetMasterSupplierId",
        "actionSetUserId",
        "actionSetSupplierToken",
        "actionSetAdminToken"
      ]
    )
  },
  created() {
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR
    });
  },
  mounted() {
    this.isLoadingSupplier = true;
    const supplier_token = this.token;
    const order_id = this.ahaOrderId;
    // const supplier_token = this.$route.query.supplier_token;
    // const order_id = this.$route.query.order_id;
    this.actionSetSupplierToken({ value: supplier_token });
    this.actionSetOrderId({ value: order_id });
    let admin_token = "";
    getAdminToken(supplier_token)
      .then((token) => {
        admin_token = token;
        this.actionSetAdminToken({ value: admin_token });
        let order_info = "";
        getOrderInfo(admin_token, order_id)
          .then((info) => {
            order_info = info;
            this.actionSetUserId({ value: info.user_id });
            this.actionSetOrderService({ value: info.service_id });
            // console.log({ order_info });
            if (["IDLE", "ASSIGNING"].indexOf(order_info.status) === -1) {
              this.assign_error = true;
              this.error_message = `Đơn hàng không thể gán. Đã có tài xế chấp nhận.`;
              this.isLoadingSupplier = false;
              setTimeout(() => {
                this.postMessage({
                  name: this.EVENT_CLOSE
                });
              }, 3000);
            }
            const order_lat = order_info.from_location.coordinates[0],
              order_lng = order_info.from_location.coordinates[1];
            const order_coordinates = [order_lat, order_lng];
            getSupplierInfo(supplier_token)
              .then((data) => {
                const supplier_id = data.supplier._id;
                this.actionSetMasterSupplierId({ value: supplier_id });
                getFavoriteSuppliersInfo(supplier_token, supplier_id)
                  .then((data) => {
                    // console.log(`getFavoriteSuppliersInfo data: ${JSON.stringify(data)}`);
                    data = data.filter((item) => item.services.indexOf(order_info.service_id) > -1 && ["ONLINE", "BUSY"].indexOf(item.status) > -1);
                    data.map((item) => {
                      item.last_activity = moment
                        .unix(Math.round(Number(item.last_activity)))
                        .fromNow();
                      item.distance =
                        Math.sqrt(
                          (item.coordinates[0] - order_coordinates[0]) ** 2 +
                          (item.coordinates[1] - order_coordinates[1]) ** 2
                        ) * 111.2;
                      item.distance = Math.round(item.distance);
                    });
                    this.suppliers = data;
                    // console.log(`suppliers: ${JSON.stringify(this.suppliers)}`);
                    this.isLoadingSupplier = false;
                  })
                  .catch((error) => {
                    this.assign_error = true;
                    this.error_message = `Đã xảy ra lỗi! ${error.response.status}: ${error.response.data.description}`;
                    this.isLoadingSupplier = false;
                    setTimeout(() => {
                      this.postMessage({
                        name: this.EVENT_CLOSE
                      });
                    }, 3000);
                  });
              })
              .catch((error) => {
                this.assign_error = true;
                this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
                this.isLoadingSupplier = false;
                setTimeout(() => {
                  this.postMessage({
                    name: this.EVENT_CLOSE
                  });
                }, 3000);
              });
          })
          .catch((error) => {
            this.assign_error = true;
            this.error_message = `Đã xảy ra lỗi! ${error.response.status}: ${error.response.data.description}`;
            this.isLoadingSupplier = false;
            setTimeout(() => {
              this.postMessage({
                name: this.EVENT_CLOSE
              });
            }, 3000);
          });
      })
      .catch((error) => {
        this.assign_error = true;
        this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
        this.isLoadingSupplier = false;
        setTimeout(() => {
          this.postMessage({
            name: this.EVENT_CLOSE
          });
        }, 3000);
      });
  },
};
</script>
