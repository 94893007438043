<template>
  <div class="bottom-navigator" :class="{ active: open }">
    <div class="overlay" @click="$emit('close')"></div>
    <div class="navigator-body">
      <div class="body-content" :class="{ fluid }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomModal",
  props: ["open", "fluid"],
};
</script>

<style lang="scss" scoped>
.bottom-navigator {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  transition: all ease 0.7s;
  &.active {
    z-index: 10;
    transition: all ease 0.7s;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all ease 0.7s;
  .active & {
    opacity: 0.2;
    transition: all ease 0.7s;
  }
}

.navigator-body {
  position: absolute;
  bottom: -100%;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 24px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: ease-in-out 0.7s;
  .active & {
    bottom: 0;
    transition: ease-in-out 0.7s;
  }
}

.body-content {
  height: 100%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &.fluid {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.body-content::-webkit-scrollbar {
  display: none; /* for Safari , Chrome, Opera */
}
</style>
